import { isBrowser } from './is-browser.util';

let inProgressModalTitle = null;
/**
 * It disables the page scroll by setting the overflow property of the body element to hidden
 * @param {boolean} disabled - boolean
 */
export const disablePageScroll = (disabled: boolean, key: string) => {
  if (!isBrowser()) {
    throw new Error('use disablePageScroll util function inside the browser environment.');
  }

  if (key) {
    if (!inProgressModalTitle && disabled) {
      inProgressModalTitle = key;
      window.document.body.style.overflow = 'hidden';
    }
    if (inProgressModalTitle === key && !disabled) {
      window.document.body.style.overflow = 'unset';
      inProgressModalTitle = null;
    }
  } else {
    window.document.body.style.overflow = disabled ? 'hidden' : 'unset';
  }
};
