import clsx from 'clsx';
import type IReactToastify from 'react-toastify';
import type { TypeOptions } from 'react-toastify';
import type { ApiErrorResponse } from '@ping/api';
import { IToastProps, Spinner } from '@ping/uikit';
import { EToastType } from '@ping/enums';
import { ApiErrorResponseToastContent } from '@ping/uikit';
import { toast, ToastContainer } from 'react-toastify';

import FailedIcon from '@ping/assets/Icon/failed.svg';
import DoneIcon from '@ping/assets/Icon/done.svg';
import WarningIcon from '@ping/assets/Icon/warning.svg';

import style from './style.module.scss';

/**
 * @function Toast
 * @description Toast Component for notification
 * @param props
 * @constructor
 */
export const Toast = ({ className, customClass, ...rest }: IToastProps) => (
  <ToastContainer className={`${className} ${customClass}`} {...rest} />
);

Toast.defaultProps = {
  position: 'top-center',
  rtl: true,
  hideProgressBar: true,
  customClass: '',
  autoClose: false,
  toastClassName: style['ping-toast'],
  className: style['ping-toast__container'],
};

interface IDisplayToast {
  type: EToastType;
  title?: IReactToastify.ToastContent | string;
  text?: string;
  options?: IReactToastify.ToastOptions;
}

interface IToast {
  title?: IReactToastify.ToastContent | string;
  text?: string;
  options?: IReactToastify.ToastOptions;
}

interface IToastError extends Omit<IToast, 'title'> {
  title?: IToast['title'] | ApiErrorResponse;
}

/**
 * It takes an object with a type, content, and options
 * @param {IDisplayToast}  - type - the type of toast to display. This is used to set the className of the
 * toast.
 */
export const displayToast = ({ type, title, text, options }: IDisplayToast) => {
  options.type = type as TypeOptions;
  options.className = clsx(style['ping-toast'], style[`ping-toast--${type}`]);

  toast(
    props => (
      <section>
        <>
          {typeof title === 'function' ? (
            title?.(props)
          ) : (
            <div className={style['ping-toast__content-title']}>{title}</div>
          )}
          {text && (
            <div
              className={clsx(style['ping-toast__content-text'], {
                [style['ping-toast__content-text--color']]: !title,
              })}
            >
              {text}
            </div>
          )}
        </>
      </section>
    ),
    options
  );
};

Toast.default = ({ title, text, options = {} }: IToast) =>
  displayToast({ type: EToastType.DEFAULT, title, text, options: { ...options, icon: false } });

Toast.progress = ({ title, text, options = {} }: IToast) =>
  displayToast({
    type: EToastType.PROGRESS,
    title,
    text,
    options: { ...options, icon: <Spinner spinnerType='clipLoader' color='#4E60FF' size={20} /> },
  });

Toast.success = ({ title, text, options = {} }: IToast) =>
  displayToast({ type: EToastType.SUCCESS, title, text, options: { ...options, icon: DoneIcon } });

Toast.warning = ({ title, text, options = {} }: IToast) =>
  displayToast({ type: EToastType.WARNING, title, text, options: { ...options, icon: WarningIcon } });

Toast.error = ({ title, text, options = {} }: IToastError) => {
  let toastContent = title;

  if (typeof title === 'object' && 'errorCode' in (title as ApiErrorResponse)) {
    const { errorCode, ...rest } = title as ApiErrorResponse;
    toastContent = <ApiErrorResponseToastContent {...rest} />;
    options.toastId ||= errorCode;
  }

  return displayToast({
    type: EToastType.ERROR,
    title: toastContent as IReactToastify.ToastContent | string,
    text,
    options: { ...options, icon: FailedIcon },
  });
};

// Close all the opening Toast / Close the specific Toast (with toastId)
Toast.dismiss = toast.dismiss;
