import { ReactNode } from 'react';
import { useRemoteModal } from '@ping/hooks';
import clsx from 'clsx';
import BannerModal from './BannerModal';
import BannerMessage from './BannerMessage';

import type { IBannerType } from './BannerMessage';

import style from './style.module.scss';

interface IBanner {
  show: boolean;
  ModalContentChildren?: ReactNode;
  ModalActionsChildren?: ReactNode;
  modalTitle?: string;
  bannerType?: IBannerType;
  message: string;
  extraMessage?: string;
  showIcon?: boolean;
  headerTag?: IHeaderTag;
  messageClassName?: string;
  modalTriggerClassName?: string;
  modalPortalClassName?: string;
  remoteModal?: ReturnType<typeof useRemoteModal>;
  onClick?: () => void;
}

const Banner = ({
  show,
  ModalContentChildren,
  modalTitle,
  ModalActionsChildren,
  remoteModal,
  bannerType,
  message,
  extraMessage,
  showIcon,
  headerTag,
  messageClassName,
  modalTriggerClassName,
  modalPortalClassName,
  onClick,
}: IBanner) => {
  return (
    <div className={clsx({ [style['banner']]: show, [style['banner_cursor']]: onClick })}>
      {show && !ModalContentChildren && (
        <BannerMessage
          bannerType={bannerType}
          message={message}
          showIcon={showIcon}
          extraMessage={extraMessage}
          messageClassName={messageClassName}
          onClick={onClick}
        />
      )}

      {/* ToDo: we should remove the modal from the banner but we should keep the modal design (let's do it when modal announcement gets ready )*/}
      {show && !!ModalContentChildren && (
        <BannerModal
          ModalContentChildren={ModalContentChildren}
          modalTitle={modalTitle}
          ModalActionsChildren={ModalActionsChildren}
          remoteModal={remoteModal}
          bannerType={bannerType}
          message={message}
          extraMessage={extraMessage}
          showIcon={showIcon}
          headerTag={headerTag}
          messageClassName={messageClassName}
          modalTriggerClassName={modalTriggerClassName}
          modalPortalClassName={modalPortalClassName}
        />
      )}
    </div>
  );
};

export default Banner;
