import axios from 'axios';
import { isNil } from 'rambdax';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ApiErrorResponse } from '@ping/api';
import { Toast } from '@ping/uikit';

import type { ErrorType } from '@ping/api/custom-instance';
import type { DehydratedState } from 'react-query';

export interface IReactQueryProviderProps {
  dehydratedState?: DehydratedState;
  children: React.ReactNode;
}

const TraderApiErrorCodes = {
  ExceedingPerTransactionWithdrawalLimit: 70100,
} as const;

//
// NOTE: If an error code is handled in other places via a non-toast method, e.g. dialog, it should be added here.
const CoveredErrorCodes = new Set<number>([TraderApiErrorCodes.ExceedingPerTransactionWithdrawalLimit]);

const handleOnRequestError = (response: ApiErrorResponse) => {
  if (!isNil(response) && !CoveredErrorCodes.has(response.errorCode)) {
    Toast.error({ title: response });
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: ErrorType<any>) => {
        if (axios.isCancel(error)) {
          return false;
        }
        return failureCount < 3;
      },
      // we don't need to refetch on every focus. that's just unnecessary stress on server maybe add it later again
      refetchOnWindowFocus: false,
      onError: (error: ErrorType<ApiErrorResponse>) => handleOnRequestError(error?.response?.data),
    },
    mutations: {
      onError: (error: ErrorType<ApiErrorResponse>) => handleOnRequestError(error?.response?.data),
    },
  },
});

const ReactQueryProvider = ({ children, dehydratedState }: IReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
