/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Introduction
 * ## Ping Trader API
API can be accessed through [https://api.ping.exchnage](https://api.ping.exchnage)
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from './custom-instance';
import type { ErrorType } from './custom-instance';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

export type GetAllTrendsV1200Three = { [key: string]: TrendResponse };

export type GetAllTrendsV1200Two = { [key: string]: TrendResponse };

export type GetAllTrendsV1200One = { [key: string]: TrendResponse };

export type GetAllTrends200Three = { [key: string]: TrendResponse };

export type GetAllTrends200Two = { [key: string]: TrendResponse };

export type GetAllTrends200One = { [key: string]: TrendResponse };

export type TradeHistoryV1MarketsParams = {
  /**
   * Type of Order, Buy or Sell
   */
  type?: OrderSide;
  /**
   * Number of historical trades to retrieve from time of query. Default 100; max 500.
   */
  limit?: number;
  /**
   * End time for historical trades query
   */
  endDateTime?: string;
  /**
   * Cursor for pagination (optional).
   */
  cursor?: string;
};

export type OrderbookV1MarketsParams = {
  /**
   * Order book depth, default and max value is 300
   */
  depth?: number;
};

export type TickersV1MarketsParams = {
  /**
   * optional market symbol filter
   */
  symbol?: string;
};

export type GetRecentHistoryV1ChartingParams = {
  /**
   * Type of Timeframe: 1m, 5m, ..., 1h, 1w, 1M
   */
  type: string;
  /**
   * The total number of history items (limit)
   */
  count: number;
  /**
   * A point in timeline when the history before that moment is requested
   */
  endDateTime?: string;
};

export type GetRecentHistoryChartingParams = {
  /**
   * Type of Timeframe: 1m, 5m, ..., 1h, 1w, 1M
   */
  type: string;
  /**
   * The total number of history items (limit)
   */
  count: number;
  /**
   * A point in timeline when the history before that moment is requested
   */
  endDateTime?: string;
};

export type GetAllUserParams = {
  /**
   * User Name, user email search filter
   */
  Search?: string;
  /**
   * User's current status
   */
  Status?: ActivityStatus;
  FromLastDepositDate?: string;
  ToLastDepositDate?: string;
  FromLastDepositAmount?: number;
  ToLastDepositAmount?: number;
  FromTradeVolume?: number;
  ToTradeVolume?: number;
  AscOrder?: string;
  DescOrder?: string;
  Sort?: string;
  Page?: number;
  PerPage?: number;
};

export type GetTransfersAsCsvParams = {
  /**
   * Filter transfers with Qodex.Account.TransferData.CreatedAt greater or equal to the given value.
   */
  From?: string;
  /**
   * Filter transfers with Qodex.Account.TransferData.CreatedAt less than the given value.
   */
  To?: string;
  /**
   * Filter by piece of a username or an email.
   */
  User?: string;
  /**
   * Filter by account.
   */
  Account?: string;
  /**
   * Filter by status.
   */
  Status?: TransferStatus;
  /**
   * Filter by asset.
   */
  Asset?: string;
  /**
   * Filter by piece of a transfer id.
   */
  TransferId?: string;
  /**
   * Filter by type.
   */
  Type?: TransferType;
  Cursor?: string;
  /**
 * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by CreatedAt desc.
- Supported fields:
    - CreatedAt
    - Amount
 */
  OrderBy?: string;
  /**
   * Filter by account.
   */
  CorePassId?: string;
  /**
   * Filter by transaction id
   */
  TxId?: string;
};

export type GetTransfersParams = {
  /**
   * Filter transfers with Qodex.Account.TransferData.CreatedAt greater or equal to the given value.
   */
  From?: string;
  /**
   * Filter transfers with Qodex.Account.TransferData.CreatedAt less than the given value.
   */
  To?: string;
  /**
   * Filter by piece of a username or an email.
   */
  User?: string;
  /**
   * Filter by account.
   */
  Account?: string;
  /**
   * Filter by status.
   */
  Status?: TransferStatus;
  /**
   * Filter by asset.
   */
  Asset?: string;
  /**
   * Filter by piece of a transfer id.
   */
  TransferId?: string;
  /**
   * Filter by type.
   */
  Type?: TransferType;
  Cursor?: string;
  /**
 * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by CreatedAt desc.
- Supported fields:
    - CreatedAt
    - Amount
 */
  OrderBy?: string;
  /**
   * Filter by account.
   */
  CorePassId?: string;
  /**
   * Filter by transaction id
   */
  TxId?: string;
};

export type TradesGetOrdersParams = {
  From: string;
  To?: string;
  User?: string;
  Account?: number;
  OrderId?: string;
  TradeId?: number;
  Market?: string;
  Cursor?: string;
  Sort?: string[];
};

export type GetProfitParams = {
  CurrencyId: string;
  From?: string;
  To?: string;
};

export type GetTransferBalanceHotWalletParams = {
  paymentSystem?: string;
  assetId?: string;
  cursor?: string;
};

export type ComplianceUsersParams = {
  Search?: string;
  Cursor?: string;
  PerPage?: number;
  Status?: UserComplianceRequestStatus;
  UserTier?: UserComplianceTier;
};

export type SponserBalanceAggregatorParams = {
  extKeyVersion?: string;
};

export type BalancesBalanceAggregatorParams = {
  extKeyVersion?: string;
};

export type GetUserBalanceCheckAdminAccountingParams = {
  CorePassId?: string;
  Page?: number;
  PerPage?: number;
  Status?: ActivityStatus;
};

export type GetUserDepositInfoAdminAccountingParams = {
  user?: string;
};

export type GetNetworksWalletParams = {
  AssetId?: string;
};

export type GetNetworkFeeWalletParams = {
  PaymentSystem: string;
  AssetId?: string;
};

export type GetTransferHistoryWalletParams = {
  AssetId?: string;
  Type?: TransferType;
  /**
   * If IsReward is false returns all tranfer reason types except for reward reason
   */
  IsReward?: boolean;
  StartDate?: string;
  EndDate?: string;
  /**
 * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by TransferId desc.
- Supported fields:
    - TransferId
    - Status
    - Amount
    - CreatedAt
    - AssetId
 */
  OrderBy?: string;
  Page?: number;
  PerPage?: number;
};

export type QuoteTradeParams = {
  Instrument?: string;
  Amount?: number;
  IsBuy?: boolean;
};

export type RecentTradesParams = {
  /**
   * A string representing the market name which recent data will be collected.
   */
  Instrument?: string;
};

export type SwapTradeParams = {
  FromAssetId: string;
  ToAssetId: string;
  Amount: number;
  DefaultCurrency?: string;
};

export type GetTradeHistoryParams = {
  Market?: string;
  Side?: OrderSide;
  StartDate?: string;
  EndDate?: string;
  /**
 * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by TradeSeq desc.
- Supported fields:
    - TradeSeq
    - TradeTime
    - Amount
    - Instrument
    - Side
    - ExecutionPrice
    - Commission
 */
  OrderBy?: string;
  Page?: number;
  PerPage?: number;
};

export type OrderHistoryTradeParams = {
  Market?: string;
  Side?: OrderSide;
  StartDate?: string;
  EndDate?: string;
  /**
 * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by UpdatedAt desc.
- Supported fields:
    - Instrument
    - Amount
    - UnitsFilled
    - Remaining
    - Price
    - Total
    - OrderType
    - StopPrice
    - CreatedAt
    - UpdatedAt
    - Side
    - ExecutionPrice
    - Commission
    - Status
 */
  OrderBy?: string;
  IsHideCanceled?: boolean;
  Page?: number;
  PerPage?: number;
};

export type GetReferralStatusParams = {
  Page?: number;
  PerPage?: number;
};

export type GetQuotesRampParams = {
  Side: RampOrderSide;
  CountryCode: string;
  'SourceAsset.Symbol': string;
  'SourceAsset.BlockchainNetwork'?: BlockchainNetwork;
  'TargetAsset.Symbol': string;
  'TargetAsset.BlockchainNetwork'?: BlockchainNetwork;
  Amount: number;
  PaymentMethod?: PaymentMethod;
};

export type GetPaymentMethodsRampParams = {
  Side: RampOrderSide;
  CountryCode: string;
  'SourceAsset.Symbol': string;
  'SourceAsset.BlockchainNetwork'?: BlockchainNetwork;
  'TargetAsset.Symbol': string;
  'TargetAsset.BlockchainNetwork'?: BlockchainNetwork;
  Amount?: number;
};

export type GetAssetsRampParams = {
  Side: RampOrderSide;
  CountryCode: string;
};

export type GetCountriesRampParams = {
  Side: RampOrderSide;
};

export type GetOrCreateProfileParams = {
  /**
 * Used only on first call to this api when user is being created, 
            It indicates the user who referred this user
 */
  referralCode?: string;
};

export type NotificationProfileParams = {
  corepssId?: string;
  eventName?: string;
  data?: string;
};

export type ResetCorepassIdProfileParams = {
  corepssId?: string;
};

export type InvalidateKycParams = {
  /**
   * CorePassId
   */
  coreId: string;
};

export type UploadFileToCloudStorageFileTransferParams = {
  documentType: CorePassDocumentType;
};

export type GetPreviousStepComplianceParams = {
  step?: ComplianceStepType;
};

export type GetNextStepComplianceParams = {
  step?: ComplianceStepType;
};

/**
 * @nullable
 */
export type TrendResponseTrends = { [key: string]: number } | null;

export interface TrendResponse {
  readonly change?: number;
  /** @nullable */
  trends?: TrendResponseTrends;
}

export interface TradeHistoryResponseItem {
  /** The volume of the base currency traded. */
  baseVolume?: number;
  /** The price at which the trade occurred. */
  price?: number;
  side?: OrderSide;
  /** The volume of the target currency traded. */
  targetVolume?: number;
  /** The timestamp of when the trade occurred. */
  timestamp?: string;
  /** Unique identifier for the trade. */
  tradeId?: number;
}

export interface TradeHistoryResponse {
  /**
   * Array of trade history
   * @nullable
   */
  items?: TradeHistoryResponseItem[] | null;
  /**
   * Cursor for fetching next set of records
   * @nullable
   */
  nextCursor?: string | null;
}

/**
 * Represents quote.
 */
export interface QuoteInfo {
  /** Close price */
  close?: number;
  CoinGeckoPrice?: number;
  /** End date and time of quote */
  end?: string;
  /** Highest price of quote in the timeframe. */
  high?: number;
  /** @nullable */
  instrument?: string | null;
  /** Lowest price of quote in the timeframe. */
  low?: number;
  /** Open price */
  open?: number;
  /** Start date and time of quote. */
  start?: string;
  /** milliseconds since Unix epoch of Start Time */
  readonly time?: number;
  /** Volume of trading */
  volume?: number;
}

export interface OrderBookLevelInfo {
  amount?: number;
  price?: number;
}

export interface OrderBookItem {
  /** The order amount. */
  amount?: number;
  /** The order price. */
  price?: number;
}

export interface OrderBookResponse {
  /**
   * List of ask orders
   * @nullable
   */
  asks?: OrderBookItem[] | null;
  /**
   * List of bid orders
   * @nullable
   */
  bids?: OrderBookItem[] | null;
  /** The timestamp of when the order book data was retrieved. */
  timestamp?: string;
  /** The version of the order book data. */
  version?: number;
}

export interface OrderBookInfo {
  /** @nullable */
  asks?: OrderBookLevelInfo[] | null;
  askTotalAmount?: number;
  /** @nullable */
  bids?: OrderBookLevelInfo[] | null;
  bidTotalAmount?: number;
  /** @nullable */
  instrument?: string | null;
  snapshot?: boolean;
  version?: number;
}

export interface MarketSummaryData {
  /**
   * Symbol/currency code of base currency, eg. btc
   * @nullable
   */
  baseAsset?: string | null;
  /** 24-hr volume of market pair denoted in BASE currency */
  baseVolume?: number;
  /** 24-hr % price change of market pair */
  changePercent?: number;
  /** Highest bid price of base currency based on given quote currency */
  highestBid?: number;
  /** Highest price of base currency based on given quote currency in the last 24-hrs */
  highestPrice?: number;
  /** Last transacted price of base currency based on given quote currency */
  lastPrice?: number;
  /** Lowest Ask price of base currency based on given quote currency */
  lowestAsk?: number;
  /** Lowest price of base currency based on given quote currency in the last 24-hrs */
  lowestPrice?: number;
  /**
   * Symbol/currency code of quote currency, eg. usdc
   * @nullable
   */
  quoteAsset?: string | null;
  /** 24-hr volume of market pair denoted in QUOTE currency */
  quoteVolume?: number;
  /**
   * Identifier of a ticker with delimiter to separate base/quote, eg. btc_usdc (Price of btc is quoted in usdc)
   * @nullable
   */
  symbol?: string | null;
}

export interface Market24HourData {
  /** Highest price of base currency based on given quote currency in the last 24-hrs */
  high?: number;
  /** Last transacted price of base currency based on given quote currency */
  lastPrice?: number;
  /** Lowest price of base currency based on given quote currency in the last 24-hrs */
  low?: number;
  /**
   * Identifier of a ticker with delimiter to separate base/quote, eg. btc_usdc (Price of btc is quoted in usdc)
   * @nullable
   */
  symbol?: string | null;
  /** 24 hour trading volume denoted in QUOTE currency */
  readonly targetVolume?: number;
  /** 24-hour trading volume denoted in BASE currency */
  volume?: number;
}

export interface ChartHistoryResponse {
  /** @nullable */
  data?: QuoteInfo[] | null;
  /** @nullable */
  endDateTime?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  /** @nullable */
  instrument?: string | null;
  /** @nullable */
  startDateTime?: string | null;
  success?: boolean;
}

/**
 * Represents the response for an asset.
 */
export interface AssetsResponse {
  /** Indicating whether the asset can be deposited. */
  canDeposit?: boolean;
  /** Indicating whether the asset can be withdrawn. */
  canWithdraw?: boolean;
  /**
   * List of contract addresses associated with this asset.
   * @nullable
   */
  contractAddress?: string[] | null;
  /**
   * List of URLs for the contract addresses associated with this asset.
   * @nullable
   */
  contractAddressUrl?: string[] | null;
  /** The maximum  amount that can be withdrawn for this asset. */
  maxWithdraw?: number;
  /** The minimum amount that can be withdrawn for this asset. */
  minWithdraw?: number;
  /**
   * The name of the asset.
   * @nullable
   */
  name?: string | null;
  /**
   * The unified identifier for the crypto asset, which is unique across the platform.
   * @nullable
   */
  unifiedCryptoAssetId?: string | null;
}

/**
 * @nullable
 */
export type Asset24HourDataTrends = { [key: string]: number } | null;

export interface Asset24HourData {
  /** @nullable */
  assetName?: string | null;
  changeRate?: number;
  price?: number;
  /** @nullable */
  symbol?: string | null;
  /** @nullable */
  trends?: Asset24HourDataTrends;
  volume?: number;
}

export interface WalletTransactionRecoverRequest {
  paymentSystem: PaymentSystem;
  /** @pattern ^[a-zA-Z0-9]*$ */
  txId: string;
}

export interface UserTransferData {
  amount?: number;
  /** @nullable */
  asset?: string | null;
  /** @nullable */
  blockchainLink?: string | null;
  createdAt?: string;
  /** @nullable */
  errorCode?: string | null;
  /** @nullable */
  errorDetails?: string | null;
  status?: TransferStatus;
  transferReason?: TransferReasonType;
  /** @nullable */
  txId?: string | null;
  type?: TransferType;
  /** @nullable */
  userId?: string | null;
}

export type UserState = (typeof UserState)[keyof typeof UserState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserState = {
  NoRisk: 'NoRisk',
  HighRisk: 'HighRisk',
} as const;

export interface UserReviewResolveRequest {
  /**
   * @minLength 0
   * @maxLength 1500
   * @nullable
   */
  comment?: string | null;
  requestId: string;
  resolve: ResolveComplienceRequestStatusType;
}

export interface UserResponse {
  /** @nullable */
  accounts?: BasicAccountInfo[] | null;
  /** @nullable */
  corePassId?: string | null;
  /** @nullable */
  createdAt?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  id?: string | null;
  isActive?: boolean;
  isEmailConfirmed?: boolean;
  isPhoneConfirmed?: boolean;
  isPioneer?: boolean;
  isTierExpired?: boolean;
  /** @nullable */
  lastSignInDate?: string | null;
  /** @nullable */
  nickname?: string | null;
  /** @nullable */
  registrationDate?: string | null;
  /** @nullable */
  roles?: string[] | null;
  status?: ActivityStatus;
  tier?: UserTierType;
  /** @nullable */
  tierExpiresAt?: string | null;
  verificationLevel?: number;
}

export interface UserResponseResponse {
  data?: UserResponse;
}

export interface UserPortfolioRequest {
  userId?: string;
}

export interface UserOrdersResponse {
  /** @nullable */
  cursor?: string | null;
  /** @nullable */
  data?: OrderItemData[] | null;
}

/**
 * @nullable
 */
export type UserItemResponseFilteredResponseFilters = unknown | null;

export interface UserItemResponse {
  availableBalance?: number;
  /** @nullable */
  corePassId?: string | null;
  /** @nullable */
  createdAt?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  kycError?: string | null;
  kycStatus?: KycStatus;
  /** @nullable */
  lastDepositAmount?: number | null;
  /** @nullable */
  lastDepositDate?: string | null;
  status?: ActivityStatus;
  totalBalance?: number;
  totalOnHoldBalance?: number;
  /** @nullable */
  tradeVolume?: number | null;
  /** @nullable */
  userName?: string | null;
}

export interface UserItemResponseFilteredResponse {
  /** @nullable */
  data?: UserItemResponse[] | null;
  /** @nullable */
  filters?: UserItemResponseFilteredResponseFilters;
  paging?: Paging;
}

export interface UserInfoResponse {
  /** @nullable */
  employmentStatus?: number | null;
  /** @nullable */
  entityType?: string | null;
  /** @nullable */
  formSignature?: string | null;
  /** @nullable */
  formSignatureDate?: string | null;
  /** @nullable */
  industry?: string | null;
  /** @nullable */
  primarySource?: number | null;
  /** @nullable */
  primaryUse?: number | null;
  /** @nullable */
  termsAccepted?: string | null;
  /** @nullable */
  totalValue?: number | null;
}

/**
 * Filters for users GET request
 */
export interface UserFilters {
  /** @nullable */
  fromLastDepositAmount?: number | null;
  /** @nullable */
  fromLastDepositDate?: string | null;
  /** @nullable */
  fromTradeVolume?: number | null;
  /**
   * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by CreatedAt desc.
- Supported fields:
    - CorePassId
    - Email
    - Status
    - CreatedAt
   * @nullable
   */
  orderBy?: string | null;
  page?: number;
  perPage?: number;
  /**
   * User Name, user email search filter
   * @nullable
   */
  search?: string | null;
  status?: ActivityStatus;
  /** @nullable */
  toLastDepositAmount?: number | null;
  /** @nullable */
  toLastDepositDate?: string | null;
  /** @nullable */
  toTradeVolume?: number | null;
}

export interface UserDocument {
  /** @nullable */
  documentType?: string | null;
  /** @nullable */
  fileName?: string | null;
  /** @nullable */
  fileSize?: string | null;
  /** @nullable */
  id?: string | null;
  kycVersion?: number;
}

export interface UserDetailsResponse {
  accountCreated?: string;
  /** @nullable */
  addressNote?: string | null;
  amlCheck?: boolean;
  /** @nullable */
  amlData?: string | null;
  complianceRequestType?: UserReviewRequestType;
  complianceTier?: UserComplianceTierType;
  /** @nullable */
  coreId?: string | null;
  /** @nullable */
  documentNote?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  flagDetails?: FlagDetail[] | null;
  /** @nullable */
  fullName?: string | null;
  /** @nullable */
  fullNameEnglish?: string | null;
  /** @nullable */
  nationality?: string | null;
  /**
   * RequestPendingTime is null when the user does not have any active requests
   * @nullable
   */
  requestPendingTime?: number | null;
  /** @nullable */
  residence?: string | null;
  status?: ActivityStatus;
  /**
   * Sum of the amount for the user's withdrawal transactions from the beginning of the current month.
   * @nullable
   */
  totalCurrentMonthWithdrawalAmount?: number | null;
  /**
   * Sum of the amount for the user's withdrawal transactions from the beginning of the current year
   * @nullable
   */
  totalCurrentYearWithdrawalAmount?: number | null;
  /**
   * The total monthly withdrawal limit for the user.
   * @nullable
   */
  totalMonthlyLimit?: number | null;
  /**
   * The total yearly withdrawal limit for the user.
   * @nullable
   */
  totalYearlyLimit?: number | null;
  userId?: string;
  userInfo?: UserInfoResponse;
  /** @nullable */
  userRequests?: ComplianceRequest[] | null;
  userStatus?: UserState;
}

export type UserComplianceTier = (typeof UserComplianceTier)[keyof typeof UserComplianceTier];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserComplianceTier = {
  Tier0: 'Tier0',
  Tier1: 'Tier1',
  Tier2: 'Tier2',
  Tier3: 'Tier3',
} as const;

export type UserComplianceRequestStatus =
  (typeof UserComplianceRequestStatus)[keyof typeof UserComplianceRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserComplianceRequestStatus = {
  Pending: 'Pending',
  Completed: 'Completed',
} as const;

export interface UserBalanceCheckItem {
  /** @nullable */
  accounts?: AssetAccount[] | null;
  /** @nullable */
  corePassId?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  userId?: string | null;
}

export interface UserBalanceCheckResponse {
  total?: number;
  /** @nullable */
  users?: UserBalanceCheckItem[] | null;
}

export interface UpdateUsersRequest {
  activityStatus: ActivityStatus;
  usersIds: string[];
}

export interface UpdateUserRequest {
  status?: ActivityStatus;
  /** @nullable */
  tradeLimit?: number | null;
  userId?: string;
}

export interface UpdateMarketRequest {
  /** @nullable */
  allowMarketOrder?: boolean | null;
  /** @nullable */
  amountScale?: number | null;
  /** @nullable */
  baseFee?: number | null;
  /** @nullable */
  hidden?: boolean | null;
  /** @nullable */
  isStable?: boolean | null;
  /** @nullable */
  makerFee?: number | null;
  /** @nullable */
  maxSellPriceVariationPercent?: number | null;
  /** @nullable */
  minAmount?: number | null;
  /** @nullable */
  minBuyPriceVariationPercent?: number | null;
  /** @nullable */
  priceScale?: number | null;
  /** @nullable */
  quoteFee?: number | null;
  status?: MarketStatus;
  /** @nullable */
  takerFee?: number | null;
}

export interface UnblockUserApiRequest {
  userId?: string;
}

export interface TransfersResponse {
  /** @nullable */
  data?: AdminTransferData[] | null;
  paging?: PagingData;
  /** @nullable */
  sort?: string[] | null;
}

export interface TransferRecord {
  accountId?: number;
  amount?: number;
  /** @nullable */
  assetId?: string | null;
  /** @nullable */
  comment?: string | null;
  createdAt?: string;
  /** @nullable */
  data?: string | null;
  /** @nullable */
  errorMessage?: string | null;
  fee?: number;
  id?: string;
  /** @nullable */
  paymentSystem?: string | null;
  /** @nullable */
  paymentSystemId?: string | null;
  /** @nullable */
  portfolioLockId?: string | null;
  /** @nullable */
  price?: number | null;
  status?: TransferStatus;
  /** @nullable */
  targetAddress?: string | null;
  transferReason?: TransferReasonType;
  /** @nullable */
  txId?: string | null;
  type?: TransferType;
  updatedAt?: string;
  version?: number;
}

export type TransactionType = (typeof TransactionType)[keyof typeof TransactionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionType = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
} as const;

export type TransactionStatus = (typeof TransactionStatus)[keyof typeof TransactionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionStatus = {
  AwaitingConfirmation: 'AwaitingConfirmation',
  ReplacedByFee: 'ReplacedByFee',
  Completed: 'Completed',
  Failed: 'Failed',
} as const;

export interface TransactionEventData {
  amount?: number;
  /** @nullable */
  assetId?: string | null;
  confirmations?: number;
  /** @nullable */
  errorDetails?: string | null;
  /** @nullable */
  from?: string | null;
  minConfirmations?: number;
  ownerId?: number;
  /** @nullable */
  paymentSystem?: string | null;
  /** @nullable */
  paymentSystemId?: string | null;
  status?: TransactionStatus;
  /** @nullable */
  to?: string | null;
  transferId?: string;
  /** @nullable */
  txId?: string | null;
  type?: TransactionType;
}

export interface TransactionEmergencyRecoverResult {
  /** @nullable */
  eventData?: TransactionEventData[] | null;
  recoverStatus?: RecoverStatus;
}

export interface TradesResponse {
  /** @nullable */
  data?: TradeData[] | null;
  paging?: PagingData;
  /** @nullable */
  sort?: string[] | null;
}

export type TradeRole = (typeof TradeRole)[keyof typeof TradeRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRole = {
  Aggressor: 'Aggressor',
  Resting: 'Resting',
} as const;

export interface TradeData {
  /** @nullable */
  accountId?: string | null;
  accountVersion?: number;
  /** @nullable */
  commission?: string | null;
  /** @nullable */
  commissionCurrency?: string | null;
  /** @nullable */
  commissionType?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  market?: string | null;
  /** @nullable */
  orderId?: string | null;
  /** @nullable */
  remainingAmount?: string | null;
  side?: OrderSide;
  /** @nullable */
  tradeAmount?: string | null;
  /** @nullable */
  tradeId?: string | null;
  /** @nullable */
  tradePrice?: string | null;
  tradeQuoteAmount?: number;
  /** @nullable */
  tradeRole?: string | null;
  tradeTime?: string;
  /** @nullable */
  userName?: string | null;
}

export type TagCardinality = (typeof TagCardinality)[keyof typeof TagCardinality];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagCardinality = {
  Single: 'Single',
  Multiple: 'Multiple',
} as const;

export interface SystemBalanceReportItem {
  /**
   * Calculated amount using transfers and orders
   * @nullable
   */
  amount?: string | null;
  /** @nullable */
  assetId?: string | null;
  /**
   * Calculated value -PortfolioAmount
   * @nullable
   */
  difference?: string | null;
  /** The calculated value is greater or equal to portfolio amount and locked amount */
  readonly isCorrect?: boolean;
  /** @nullable */
  lockedAmount?: string | null;
  /** @nullable */
  portfolioAmount?: string | null;
  /** @nullable */
  totalDeposit?: string | null;
  /** @nullable */
  totalOrder?: string | null;
  /** @nullable */
  totalWithdrawal?: string | null;
}

export type RoleType = (typeof RoleType)[keyof typeof RoleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
  Admin: 'Admin',
  Support: 'Support',
  ComplianceReviewer: 'ComplianceReviewer',
  TradeDataViewer: 'TradeDataViewer',
} as const;

export interface RoleRequest {
  /** @nullable */
  roles?: RoleType[] | null;
  userId?: string;
}

export interface RewardRequestModel {
  /** @minimum 0 */
  amount: number;
  /**
   * @minLength 3
   * @maxLength 4
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  assetId?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^[a-zA-Z0-9]*$
   */
  coreId: string;
  /**
   * @minLength 0
   * @maxLength 256
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  reason?: string | null;
}

export type ResolveComplienceRequestStatusType =
  (typeof ResolveComplienceRequestStatusType)[keyof typeof ResolveComplienceRequestStatusType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolveComplienceRequestStatusType = {
  Approved: 'Approved',
  Denied: 'Denied',
  RequestedMoreData: 'RequestedMoreData',
} as const;

export type RecoverStatus = (typeof RecoverStatus)[keyof typeof RecoverStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecoverStatus = {
  TransactionIsNotRelated: 'TransactionIsNotRelated',
  TransactionWasDetectedBefore: 'TransactionWasDetectedBefore',
  TransactionIsNowRecovered: 'TransactionIsNowRecovered',
} as const;

export interface ProfitResponse {
  /** @nullable */
  currencyId?: string | null;
  expense?: number;
  income?: number;
  profit?: number;
}

export type PaymentSystem = (typeof PaymentSystem)[keyof typeof PaymentSystem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSystem = {
  None: 'None',
  Bitcoin: 'Bitcoin',
  Litecoin: 'Litecoin',
  Ethereum: 'Ethereum',
  BitcoinCash: 'BitcoinCash',
  OpenPay: 'OpenPay',
  Omni: 'Omni',
  CoreBlockchain: 'CoreBlockchain',
  BinanceSmartChain: 'BinanceSmartChain',
} as const;

export interface OrdersResponse {
  /** @nullable */
  data?: OrderData[] | null;
  paging?: PagingData;
  /** @nullable */
  sort?: string[] | null;
}

/**
 * Orders request data - filters, sort parameters and cursor. If set to null, a property is excluded
from filtering.
 */
export interface OrdersRequest {
  /**
   * Filter by account.
   * @nullable
   */
  accountId?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  corePassId?: string | null;
  /**
   * Filter orders with creation time greater or equal to the given value.
   * @nullable
   */
  from?: string | null;
  /**
   * Filter by requested amount minimum value.
   * @nullable
   */
  fromRequestedAmount?: number | null;
  /**
   * Filter by requested price minimum value.
   * @nullable
   */
  fromRequestedPrice?: number | null;
  /**
   * Filter by instrument.
   * @maxLength 16
   * @nullable
   * @pattern ^[a-zA-Z0-9_]*$
   */
  market?: string | null;
  /**
   * A comma-separated list of expressions used to sort the items.
- For ascending sort use 'asc' suffix or nothing(default) like 'CreatedAt asc' or 'CreatedAt'.
- For descending sort use 'desc' suffix like 'CreatedAt desc'.
- Note: value is case insensitive
- Default: if nothing provided default ordering is by CreatedAt desc.
- Supported fields:
    - CreatedAt
    - RequestedLimitPrice
   * @nullable
   */
  orderBy?: string | null;
  /**
   * Filter by piece of an order id.
   * @nullable
   */
  orderId?: string | null;
  paging?: PagingData;
  status?: OrderStatus;
  /**
   * Filter orders with creation time less than the given value.
   * @nullable
   */
  to?: string | null;
  /**
   * Filter by requested amount maximum value.
   * @nullable
   */
  toRequestedAmount?: number | null;
  /**
   * Filter by requested price maximum value.
   * @nullable
   */
  toRequestedPrice?: number | null;
  /**
   * Filter by order type.
   * @nullable
   */
  type?: OrderType[] | null;
  /**
   * Filter by piece of a username or an email.
   * @maxLength 256
   * @nullable
   * @pattern ^[a-zA-Z0-9-]*$
   */
  user?: string | null;
}

export interface OrderItemData {
  /** @nullable */
  accountId?: string | null;
  accountVersion?: number;
  /** @nullable */
  averageFillPrice?: string | null;
  cancelReason?: CancelReason;
  /** @nullable */
  commissionCurrency?: string | null;
  createdAt?: string;
  /** @nullable */
  effectiveLimitPrice?: string | null;
  /** @nullable */
  expirationTime?: string | null;
  /** @nullable */
  filledAmount?: string | null;
  /** @nullable */
  market?: string | null;
  /** @nullable */
  orderId?: string | null;
  orderStatus?: OrderStatus;
  orderType?: OrderType;
  /** @nullable */
  rejectDetails?: string | null;
  /** @nullable */
  rejectReason?: string | null;
  /** @nullable */
  remainingAmount?: string | null;
  /** @nullable */
  requestedAmount?: string | null;
  /** @nullable */
  requestedLimitPrice?: string | null;
  /** @nullable */
  requestedStopPrice?: string | null;
  side?: OrderSide;
  /** @nullable */
  timeInForce?: string | null;
  /** @nullable */
  totalCommission?: string | null;
  /** @nullable */
  triggerPrice?: string | null;
  updatedAt?: string;
  /** @nullable */
  userId?: string | null;
}

export interface OrderData {
  /** @nullable */
  accountId?: string | null;
  accountVersion?: number;
  /** @nullable */
  averageFillPrice?: string | null;
  cancelReason?: CancelReason;
  /** @nullable */
  commissionCurrency?: string | null;
  /** @nullable */
  corePassId?: string | null;
  createdAt?: string;
  /** @nullable */
  effectiveLimitPrice?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  expirationTime?: string | null;
  /** @nullable */
  filledAmount?: string | null;
  /** @nullable */
  market?: string | null;
  /** @nullable */
  orderId?: string | null;
  orderStatus?: OrderStatus;
  orderType?: OrderType;
  /** @nullable */
  rejectDetails?: string | null;
  /** @nullable */
  rejectReason?: string | null;
  /** @nullable */
  remainingAmount?: string | null;
  /** @nullable */
  requestedAmount?: string | null;
  /** @nullable */
  requestedLimitPrice?: string | null;
  /** @nullable */
  requestedStopPrice?: string | null;
  side?: OrderSide;
  /** @nullable */
  timeInForce?: string | null;
  /** @nullable */
  totalCommission?: string | null;
  /** @nullable */
  triggerPrice?: string | null;
  updatedAt?: string;
  /** @nullable */
  userId?: string | null;
  /** @nullable */
  userName?: string | null;
}

export interface OrderDetails {
  /** @nullable */
  executions?: BriefExecution[] | null;
  order?: OrderData;
}

export type MarketStatus = (typeof MarketStatus)[keyof typeof MarketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketStatus = {
  Open: 'Open',
  Halted: 'Halted',
  Paused: 'Paused',
} as const;

export interface MarketStableRequest {
  isStable?: boolean;
  marketId?: number;
}

export interface MarketResponse {
  allowMarketOrder?: boolean;
  amountScale?: number;
  /** @nullable */
  baseAsset?: string | null;
  baseFee?: number;
  hidden?: boolean;
  /** @nullable */
  id?: string | null;
  isStable?: boolean;
  makerFee?: number;
  makerFeeLimit?: number;
  maxPrice?: number;
  maxSellPriceVariationPercent?: number;
  minAmount?: number;
  minBuyPriceVariationPercent?: number;
  minPrice?: number;
  priceScale?: number;
  /** @nullable */
  quoteAsset?: string | null;
  quoteFee?: number;
  status?: MarketStatus;
  takerFee?: number;
  takerFeeLimit?: number;
}

export interface ManualTransferRequest {
  amount: number;
  asset: string;
  /** @nullable */
  code?: string | null;
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  errorDetails?: string | null;
  status: TransferStatus;
  type: TransferType;
  userId: string;
}

export type KycStatus = (typeof KycStatus)[keyof typeof KycStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycStatus = {
  Kyc: 'Kyc',
  InProgress: 'InProgress',
  Failed: 'Failed',
} as const;

export interface IColdStorageSignedTransaction {
  /** @nullable */
  readonly asset?: string | null;
  readonly id?: string;
  /** @nullable */
  readonly rawHex?: string | null;
}

export interface IBalance {
  /** @nullable */
  readonly amount?: string | null;
  /** @nullable */
  readonly asset?: string | null;
  readonly decimals?: number;
  readonly depositCount?: number;
  readonly nonce?: number;
}

export interface HotWalletResponse {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  amount?: number | null;
  /** @nullable */
  assetId?: string | null;
  /** @nullable */
  coldWalletAddress?: string | null;
  /** @nullable */
  displayAmount?: number | null;
  /** @nullable */
  error?: string | null;
  /** @nullable */
  paymentSystem?: string | null;
  /** @nullable */
  rate?: number | null;
}

export interface GetUserRequest {
  userId?: string;
}

export interface GetUserDetailsRequest {
  requestId: string;
  userId: string;
}

export interface GetTransfersByUserResponse {
  /** @nullable */
  cursor?: string | null;
  /** @nullable */
  data?: UserTransferData[] | null;
}

export interface GetTransfersByUserRequest {
  /**
   * @maxLength 1024
   * @nullable
   */
  cursor?: string | null;
  /**
   * @minimum 1
   * @maximum 100
   * @nullable
   */
  perPage?: number | null;
  type: TransferType;
  userId: string;
}

export interface GetOrdersByUserRequest {
  /**
   * @maxLength 1024
   * @nullable
   */
  cursor?: string | null;
  /**
   * @minimum 1
   * @maximum 100
   * @nullable
   */
  perPage?: number | null;
  userId: string;
}

export interface GetDocumentsByUserRequest {
  /** @nullable */
  kycVersion?: number | null;
  userId?: string;
}

export interface GetAccountTagsByUserRequest {
  userId: string;
}

export type FlagType = (typeof FlagType)[keyof typeof FlagType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlagType = {
  BannedResidence: 'BannedResidence',
  HighRiskResidence: 'HighRiskResidence',
  PoliticallyExposedPerson: 'PoliticallyExposedPerson',
  AntiMoneyLaunderingPositive: 'AntiMoneyLaunderingPositive',
  NameDiscrepancy: 'NameDiscrepancy',
  HighRiskNationality: 'HighRiskNationality',
  MissingAddressState: 'MissingAddressState',
  UnderAge: 'UnderAge',
  MissingExpiryDate: 'MissingExpiryDate',
  NationalityFieldMissing: 'NationalityFieldMissing',
} as const;

export interface FlagDetail {
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  disabledAt?: string | null;
  flagType?: FlagType;
  isDisabled?: boolean;
}

export type ExecType = (typeof ExecType)[keyof typeof ExecType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExecType = {
  OrderStatusUpdate: 'OrderStatusUpdate',
  Trade: 'Trade',
} as const;

export interface DeleteAccountTagRequest {
  id: number;
}

export interface CreateUserApiKeyRequest {
  /**
   * @minLength 0
   * @maxLength 50
   * @pattern ^[a-zA-Z0-9]*$
   */
  corePassId: string;
  /**
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]*$
   */
  displayName: string;
  /** @nullable */
  expiresAt?: string | null;
}

export interface CreateApiKeyResponse {
  /** @nullable */
  apiKey?: string | null;
  /** @nullable */
  apiSecret?: string | null;
  /** @nullable */
  displayName?: string | null;
  /** @nullable */
  expiresAt?: number | null;
}

export interface CreateAccountTagRequest {
  tagId: number;
  userId: string;
  /** @pattern ^[a-zA-Z0-9]*$ */
  value: string;
}

export interface ComplianceUsersResponse {
  /** @nullable */
  cursor?: string | null;
  /** @nullable */
  data?: ComplianceResponseItem[] | null;
  pendingRequestsCount?: number;
}

export interface ComplianceUsersRequest {
  /** @nullable */
  cursor?: string | null;
  /** @nullable */
  perPage?: number | null;
  /** @nullable */
  search?: string | null;
  status?: UserComplianceRequestStatus;
  userTier?: UserComplianceTier;
}

export interface ComplianceUserDetailsResponse {
  accountCreated?: string;
  /** @nullable */
  addressNote?: string | null;
  amlCheck?: boolean;
  /** @nullable */
  amlData?: string | null;
  complianceRequestType?: UserReviewRequestType;
  complianceTier?: UserComplianceTierType;
  /** @nullable */
  coreId?: string | null;
  /** @nullable */
  documentNote?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  flagDetails?: FlagDetail[] | null;
  /** @nullable */
  fullName?: string | null;
  /** @nullable */
  fullNameEnglish?: string | null;
  /** @nullable */
  nationality?: string | null;
  /** @nullable */
  requestPendingTime?: number | null;
  /** @nullable */
  residence?: string | null;
  status?: ActivityStatus;
  /** @nullable */
  totalCurrentMonthWithdrawalAmount?: number | null;
  /** @nullable */
  totalCurrentYearWithdrawalAmount?: number | null;
  /** @nullable */
  totalMonthlyLimit?: number | null;
  /** @nullable */
  totalYearlyLimit?: number | null;
  /** @nullable */
  userDocuments?: UserDocument[] | null;
  userId?: string;
  userInfo?: UserInfoResponse;
  /** @nullable */
  userRequests?: ComplianceRequest[] | null;
  userStatus?: UserState;
}

export interface ComplianceUserDetailsRequest {
  requestId: string;
  userId: string;
}

export interface ComplianceTierDowngradeRequest {
  /** @pattern ^[a-zA-Z0-9-.\s]*$ */
  reason: string;
  userId: string;
}

export interface ComplianceResponseItem {
  complianceRequestType?: UserReviewRequestType;
  complianceTier?: UserComplianceTierType;
  /** @nullable */
  corePassId?: string | null;
  /** @nullable */
  emailAddress?: string | null;
  /** @nullable */
  fullName?: string | null;
  /** @nullable */
  requestPendingTime?: number | null;
  /** @nullable */
  userActiveRequestId?: string | null;
  userId?: string;
  userStatus?: ActivityStatus;
}

export interface ComplianceRequest {
  /** @nullable */
  comment?: string | null;
  complianceRequestType?: UserReviewRequestType;
  createdAt?: string;
  id?: string;
  /** @nullable */
  modifierId?: string | null;
  /** @nullable */
  readyToReviewAt?: string | null;
  /** @nullable */
  resolvedAt?: string | null;
  status?: UserReviewStatusType;
}

export interface ComplianceChangeUserStatusRequest {
  activityStatus?: ActivityStatus;
  mute?: boolean;
  /** @nullable */
  userId?: string | null;
  /** @nullable */
  usersIds?: string[] | null;
}

export interface ColdWalletTransferRequest {
  amount: number;
  assetId: string;
  paymentSystem: string;
}

export interface ColdStorageDestination {
  /** @nullable */
  address?: string | null;
  readonly decimals?: number;
  /** @nullable */
  label?: string | null;
}

export interface BriefExecution {
  accountVersion?: number;
  cancelReason?: CancelReason;
  /** @nullable */
  commission?: string | null;
  /** @nullable */
  commissionCurrency?: string | null;
  /** @nullable */
  commissionType?: string | null;
  createdAt?: string;
  execType?: ExecType;
  /** @nullable */
  filledAmount?: string | null;
  /** @nullable */
  filledBaseAmount?: string | null;
  /** @nullable */
  filledQuoteAmount?: string | null;
  orderStatus?: OrderStatus;
  /** @nullable */
  rejectDetails?: string | null;
  /** @nullable */
  rejectReason?: string | null;
  /** @nullable */
  remainingAmount?: string | null;
  /** @nullable */
  tradeAmount?: string | null;
  /** @nullable */
  tradeId?: string | null;
  /** @nullable */
  tradePrice?: string | null;
  tradeRole?: TradeRole;
  /** @nullable */
  triggerPrice?: string | null;
}

export interface BlockUserApiRequest {
  userId?: string;
}

export interface BalanceAggregateRequest {
  /** @nullable */
  balances?: IBalance[] | null;
  destination?: ColdStorageDestination;
}

export interface AssetRequest {
  can_deposit?: boolean;
  can_withdraw?: boolean;
  min_withdraw?: number;
  scale?: number;
  withdrawal_fee?: number;
}

export interface AssetAccount {
  accountId?: number;
  /** @nullable */
  assetId?: string | null;
  balance?: number;
  /** ExpectedBalance - Balance */
  readonly difference?: number;
  expectedBalance?: number;
  readonly isCorrect?: boolean;
  totalDeposit?: number;
  totalOrder?: number;
  totalWithdrawal?: number;
}

export interface ApprovePendingReviewDepositRequest {
  /**
   * @minLength 0
   * @maxLength 500
   * @nullable
   */
  comment?: string | null;
  depositTransferId: string;
}

export interface AggregationBroadcastRequest {
  /** @nullable */
  signedTransactions?: IColdStorageSignedTransaction[] | null;
}

export type AdminUserTierRequestType = (typeof AdminUserTierRequestType)[keyof typeof AdminUserTierRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUserTierRequestType = {
  Basic: 'Basic',
  PremiumForced: 'PremiumForced',
} as const;

export interface AdminUserTierRequest {
  tier?: AdminUserTierRequestType;
  userId?: string;
}

export interface AdminTransferData {
  /** @nullable */
  accountId?: string | null;
  amount?: number;
  /** @nullable */
  asset?: string | null;
  /** @nullable */
  blockchainLink?: string | null;
  confirmations?: number;
  confirmationsRequired?: number;
  /** @nullable */
  corePassId?: string | null;
  createdAt?: string;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  errorCode?: string | null;
  /** @nullable */
  errorDetails?: string | null;
  fee?: number;
  id?: string;
  /** @nullable */
  paymentSystem?: string | null;
  status?: TransferStatus;
  /** @nullable */
  targetAddress?: string | null;
  /** @nullable */
  transferId?: string | null;
  /** @nullable */
  txId?: string | null;
  type?: TransferType;
  updatedAt?: string;
  /** @nullable */
  userId?: string | null;
  /** @nullable */
  userName?: string | null;
}

export interface AdminManualTransferResponse {
  data?: AdminTransferData;
  /** @nullable */
  provider?: string | null;
  twoFactorRequired?: boolean;
}

export interface AccountTagInfo {
  cardinality?: TagCardinality;
  editable?: boolean;
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  type?: string | null;
  /** @nullable */
  values?: string[] | null;
}

export interface WithdrawalRequest {
  /** @nullable */
  address?: string | null;
  amount?: number;
  /** @nullable */
  assetId?: string | null;
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  paymentSystem?: string | null;
  /** @nullable */
  readonly paymentSystems?: readonly PaymentSystemInfo[] | null;
  validateTestnetAddresses?: boolean;
}

export interface WithdrawalLimitResponse {
  complianceTier?: UserComplianceTierType;
  currentLimit?: number;
  dailyLimit?: number;
  /**
   * The currency in which the withdrawal limits are defined.
   * @nullable
   */
  limitCurrency?: string | null;
  /** The maximum limit allowed per transaction. */
  perTransactionLimit?: number;
  /** The cumulative sum of withdrawals made by the user since the beginning of the current month. */
  totalCurrentMonthWithdrawalAmount?: number;
  /** The cumulative sum of withdrawals made by the user since the beginning of the current year. */
  totalCurrentYearWithdrawalAmount?: number;
  /** The total monthly withdrawal limit for the user. */
  totalMonthlyLimit?: number;
  /** The total yearly withdrawal limit for the user. */
  totalYearlyLimit?: number;
}

export interface WithdrawalData {
  amount?: number;
  asset?: RampingAssetData;
  /** @nullable */
  walletAddress?: string | null;
}

export type UserTotalValueType = (typeof UserTotalValueType)[keyof typeof UserTotalValueType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTotalValueType = {
  Under1000: 'Under1000',
  Between1000And9999: 'Between1000And9999',
  Between10000And99999: 'Between10000And99999',
  Between100000And249999: 'Between100000And249999',
  Between250000And999999: 'Between250000And999999',
  Between1000000And2999999: 'Between1000000And2999999',
  Between3000000And4999999: 'Between3000000And4999999',
  Over5000000: 'Over5000000',
} as const;

export type UserTierType = (typeof UserTierType)[keyof typeof UserTierType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTierType = {
  Basic: 'Basic',
  Premium: 'Premium',
  PremiumForced: 'PremiumForced',
} as const;

export type UserReviewStatusType = (typeof UserReviewStatusType)[keyof typeof UserReviewStatusType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserReviewStatusType = {
  Open: 'Open',
  PendingForReview: 'PendingForReview',
  Approved: 'Approved',
  Denied: 'Denied',
  Cancelled: 'Cancelled',
  RequestedMoreData: 'RequestedMoreData',
} as const;

export type UserReviewRequestType = (typeof UserReviewRequestType)[keyof typeof UserReviewRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserReviewRequestType = {
  NameDiscrepancyToTier1: 'NameDiscrepancyToTier1',
  HighRiskToTier2: 'HighRiskToTier2',
  Tier1ToTier2: 'Tier1ToTier2',
  Tier1ToTier3: 'Tier1ToTier3',
  Tier2ToTier3: 'Tier2ToTier3',
  SuspiciousToTier1: 'SuspiciousToTier1',
  Downgrade: 'Downgrade',
} as const;

export type UserReviewAlertType = (typeof UserReviewAlertType)[keyof typeof UserReviewAlertType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserReviewAlertType = {
  None: 'None',
  Info: 'Info',
  Warning: 'Warning',
} as const;

export interface UserReviewLastRequest {
  alert?: UserReviewAlertType;
  id?: string;
  status?: UserReviewStatusType;
}

export type UserPrimaryUseType = (typeof UserPrimaryUseType)[keyof typeof UserPrimaryUseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPrimaryUseType = {
  Investing: 'Investing',
  TradingOnPingExchange: 'TradingOnPingExchange',
  TradingOnOtherExchanges: 'TradingOnOtherExchanges',
  OnlinePurchases: 'OnlinePurchases',
  PaymentToFriends: 'PaymentToFriends',
  OnlinePayments: 'OnlinePayments',
  Business: 'Business',
} as const;

export type UserPrimarySourceType = (typeof UserPrimarySourceType)[keyof typeof UserPrimarySourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPrimarySourceType = {
  Occupation: 'Occupation',
  Investments: 'Investments',
  Savings: 'Savings',
  Inheritance: 'Inheritance',
  CreditLoan: 'CreditLoan',
  FamilyOrOthers: 'FamilyOrOthers',
  CryptocurrencyMining: 'CryptocurrencyMining',
} as const;

export type UserIndustryType = (typeof UserIndustryType)[keyof typeof UserIndustryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserIndustryType = {
  Agriculture: 'Agriculture',
  ArtsAndMedia: 'ArtsAndMedia',
  CasinosAndGaming: 'CasinosAndGaming',
  Cryptocurrency: 'Cryptocurrency',
  Defense: 'Defense',
  Education: 'Education',
  EnergyOilAndGas: 'EnergyOilAndGas',
  EnergyOther: 'EnergyOther',
  Other: 'Other',
} as const;

export type UserEmploymentStatusType = (typeof UserEmploymentStatusType)[keyof typeof UserEmploymentStatusType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEmploymentStatusType = {
  Employed: 'Employed',
  Unemployed: 'Unemployed',
  Retired: 'Retired',
  Student: 'Student',
  SelfEmployed: 'SelfEmployed',
} as const;

export interface UserInfo {
  employmentStatus?: UserEmploymentStatusType;
  /** @nullable */
  entityType?: boolean | null;
  /** @nullable */
  formSignature?: string | null;
  /** @nullable */
  formSignatureDate?: string | null;
  industry?: UserIndustryType;
  /** @nullable */
  otherIndustry?: string | null;
  primarySource?: UserPrimarySourceType;
  primaryUse?: UserPrimaryUseType;
  /** @nullable */
  termsAccepted?: boolean | null;
  totalValue?: UserTotalValueType;
  userId?: string;
}

export interface UserDocumentDto {
  createdAt?: number;
  documentType?: DocumentTypeRpc;
  /** @nullable */
  fileName?: string | null;
  fileSize?: number;
  /** @nullable */
  id?: string | null;
  kycVersion?: number;
  /** @nullable */
  userId?: string | null;
}

export type UserComplianceTierType = (typeof UserComplianceTierType)[keyof typeof UserComplianceTierType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserComplianceTierType = {
  Tier0: 'Tier0',
  Tier1: 'Tier1',
  Tier2: 'Tier2',
  Tier3: 'Tier3',
} as const;

export interface TwoFactorAuthCallbackRequest {
  /** @nullable */
  coreID?: string | null;
  /** @nullable */
  signature?: string | null;
}

export interface TravelRequest {
  originatorBeneficiary: OriginatorBeneficiaryModel;
  /**
   * The Virtual Asset Provider ID.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  vaspId: string;
}

export interface TravelOriginator {
  /**
   * Represents the customer ID.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  customerId: string;
  /** Represents the date of birth of the originator. */
  dateOfBirth: string;
  /**
   * Represents the document number of the originator.
   * @minLength 0
   * @maxLength 64
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  documentNumber: string;
  /**
   * Represents the name of the originator.
   * @minLength 0
   * @maxLength 255
   * @pattern ^([\p{L}\p{N}\s-/])*$
   */
  name: string;
  /**
   * Represents the physical address of the originator.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-,.\s]*$
   */
  physicalAddress: string;
  /**
   * Represents the place of birth of the originator.
   * @minLength 0
   * @maxLength 64
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  placeOfBirth: string;
  /**
   * Represents the wallet address of the originator.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  walletAddress: string;
}

export interface TravelCheckRequest {
  /**
   * The ID of the asset associated with the wallet such as ctn, xcb, btn etc. (required)
   * @minLength 3
   * @maxLength 4
   * @pattern ^[a-zA-Z0-9]*$
   */
  assetId: string;
  /**
   * The `Virtual Asset Provider` identifier (required)
   * @minLength 3
   * @maxLength 256
   * @pattern ^[a-zA-Z0-9]*$
   */
  vaspId: string;
  /**
   * The address of the wallet that requires verification to determine if it is associated with PingExchange (required)
   * @minLength 3
   * @maxLength 256
   * @pattern ^[a-zA-Z0-9]*$
   */
  walletAddress: string;
}

export interface TravelBeneficiary {
  /**
   * Represents the customer ID of the beneficiary.
   * @minLength 0
   * @maxLength 255
   * @nullable
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  customerId?: string | null;
  /**
   * Represents the name of the beneficiary.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  name: string;
  /**
   * Represents the wallet address of the beneficiary.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  walletAddress: string;
}

export type TransferType = (typeof TransferType)[keyof typeof TransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferType = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
} as const;

export type TransferStatus = (typeof TransferStatus)[keyof typeof TransferStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferStatus = {
  Working: 'Working',
  Pending: 'Pending',
  Completed: 'Completed',
  Failed: 'Failed',
  PendingReview: 'PendingReview',
} as const;

export type TransferReasonType = (typeof TransferReasonType)[keyof typeof TransferReasonType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReasonType = {
  External: 'External',
  Internal: 'Internal',
  Reward: 'Reward',
  Manual: 'Manual',
} as const;

/**
 * @nullable
 */
export type TransferHistoryResponseFilteredResponseFilters = unknown | null;

export interface TransferHistoryResponse {
  amount?: number;
  /** @nullable */
  assetId?: string | null;
  createdAt?: string;
  /** @nullable */
  explorerUrl?: string | null;
  /** @nullable */
  fee?: number | null;
  isInternal?: boolean;
  /** @nullable */
  paymentSystemId?: string | null;
  status?: TransferStatus;
  /** @nullable */
  targetAddress?: string | null;
  /** @nullable */
  transferId?: string | null;
  transferReason?: TransferReasonType;
  /** @nullable */
  txId?: string | null;
  type?: TransferType;
}

export interface TransferHistoryResponseFilteredResponse {
  /** @nullable */
  data?: TransferHistoryResponse[] | null;
  /** @nullable */
  filters?: TransferHistoryResponseFilteredResponseFilters;
  paging?: Paging;
}

/**
 * @nullable
 */
export type TradeHistoryItemFilteredResponseFilters = unknown | null;

export interface TradeHistoryItem {
  amount?: number;
  /** @nullable */
  commission?: number | null;
  executionPrice?: number;
  /** @nullable */
  instrument?: string | null;
  /** @nullable */
  orderId?: string | null;
  quoteAmount?: number;
  remaining?: number;
  side?: OrderSide;
  status?: OrderStatus;
  tradeSeq?: number;
  tradeTime?: string;
  transactionId?: number;
}

export interface TradeHistoryItemFilteredResponse {
  /** @nullable */
  data?: TradeHistoryItem[] | null;
  /** @nullable */
  filters?: TradeHistoryItemFilteredResponseFilters;
  paging?: Paging;
}

export interface TagInfo {
  id?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  values?: string[] | null;
}

export interface SwapResponse {
  currencyRate?: number;
  rate?: number;
  totalConversion?: number;
  /** @nullable */
  userCurrency?: string | null;
}

export type SelfMatchPreventionStrategy =
  (typeof SelfMatchPreventionStrategy)[keyof typeof SelfMatchPreventionStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelfMatchPreventionStrategy = {
  cancelAggressor: 'cancelAggressor',
  cancelResting: 'cancelResting',
  cancelBoth: 'cancelBoth',
} as const;

/**
 * Reward properties
 */
export interface RewardResponse {
  /** Amount that will be rewarded to the user */
  amount?: number;
  /**
   * Asset Id
   * @nullable
   */
  assetId?: string | null;
  /**
   * Corepass Id
   * @nullable
   */
  coreId?: string | null;
  /** Reward Id */
  id?: string;
  /**
   * Reason that this reward has been assigned to the user
   * @nullable
   */
  reason?: string | null;
}

export interface ReferralResponse {
  firstLevelRefereeCount?: number;
  hasReferrer?: boolean;
  /** @nullable */
  referralCode?: string | null;
}

export interface RefereeRewardResponse {
  readonly commissionPercentage?: number;
  convertedTotalCommission?: number;
  /** @nullable */
  corePassId?: string | null;
  totalCommission?: number;
}

export interface ReferralStatusResponse {
  convertedCommission?: number;
  paging?: PagingData;
  recentEarnedCommission?: number;
  /** @nullable */
  refereesCommissionList?: RefereeRewardResponse[] | null;
  refereesCount?: number;
  totalEarnedCommission?: number;
}

export interface RecentTradesResponse {
  amount?: number;
  executionPrice?: number;
  /** @nullable */
  instrument?: string | null;
  side?: OrderSide;
  tradeId?: number;
  tradeTime?: number;
}

export type RampingProvider = (typeof RampingProvider)[keyof typeof RampingProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampingProvider = {
  MoonPay: 'MoonPay',
} as const;

export interface RampingProviderData {
  code?: RampingProvider;
  /** @nullable */
  name?: string | null;
}

export interface RampingCallbackResponse {
  action?: RampCallbackAction;
  /** @nullable */
  description?: string | null;
  provider?: RampingProviderData;
  side?: RampOrderSide;
  withdrawalInfo?: WithdrawalData;
}

export interface RampingAssetData {
  blockchainNetwork?: BlockchainNetworkData;
  maxBuyAmount?: number;
  maxSellAmount?: number;
  minBuyAmount?: number;
  minSellAmount?: number;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  symbol?: string | null;
  type?: RampAssetType;
}

export type RampOrderSide = (typeof RampOrderSide)[keyof typeof RampOrderSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampOrderSide = {
  Buy: 'Buy',
  Sell: 'Sell',
} as const;

export type RampCallbackAction = (typeof RampCallbackAction)[keyof typeof RampCallbackAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampCallbackAction = {
  ShowStatus: 'ShowStatus',
  Withdraw: 'Withdraw',
} as const;

export type RampAssetType = (typeof RampAssetType)[keyof typeof RampAssetType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampAssetType = {
  Crypto: 'Crypto',
  Fiat: 'Fiat',
} as const;

export interface QuoteData {
  amount?: number;
  estimatedSpendAmountInSourceAsset?: number;
  providerInfo?: RampingProviderData;
  unitPriceInTargetAsset?: number;
}

export interface QrCodeData {
  /** @nullable */
  alreadySent?: boolean | null;
  expiration?: number;
  /** @nullable */
  link?: string | null;
  /** @nullable */
  qrcode?: string | null;
}

export interface ProfileSettingsUpdateRequest {
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9-]*$
   */
  type?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9-/]*$
   */
  value?: string | null;
}

export interface ProfileResponse {
  /** @nullable */
  accounts?: BasicAccountInfo[] | null;
  activityStatus?: ActivityStatus;
  complianceTier?: UserComplianceTierType;
  /** @nullable */
  coreId?: string | null;
  /** @nullable */
  createdAt?: string | null;
  /** @nullable */
  documentExpirationDate?: number | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  id?: string | null;
  isTierExpired?: boolean;
  lastRequest?: UserReviewLastRequest;
  /** @nullable */
  lastSignInDate?: string | null;
  /** @nullable */
  nickname?: string | null;
  /** @nullable */
  roles?: string[] | null;
  tier?: UserTierType;
  /** @nullable */
  tierExpiresAt?: string | null;
  /** @nullable */
  verificationError?: string | null;
  /** @nullable */
  verificationFailedAt?: string | null;
  verificationIsInProgress?: boolean;
  verificationLevel?: number;
  verificationStartedAt?: string;
}

/**
 * Request for processing eligibility
 */
export interface ProcessEligibilityRequest {
  /**
   * CorePassId of the user
   * @nullable
   * @pattern ^[a-zA-Z0-9\-]*$
   */
  corePassId?: string | null;
  /**
   * Email of the user
   * @nullable
   */
  email?: string | null;
}

export interface ProblemDetails {
  /** @nullable */
  detail?: string | null;
  /** @nullable */
  instance?: string | null;
  /** @nullable */
  status?: number | null;
  /** @nullable */
  title?: string | null;
  /** @nullable */
  type?: string | null;
  [key: string]: unknown;
}

export interface PortfolioItemResponse {
  accountId?: number;
  amount?: number;
  /** @nullable */
  assetId?: string | null;
  availableAmount?: number;
  availableBalance?: number;
  balance?: number;
  onHoldAmount?: number;
  onHoldBalance?: number;
  percentageOfTotal?: number;
}

export interface PortfolioResponse {
  accountId?: number;
  availableBalance?: number;
  /** @nullable */
  currencyId?: string | null;
  /** @nullable */
  portfolioItems?: PortfolioItemResponse[] | null;
  totalBalance?: number;
  totalOnHoldBalance?: number;
  /** @nullable */
  userId?: string | null;
  withdrawalThresholdReached?: boolean;
}

export interface PaymentSystemInfo {
  /** @nullable */
  assets?: string[] | null;
  /** @nullable */
  name?: string | null;
}

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethod = {
  AchBankTransfer: 'AchBankTransfer',
  CreditDebitCard: 'CreditDebitCard',
  GbpBankTransfer: 'GbpBankTransfer',
  GbpOpenBankingTransfer: 'GbpOpenBankingTransfer',
  MobileWallet: 'MobileWallet',
  SepaBankTransfer: 'SepaBankTransfer',
  SepaOpenBankingTransfer: 'SepaOpenBankingTransfer',
  PixInstantPayment: 'PixInstantPayment',
} as const;

export interface PaymentMethodData {
  code?: PaymentMethod;
  /** @nullable */
  name?: string | null;
}

export interface PagingData {
  /** @nullable */
  next?: string | null;
  /** @nullable */
  prev?: string | null;
}

export interface Paging {
  page?: number;
  per_page?: number;
  total?: number;
  total_elements?: number;
}

export interface OriginatorBeneficiaryModel {
  /**
   * The amount associated with the transaction.
   * @minimum 0
   */
  amount: number;
  /**
   * The asset ID.
   * @minLength 0
   * @maxLength 12
   * @pattern ^[a-zA-Z]{3,12}$
   */
  assetId: string;
  beneficiary?: TravelBeneficiary;
  originator?: TravelOriginator;
  /**
   * The VASP ID of the originator.
   * @minLength 0
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  originatorVASP: string;
  /**
   * The transaction ID.
   * @minLength 0
   * @maxLength 128
   * @pattern ^[a-zA-Z0-9-.\s]*$
   */
  transactionId: string;
}

export type OrderType = (typeof OrderType)[keyof typeof OrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderType = {
  Limit: 'Limit',
  Market: 'Market',
  StopLimit: 'StopLimit',
  StopMarket: 'StopMarket',
  UnPaid: 'UnPaid',
} as const;

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
  Working: 'Working',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  Expired: 'Expired',
  WaitingForPayment: 'WaitingForPayment',
  Pending: 'Pending',
  Sending: 'Sending',
} as const;

export type OrderSide = (typeof OrderSide)[keyof typeof OrderSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSide = {
  Buy: 'Buy',
  Sell: 'Sell',
} as const;

export interface OrderRequest {
  order?: OrderInfoRequest;
}

/**
 * @nullable
 */
export type OrderInfoResponseFilteredResponseFilters = unknown | null;

export interface OrderInfoResponse {
  amount?: number;
  cancelReason?: CancelReason;
  commission?: number;
  createdAt?: string;
  executionPrice?: number;
  /** @nullable */
  instrument?: string | null;
  isLimit?: boolean;
  isPending?: boolean;
  /** @nullable */
  orderId?: string | null;
  orderType?: OrderType;
  price?: number;
  /** @nullable */
  rejectDetails?: string | null;
  remaining?: number;
  side?: OrderSide;
  status?: OrderStatus;
  stopPrice?: number;
  total?: number;
  /** @nullable */
  type?: string | null;
  unitsFilled?: number;
  updatedAt?: string;
}

export interface OrderResponse {
  order?: OrderInfoResponse;
}

export interface OrderInfoResponseFilteredResponse {
  /** @nullable */
  data?: OrderInfoResponse[] | null;
  /** @nullable */
  filters?: OrderInfoResponseFilteredResponseFilters;
  paging?: Paging;
}

export interface OrderInfoRequest {
  activationPrice?: number;
  amount?: number;
  /** @nullable */
  instrument?: string | null;
  isLimit?: boolean;
  isStop?: boolean;
  /** Price of the order.
For Market orders, this property should be set to the VolumeWeightedPrice received from the GetQuote API.
- In Market sell orders, the Price is not utilized. */
  price?: number;
  selfMatchStrategy?: SelfMatchPreventionStrategy;
  /** @nullable */
  selfMatchToken?: string | null;
  /** @nullable */
  type?: string | null;
}

export interface NetworkItemInfo {
  /** @nullable */
  assets?: string[] | null;
  /** @nullable */
  name?: string | null;
}

export interface NetworkFeeResponse {
  readonly actualFee?: number;
  /** @nullable */
  assetId?: string | null;
  readonly composedFee?: number;
  readonly feeRate?: number;
  /** @nullable */
  readonly feeRateUnit?: string | null;
  readonly maximumAcceptableFeeRate?: number;
  /** @nullable */
  paymentSystem?: string | null;
  readonly thresholdReached?: boolean;
}

export interface MarketInfo {
  allowMarketOrder?: boolean;
  amountScale?: number;
  /** @nullable */
  baseAsset?: string | null;
  baseFee?: number;
  hidden?: boolean;
  /** @nullable */
  id?: string | null;
  makerFee?: number;
  makerFeeLimit?: number;
  maxPrice?: number;
  maxSellPriceVariationPercent?: number;
  minAmount?: number;
  minBuyPriceVariationPercent?: number;
  minPrice?: number;
  priceScale?: number;
  /** @nullable */
  quoteAsset?: string | null;
  quoteFee?: number;
  status?: ApiMarketStatus;
  takerFee?: number;
  takerFeeLimit?: number;
}

export interface LastUserReviewDocument {
  fileId?: string;
  /** @nullable */
  fileName?: string | null;
  /** @nullable */
  fileSize?: string | null;
  /** @nullable */
  kycVersion?: number | null;
}

export interface LastUserReviewResponse {
  /** @nullable */
  acknowledgedAt?: number | null;
  /** @nullable */
  comment?: string | null;
  /** @nullable */
  pending?: string | null;
  /** @nullable */
  readyToReviewAt?: string | null;
  requestId?: string;
  requestType?: UserReviewRequestType;
  statusType?: UserReviewStatusType;
  /** @nullable */
  uploads?: LastUserReviewDocument[] | null;
}

export interface Int32Response {
  data?: number;
}

/**
 * @nullable
 */
export type InfoResponsePairs = { [key: string]: MarketInfo } | null;

export interface InfoResponse {
  /** @nullable */
  pairs?: InfoResponsePairs;
  serverTime?: number;
}

export interface GetUserSettingResponse {
  /** @nullable */
  currentCurrency?: string | null;
  /** @nullable */
  currentMarketType?: string | null;
  /** @nullable */
  readonly favoriteMarkets?: readonly string[] | null;
  /** @nullable */
  notificationLanguage?: string | null;
  /** @nullable */
  notificationPreferences?: string | null;
  /** @nullable */
  presets?: string | null;
}

export interface GetUserSettingResponseResponse {
  data?: GetUserSettingResponse;
}

export interface GetQuoteResponse {
  expectedLastMatchPrice?: number;
  fillableAmount?: number;
  /** @deprecated */
  price?: number;
  volumeWeightedPrice?: number;
}

export interface GetCountriesResponse {
  /** @nullable */
  countries?: CountryData[] | null;
  userResidencyCountry?: CountryData;
}

export interface FormAResponse {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  addressEnglish?: string | null;
  /** @nullable */
  category?: string | null;
  /** @nullable */
  contractingPartner?: string | null;
  /** @nullable */
  corePassId?: string | null;
  /** @nullable */
  dateOfBirth?: string | null;
  expirationTime?: number;
  /** @nullable */
  fullName?: string | null;
  /** @nullable */
  fullNameEnglish?: string | null;
  /** @nullable */
  link?: string | null;
  /** @nullable */
  nationality?: string | null;
}

/**
 * @nullable
 */
export type ErrorData = { [key: string]: unknown } | null;

export interface Error {
  /** @nullable */
  code?: string | null;
  /** @nullable */
  data?: ErrorData;
  /** @nullable */
  key?: string | null;
  /** @nullable */
  message?: string | null;
}

export interface EligibleEmailRequest {
  amount?: number;
  /** @nullable */
  email?: string | null;
}

export type DocumentTypeRpc = (typeof DocumentTypeRpc)[keyof typeof DocumentTypeRpc];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentTypeRpc = {
  Passport: 'Passport',
  IdCard: 'IdCard',
  DriverLicense: 'DriverLicense',
  ResidencePermit: 'ResidencePermit',
  Address: 'Address',
  FormA: 'FormA',
  FaceImage: 'FaceImage',
  ProofOfFunds: 'ProofOfFunds',
  AdditionalImage: 'AdditionalImage',
} as const;

/**
 * Information about processed funds deposit request.
 */
export interface DepositFundsResponse {
  /** @nullable */
  address?: string | null;
  /** @nullable */
  asset?: string | null;
  /** @nullable */
  paymentSystem?: string | null;
}

export interface DepositFundsRequest {
  /** @nullable */
  amount?: number | null;
  /** @nullable */
  assetId?: string | null;
  /** @nullable */
  fiatPaymentMethod?: string | null;
  /** @nullable */
  paymentSystem?: string | null;
  /** @nullable */
  readonly paymentSystems?: readonly PaymentSystemInfo[] | null;
}

/**
 * Delete request
 */
export interface DeleteEligibleEmailRequest {
  /**
   * Email address of the eligibility to delete
   * @nullable
   */
  email?: string | null;
}

export interface CreateUserReviewRequest {
  type?: UserReviewRequestType;
}

export interface CreateRampingOrderRequest {
  amount: number;
  /** @minLength 1 */
  countryCode: string;
  paymentMethod: PaymentMethod;
  provider: RampingProvider;
  side: RampOrderSide;
  sourceAsset: AssetFilter;
  targetAsset: AssetFilter;
}

export interface CreateOrderResponse {
  /** @nullable */
  redirectUrl?: string | null;
}

export interface CountryData {
  /** @nullable */
  code?: string | null;
  /** @nullable */
  currency?: string | null;
  /** @nullable */
  name?: string | null;
}

export type CorePassDocumentType = (typeof CorePassDocumentType)[keyof typeof CorePassDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CorePassDocumentType = {
  Passport: 'Passport',
  IdCard: 'IdCard',
  DriverLicense: 'DriverLicense',
  ResidencePermit: 'ResidencePermit',
  Address: 'Address',
  FormA: 'FormA',
  FaceImage: 'FaceImage',
  ProofOfFunds: 'ProofOfFunds',
  AdditionalImage: 'AdditionalImage',
} as const;

export interface ContactFiatRecord {
  /** @nullable */
  accountName?: string | null;
  /** @nullable */
  accountSurname?: string | null;
  /** @nullable */
  bankAddressLine?: string | null;
  /** @nullable */
  bankAddressLineOption?: string | null;
  /** @nullable */
  bankCountry?: string | null;
  /** @nullable */
  bankName?: string | null;
  /** @nullable */
  country?: string | null;
  dateCreated?: string;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  iban?: string | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  personalAddress?: string | null;
  /** @nullable */
  personalAddressOptional?: string | null;
  /** @nullable */
  phoneNumber?: string | null;
  /** @nullable */
  swift?: string | null;
}

export interface ContactFiatData {
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  accountName?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  accountSurname?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  bankAddressLine?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  bankAddressLineOption?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  bankCountry?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  bankName?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  country?: string | null;
  dateCreated?: string;
  /** @nullable */
  email?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  iban?: string | null;
  id?: string;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  name?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  personalAddress?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  personalAddressOptional?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  phoneNumber?: string | null;
  /**
   * @nullable
   * @pattern ^[a-zA-Z0-9]*$
   */
  swift?: string | null;
}

export interface ContactCryptoResponse {
  /** @nullable */
  readonly accountName?: string | null;
  /** @nullable */
  readonly accountSurname?: string | null;
  /** @nullable */
  readonly assetId?: string | null;
  readonly dateCreated?: string;
  /** @nullable */
  readonly email?: string | null;
  readonly id?: string;
  /** @nullable */
  readonly network?: string | null;
  /** @nullable */
  readonly wallet?: string | null;
}

export interface ContactCryptoRequest {
  /** @nullable */
  accountName?: string | null;
  /** @nullable */
  accountSurname?: string | null;
  /** @nullable */
  email?: string | null;
  /** @nullable */
  network?: string | null;
  validateTestnetAddresses?: boolean;
  /** @nullable */
  wallet?: string | null;
}

export type ComplianceStepType = (typeof ComplianceStepType)[keyof typeof ComplianceStepType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceStepType = {
  NoData: 'NoData',
  EntityTypeDeclaration: 'EntityTypeDeclaration',
  KycRequest: 'KycRequest',
  FormA: 'FormA',
  PrimaryUse: 'PrimaryUse',
  PrimarySource: 'PrimarySource',
  EmploymentStatus: 'EmploymentStatus',
  TotalValue: 'TotalValue',
  Industry: 'Industry',
  SourceOfFunds: 'SourceOfFunds',
  Trading: 'Trading',
  PendingForReview: 'PendingForReview',
  Forbidden: 'Forbidden',
} as const;

/**
 * Claiming rewards request sent by the user indicating which reard they want to claim
 */
export interface ClaimRewardRequest {
  /** Reward Id */
  rewardId: string;
}

export type CancelReason = (typeof CancelReason)[keyof typeof CancelReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancelReason = {
  NotCancelled: 'NotCancelled',
  CancelledByTrader: 'CancelledByTrader',
  CancelledBySystem: 'CancelledBySystem',
  SelfMatchPrevention: 'SelfMatchPrevention',
  CancelledByAdmin: 'CancelledByAdmin',
  NoMoreMatchesAvailable: 'NoMoreMatchesAvailable',
} as const;

export interface BuildInfoResponse {
  /** @nullable */
  buildBranch?: string | null;
  /** @nullable */
  buildCommitHash?: string | null;
  /** @nullable */
  buildDate?: string | null;
}

export type BlockchainNetwork = (typeof BlockchainNetwork)[keyof typeof BlockchainNetwork];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlockchainNetwork = {
  Bitcoin: 'Bitcoin',
  Litecoin: 'Litecoin',
  Ethereum: 'Ethereum',
  CoreBlockchain: 'CoreBlockchain',
  BinanceSmartChain: 'BinanceSmartChain',
} as const;

export interface BlockchainNetworkData {
  code?: BlockchainNetwork;
  /** @nullable */
  name?: string | null;
}

export interface BasicAccountInfo {
  /** @nullable */
  code?: string | null;
  id?: string;
  /** @nullable */
  tags?: TagInfo[] | null;
  /** @nullable */
  tradeLimit?: number | null;
  type?: AccountType;
}

export interface AssetResponse {
  /** @nullable */
  asset_name?: string | null;
  can_deposit?: boolean;
  can_withdraw?: boolean;
  /** @nullable */
  confirmation_count?: number | null;
  created_at?: string;
  /** @nullable */
  id?: string | null;
  /** @nullable */
  min_withdraw?: number | null;
  /** @nullable */
  scale?: number | null;
  /** @nullable */
  withdrawal_fee?: number | null;
}

export interface AssetResponseListResponse {
  /** @nullable */
  data?: AssetResponse[] | null;
}

export interface AssetFilter {
  blockchainNetwork?: BlockchainNetwork;
  /** @minLength 1 */
  symbol: string;
}

/**
 * Market status reported in APIs. Corresponds to MyExchange.Common.Data.MarketStatus
 */
export type ApiMarketStatus = (typeof ApiMarketStatus)[keyof typeof ApiMarketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiMarketStatus = {
  Open: 'Open',
  Halted: 'Halted',
  Paused: 'Paused',
} as const;

export interface ApiErrorData {
  /** @nullable */
  code?: string | null;
  /** @nullable */
  key?: string | null;
  /** @nullable */
  message?: string | null;
  [key: string]: unknown;
}

export interface ApiErrorResponse {
  errorCode?: number;
  /** @nullable */
  errors?: ApiErrorData[] | null;
  /** @nullable */
  message?: string | null;
}

export type AnnouncementType = (typeof AnnouncementType)[keyof typeof AnnouncementType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnouncementType = {
  Banner: 'Banner',
  Modal: 'Modal',
  Board: 'Board',
} as const;

/**
 * Represents an announcement.
 */
export interface AnnouncementResponse {
  /** The time when the announcement was created. */
  createdAt?: string;
  /**
   * The description of the announcement.
   * @nullable
   */
  description?: string | null;
  /** The time when the announcement expires. */
  expiresAt?: string;
  /**
   * The link of the announcement.
   * @nullable
   */
  link?: string | null;
  /** The time when the announcement is released. */
  releasesAt?: string;
  /**
   * The title of the announcement.
   * @nullable
   */
  title?: string | null;
  type?: AnnouncementType;
}

export interface AddDocumentResponse {
  documentFileId?: string;
  /** @nullable */
  fileName?: string | null;
  /** @nullable */
  readonly fileSize?: string | null;
  size?: number;
}

export type ActivityStatus = (typeof ActivityStatus)[keyof typeof ActivityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityStatus = {
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
  Banned: 'Banned',
  WaitingForData: 'WaitingForData',
  WaitingForReview: 'WaitingForReview',
  Limited: 'Limited',
} as const;

export interface AcknowledgeRequest {
  requestId: string;
}

export type AccountType = (typeof AccountType)[keyof typeof AccountType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountType = {
  CASH: 'CASH',
  MARGIN: 'MARGIN',
} as const;

export interface AccountAssetResponse {
  /** @nullable */
  asset?: string | null;
  balance?: number;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns announcements which their display time is now.
 */
export const getAllAnnouncements = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AnnouncementResponse[]>(
    { url: `/frontoffice/api/v1/announcement`, method: 'GET', signal },
    options
  );
};

export const getGetAllAnnouncementsQueryKey = () => {
  return [`/frontoffice/api/v1/announcement`] as const;
};

export const getGetAllAnnouncementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllAnnouncements>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAnnouncements>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllAnnouncementsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAnnouncements>>> = ({ signal }) =>
    getAllAnnouncements(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllAnnouncements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllAnnouncementsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAnnouncements>>>;
export type GetAllAnnouncementsQueryError = ErrorType<unknown>;

/**
 * @summary Returns announcements which their display time is now.
 */
export const useGetAllAnnouncements = <
  TData = Awaited<ReturnType<typeof getAllAnnouncements>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAnnouncements>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllAnnouncementsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all Assets
 */
export const getAllAsset = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AssetResponseListResponse>(
    { url: `/frontoffice/api/assets-info`, method: 'GET', signal },
    options
  );
};

export const getGetAllAssetQueryKey = () => {
  return [`/frontoffice/api/assets-info`] as const;
};

export const getGetAllAssetQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllAsset>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAsset>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllAssetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAsset>>> = ({ signal }) =>
    getAllAsset(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllAsset>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAsset>>>;
export type GetAllAssetQueryError = ErrorType<unknown>;

/**
 * @summary Gets all Assets
 */
export const useGetAllAsset = <TData = Awaited<ReturnType<typeof getAllAsset>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAsset>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllAssetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the next step that needs to be shown to the user if they want to proceed with compliance tier upgrade.
 */
export const getNextStepCompliance = (
  params?: GetNextStepComplianceParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ComplianceStepType>(
    { url: `/frontoffice/api/compliance/get-next-step`, method: 'GET', params, signal },
    options
  );
};

export const getGetNextStepComplianceQueryKey = (params?: GetNextStepComplianceParams) => {
  return [`/frontoffice/api/compliance/get-next-step`, ...(params ? [params] : [])] as const;
};

export const getGetNextStepComplianceQueryOptions = <
  TData = Awaited<ReturnType<typeof getNextStepCompliance>>,
  TError = ErrorType<void>
>(
  params?: GetNextStepComplianceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNextStepCompliance>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNextStepComplianceQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNextStepCompliance>>> = ({ signal }) =>
    getNextStepCompliance(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNextStepCompliance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNextStepComplianceQueryResult = NonNullable<Awaited<ReturnType<typeof getNextStepCompliance>>>;
export type GetNextStepComplianceQueryError = ErrorType<void>;

/**
 * @summary Returns the next step that needs to be shown to the user if they want to proceed with compliance tier upgrade.
 */
export const useGetNextStepCompliance = <
  TData = Awaited<ReturnType<typeof getNextStepCompliance>>,
  TError = ErrorType<void>
>(
  params?: GetNextStepComplianceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNextStepCompliance>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNextStepComplianceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPreviousStepCompliance = (
  params?: GetPreviousStepComplianceParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ComplianceStepType>(
    { url: `/frontoffice/api/compliance/get-previous-step`, method: 'GET', params, signal },
    options
  );
};

export const getGetPreviousStepComplianceQueryKey = (params?: GetPreviousStepComplianceParams) => {
  return [`/frontoffice/api/compliance/get-previous-step`, ...(params ? [params] : [])] as const;
};

export const getGetPreviousStepComplianceQueryOptions = <
  TData = Awaited<ReturnType<typeof getPreviousStepCompliance>>,
  TError = ErrorType<void>
>(
  params?: GetPreviousStepComplianceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPreviousStepCompliance>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPreviousStepComplianceQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPreviousStepCompliance>>> = ({ signal }) =>
    getPreviousStepCompliance(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPreviousStepCompliance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPreviousStepComplianceQueryResult = NonNullable<Awaited<ReturnType<typeof getPreviousStepCompliance>>>;
export type GetPreviousStepComplianceQueryError = ErrorType<void>;

export const useGetPreviousStepCompliance = <
  TData = Awaited<ReturnType<typeof getPreviousStepCompliance>>,
  TError = ErrorType<void>
>(
  params?: GetPreviousStepComplianceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPreviousStepCompliance>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPreviousStepComplianceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates new review request in Open state, using given RequestType.
 */
export const createCompliance = (
  createUserReviewRequest: CreateUserReviewRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/compliance`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createUserReviewRequest,
    },
    options
  );
};

export const getCreateComplianceMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCompliance>>,
    TError,
    { data: CreateUserReviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCompliance>>,
  TError,
  { data: CreateUserReviewRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCompliance>>,
    { data: CreateUserReviewRequest }
  > = props => {
    const { data } = props ?? {};

    return createCompliance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateComplianceMutationResult = NonNullable<Awaited<ReturnType<typeof createCompliance>>>;
export type CreateComplianceMutationBody = CreateUserReviewRequest;
export type CreateComplianceMutationError = ErrorType<void>;

/**
 * @summary Creates new review request in Open state, using given RequestType.
 */
export const useCreateCompliance = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCompliance>>,
    TError,
    { data: CreateUserReviewRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCompliance>>,
  TError,
  { data: CreateUserReviewRequest },
  TContext
> => {
  const mutationOptions = getCreateComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Cancels active review, Returns true, if any active review is found and cancelled, otherwise returns false.
 */
export const cancelActiveReviewCompliance = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/compliance/cancel`, method: 'POST' }, options);
};

export const getCancelActiveReviewComplianceMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelActiveReviewCompliance>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelActiveReviewCompliance>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelActiveReviewCompliance>>, void> = () => {
    return cancelActiveReviewCompliance(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelActiveReviewComplianceMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelActiveReviewCompliance>>
>;

export type CancelActiveReviewComplianceMutationError = ErrorType<void>;

/**
 * @summary Cancels active review, Returns true, if any active review is found and cancelled, otherwise returns false.
 */
export const useCancelActiveReviewCompliance = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelActiveReviewCompliance>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof cancelActiveReviewCompliance>>, TError, void, TContext> => {
  const mutationOptions = getCancelActiveReviewComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets last request even when it's rejected
 */
export const getLastRequestCompliance = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<LastUserReviewResponse | void>(
    { url: `/frontoffice/api/compliance/active`, method: 'GET', signal },
    options
  );
};

export const getGetLastRequestComplianceQueryKey = () => {
  return [`/frontoffice/api/compliance/active`] as const;
};

export const getGetLastRequestComplianceQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastRequestCompliance>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLastRequestCompliance>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLastRequestComplianceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLastRequestCompliance>>> = ({ signal }) =>
    getLastRequestCompliance(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastRequestCompliance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastRequestComplianceQueryResult = NonNullable<Awaited<ReturnType<typeof getLastRequestCompliance>>>;
export type GetLastRequestComplianceQueryError = ErrorType<void>;

/**
 * @summary Gets last request even when it's rejected
 */
export const useGetLastRequestCompliance = <
  TData = Awaited<ReturnType<typeof getLastRequestCompliance>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLastRequestCompliance>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLastRequestComplianceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Submits the open compliance review request that updates request status to PendingForReview
 */
export const submitReviewRequestCompliance = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/frontoffice/api/compliance/submit`, method: 'GET', signal }, options);
};

export const getSubmitReviewRequestComplianceQueryKey = () => {
  return [`/frontoffice/api/compliance/submit`] as const;
};

export const getSubmitReviewRequestComplianceQueryOptions = <
  TData = Awaited<ReturnType<typeof submitReviewRequestCompliance>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof submitReviewRequestCompliance>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubmitReviewRequestComplianceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof submitReviewRequestCompliance>>> = ({ signal }) =>
    submitReviewRequestCompliance(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof submitReviewRequestCompliance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SubmitReviewRequestComplianceQueryResult = NonNullable<
  Awaited<ReturnType<typeof submitReviewRequestCompliance>>
>;
export type SubmitReviewRequestComplianceQueryError = ErrorType<void>;

/**
 * @summary Submits the open compliance review request that updates request status to PendingForReview
 */
export const useSubmitReviewRequestCompliance = <
  TData = Awaited<ReturnType<typeof submitReviewRequestCompliance>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof submitReviewRequestCompliance>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSubmitReviewRequestComplianceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Acknowledges user review request which is rejected.
 */
export const acknowledgeRejectedReviewCompliance = (
  acknowledgeRequest: AcknowledgeRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/compliance/acknowledge`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: acknowledgeRequest,
    },
    options
  );
};

export const getAcknowledgeRejectedReviewComplianceMutationOptions = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>,
    TError,
    { data: AcknowledgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>,
  TError,
  { data: AcknowledgeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>,
    { data: AcknowledgeRequest }
  > = props => {
    const { data } = props ?? {};

    return acknowledgeRejectedReviewCompliance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcknowledgeRejectedReviewComplianceMutationResult = NonNullable<
  Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>
>;
export type AcknowledgeRejectedReviewComplianceMutationBody = AcknowledgeRequest;
export type AcknowledgeRejectedReviewComplianceMutationError = ErrorType<void | ApiErrorResponse>;

/**
 * @summary Acknowledges user review request which is rejected.
 */
export const useAcknowledgeRejectedReviewCompliance = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>,
    TError,
    { data: AcknowledgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof acknowledgeRejectedReviewCompliance>>,
  TError,
  { data: AcknowledgeRequest },
  TContext
> => {
  const mutationOptions = getAcknowledgeRejectedReviewComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets all ContactCrypto related to current logged-in user
 */
export const getAllContactsCryptoContactCrypto = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ContactCryptoResponse[]>(
    { url: `/frontoffice/api/contactcrypto`, method: 'GET', signal },
    options
  );
};

export const getGetAllContactsCryptoContactCryptoQueryKey = () => {
  return [`/frontoffice/api/contactcrypto`] as const;
};

export const getGetAllContactsCryptoContactCryptoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllContactsCryptoContactCryptoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>> = ({ signal }) =>
    getAllContactsCryptoContactCrypto(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllContactsCryptoContactCryptoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>
>;
export type GetAllContactsCryptoContactCryptoQueryError = ErrorType<void>;

/**
 * @summary Gets all ContactCrypto related to current logged-in user
 */
export const useGetAllContactsCryptoContactCrypto = <
  TData = Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllContactsCryptoContactCryptoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createContactCrypto = (
  contactCryptoRequest: ContactCryptoRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/contactcrypto`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactCryptoRequest,
    },
    options
  );
};

export const getCreateContactCryptoMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContactCrypto>>,
    TError,
    { data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createContactCrypto>>,
  TError,
  { data: ContactCryptoRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContactCrypto>>,
    { data: ContactCryptoRequest }
  > = props => {
    const { data } = props ?? {};

    return createContactCrypto(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof createContactCrypto>>>;
export type CreateContactCryptoMutationBody = ContactCryptoRequest;
export type CreateContactCryptoMutationError = ErrorType<void>;

export const useCreateContactCrypto = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContactCrypto>>,
    TError,
    { data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createContactCrypto>>,
  TError,
  { data: ContactCryptoRequest },
  TContext
> => {
  const mutationOptions = getCreateContactCryptoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getContactCryptoById = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ContactCryptoResponse>(
    { url: `/frontoffice/api/contactcrypto/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetContactCryptoByIdQueryKey = (id: string) => {
  return [`/frontoffice/api/contactcrypto/${id}`] as const;
};

export const getGetContactCryptoByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactCryptoById>>,
  TError = ErrorType<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContactCryptoById>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactCryptoByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactCryptoById>>> = ({ signal }) =>
    getContactCryptoById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactCryptoById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContactCryptoByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContactCryptoById>>>;
export type GetContactCryptoByIdQueryError = ErrorType<void>;

export const useGetContactCryptoById = <
  TData = Awaited<ReturnType<typeof getContactCryptoById>>,
  TError = ErrorType<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContactCryptoById>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactCryptoByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateContactCrypto = (
  id: string,
  contactCryptoRequest: ContactCryptoRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ContactCryptoResponse>(
    {
      url: `/frontoffice/api/contactcrypto/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactCryptoRequest,
    },
    options
  );
};

export const getUpdateContactCryptoMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    TError,
    { id: string; data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateContactCrypto>>,
  TError,
  { id: string; data: ContactCryptoRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    { id: string; data: ContactCryptoRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateContactCrypto(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof updateContactCrypto>>>;
export type UpdateContactCryptoMutationBody = ContactCryptoRequest;
export type UpdateContactCryptoMutationError = ErrorType<void>;

export const useUpdateContactCrypto = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    TError,
    { id: string; data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateContactCrypto>>,
  TError,
  { id: string; data: ContactCryptoRequest },
  TContext
> => {
  const mutationOptions = getUpdateContactCryptoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteContactCrypto = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/contactcrypto/${id}`, method: 'DELETE' }, options);
};

export const getDeleteContactCryptoMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactCrypto>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteContactCrypto(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactCrypto>>>;

export type DeleteContactCryptoMutationError = ErrorType<void>;

export const useDeleteContactCrypto = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteContactCryptoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateContactFiat = (
  contactFiatData: ContactFiatData,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ContactFiatRecord>(
    {
      url: `/frontoffice/api/contactfiat`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactFiatData,
    },
    options
  );
};

export const getUpdateContactFiatMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactFiat>>,
    TError,
    { data: ContactFiatData },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateContactFiat>>, TError, { data: ContactFiatData }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContactFiat>>,
    { data: ContactFiatData }
  > = props => {
    const { data } = props ?? {};

    return updateContactFiat(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateContactFiatMutationResult = NonNullable<Awaited<ReturnType<typeof updateContactFiat>>>;
export type UpdateContactFiatMutationBody = ContactFiatData;
export type UpdateContactFiatMutationError = ErrorType<void>;

export const useUpdateContactFiat = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactFiat>>,
    TError,
    { data: ContactFiatData },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof updateContactFiat>>, TError, { data: ContactFiatData }, TContext> => {
  const mutationOptions = getUpdateContactFiatMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteContactFiat = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ContactFiatRecord>({ url: `/frontoffice/api/contactfiat/${id}`, method: 'DELETE' }, options);
};

export const getDeleteContactFiatMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactFiat>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteContactFiat(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContactFiatMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactFiat>>>;

export type DeleteContactFiatMutationError = ErrorType<void>;

export const useDeleteContactFiat = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteContactFiatMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary For testing purposes and possibility of adding eligible emails by admin
 */
export const addEligibleEmail = (
  eligibleEmailRequest: EligibleEmailRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/eligibleemails`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: eligibleEmailRequest,
    },
    options
  );
};

export const getAddEligibleEmailMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEligibleEmail>>,
    TError,
    { data: EligibleEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addEligibleEmail>>,
  TError,
  { data: EligibleEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addEligibleEmail>>,
    { data: EligibleEmailRequest }
  > = props => {
    const { data } = props ?? {};

    return addEligibleEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddEligibleEmailMutationResult = NonNullable<Awaited<ReturnType<typeof addEligibleEmail>>>;
export type AddEligibleEmailMutationBody = EligibleEmailRequest;
export type AddEligibleEmailMutationError = ErrorType<void>;

/**
 * @summary For testing purposes and possibility of adding eligible emails by admin
 */
export const useAddEligibleEmail = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEligibleEmail>>,
    TError,
    { data: EligibleEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addEligibleEmail>>,
  TError,
  { data: EligibleEmailRequest },
  TContext
> => {
  const mutationOptions = getAddEligibleEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary For testing purposes lets the admin delete an eligibility record
 */
export const deleteEligibleEmail = (
  deleteEligibleEmailRequest: DeleteEligibleEmailRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/eligibleemails`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: deleteEligibleEmailRequest,
    },
    options
  );
};

export const getDeleteEligibleEmailMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEligibleEmail>>,
    TError,
    { data: DeleteEligibleEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEligibleEmail>>,
  TError,
  { data: DeleteEligibleEmailRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEligibleEmail>>,
    { data: DeleteEligibleEmailRequest }
  > = props => {
    const { data } = props ?? {};

    return deleteEligibleEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEligibleEmailMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEligibleEmail>>>;
export type DeleteEligibleEmailMutationBody = DeleteEligibleEmailRequest;
export type DeleteEligibleEmailMutationError = ErrorType<void>;

/**
 * @summary For testing purposes lets the admin delete an eligibility record
 */
export const useDeleteEligibleEmail = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEligibleEmail>>,
    TError,
    { data: DeleteEligibleEmailRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEligibleEmail>>,
  TError,
  { data: DeleteEligibleEmailRequest },
  TContext
> => {
  const mutationOptions = getDeleteEligibleEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary For testing purposes and possibility of processing eligible emails by admin
 */
export const processEligibilityEligibleEmail = (
  processEligibilityRequest: ProcessEligibilityRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/eligibleemails/process`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: processEligibilityRequest,
    },
    options
  );
};

export const getProcessEligibilityEligibleEmailMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processEligibilityEligibleEmail>>,
    TError,
    { data: ProcessEligibilityRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processEligibilityEligibleEmail>>,
  TError,
  { data: ProcessEligibilityRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processEligibilityEligibleEmail>>,
    { data: ProcessEligibilityRequest }
  > = props => {
    const { data } = props ?? {};

    return processEligibilityEligibleEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessEligibilityEligibleEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof processEligibilityEligibleEmail>>
>;
export type ProcessEligibilityEligibleEmailMutationBody = ProcessEligibilityRequest;
export type ProcessEligibilityEligibleEmailMutationError = ErrorType<void>;

/**
 * @summary For testing purposes and possibility of processing eligible emails by admin
 */
export const useProcessEligibilityEligibleEmail = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processEligibilityEligibleEmail>>,
    TError,
    { data: ProcessEligibilityRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof processEligibilityEligibleEmail>>,
  TError,
  { data: ProcessEligibilityRequest },
  TContext
> => {
  const mutationOptions = getProcessEligibilityEligibleEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets a file, validate request and uploads to google cloud storage. In the 
content-disposition section, filename is mandatory and must be supplied. 
This API could not test directly in swagger. 
Use some tools like Postman or Insomnia instead.
 */
export const uploadFileToCloudStorageFileTransfer = (
  params: UploadFileToCloudStorageFileTransferParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AddDocumentResponse>(
    { url: `/frontoffice/api/v1/file-transfer/upload`, method: 'POST', params },
    options
  );
};

export const getUploadFileToCloudStorageFileTransferMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void | ProblemDetails>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>,
    TError,
    { params: UploadFileToCloudStorageFileTransferParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>,
  TError,
  { params: UploadFileToCloudStorageFileTransferParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>,
    { params: UploadFileToCloudStorageFileTransferParams }
  > = props => {
    const { params } = props ?? {};

    return uploadFileToCloudStorageFileTransfer(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadFileToCloudStorageFileTransferMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>
>;

export type UploadFileToCloudStorageFileTransferMutationError = ErrorType<ApiErrorResponse | void | ProblemDetails>;

/**
 * @summary Gets a file, validate request and uploads to google cloud storage. In the 
content-disposition section, filename is mandatory and must be supplied. 
This API could not test directly in swagger. 
Use some tools like Postman or Insomnia instead.
 */
export const useUploadFileToCloudStorageFileTransfer = <
  TError = ErrorType<ApiErrorResponse | void | ProblemDetails>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>,
    TError,
    { params: UploadFileToCloudStorageFileTransferParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadFileToCloudStorageFileTransfer>>,
  TError,
  { params: UploadFileToCloudStorageFileTransferParams },
  TContext
> => {
  const mutationOptions = getUploadFileToCloudStorageFileTransferMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Download a document by given ID. Only Admin and ComplianceReviewer are allowed to access this endpoint.
 */
export const downloadFileFromCloudStorageFileTransfer = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    { url: `/frontoffice/api/v1/file-transfer/download/${id}`, method: 'GET', signal },
    options
  );
};

export const getDownloadFileFromCloudStorageFileTransferQueryKey = (id: string) => {
  return [`/frontoffice/api/v1/file-transfer/download/${id}`] as const;
};

export const getDownloadFileFromCloudStorageFileTransferQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>,
  TError = ErrorType<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadFileFromCloudStorageFileTransferQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>> = ({ signal }) =>
    downloadFileFromCloudStorageFileTransfer(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadFileFromCloudStorageFileTransferQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>
>;
export type DownloadFileFromCloudStorageFileTransferQueryError = ErrorType<void>;

/**
 * @summary Download a document by given ID. Only Admin and ComplianceReviewer are allowed to access this endpoint.
 */
export const useDownloadFileFromCloudStorageFileTransfer = <
  TData = Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>,
  TError = ErrorType<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadFileFromCloudStorageFileTransfer>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFileFromCloudStorageFileTransferQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<InfoResponse>({ url: `/frontoffice/api/info`, method: 'GET', signal }, options);
};

export const getGetInfoQueryKey = () => {
  return [`/frontoffice/api/info`] as const;
};

export const getGetInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getInfo>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfo>>> = ({ signal }) => getInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getInfo>>>;
export type GetInfoQueryError = ErrorType<unknown>;

export const useGetInfo = <TData = Awaited<ReturnType<typeof getInfo>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns system build date, branch and commit hash.
 */
export const getBuildInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<BuildInfoResponse>({ url: `/frontoffice/api/build-info`, method: 'GET', signal }, options);
};

export const getGetBuildInfoQueryKey = () => {
  return [`/frontoffice/api/build-info`] as const;
};

export const getGetBuildInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getBuildInfo>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBuildInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBuildInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBuildInfo>>> = ({ signal }) =>
    getBuildInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBuildInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBuildInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildInfo>>>;
export type GetBuildInfoQueryError = ErrorType<unknown>;

/**
 * @summary Returns system build date, branch and commit hash.
 */
export const useGetBuildInfo = <
  TData = Awaited<ReturnType<typeof getBuildInfo>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBuildInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBuildInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary This method is called by corepass connector when data is ready or it's failed
 */
export const kycCallback = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/kyc/callback`, method: 'POST' }, options);
};

export const getKycCallbackMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycCallback>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof kycCallback>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof kycCallback>>, void> = () => {
    return kycCallback(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type KycCallbackMutationResult = NonNullable<Awaited<ReturnType<typeof kycCallback>>>;

export type KycCallbackMutationError = ErrorType<unknown>;

/**
 * @summary This method is called by corepass connector when data is ready or it's failed
 */
export const useKycCallback = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycCallback>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof kycCallback>>, TError, void, TContext> => {
  const mutationOptions = getKycCallbackMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary This method is called by corepass connector when request status gets updated
 */
export const kycStatusCallback = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/kyc/statusCallback`, method: 'POST' }, options);
};

export const getKycStatusCallbackMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycStatusCallback>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof kycStatusCallback>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof kycStatusCallback>>, void> = () => {
    return kycStatusCallback(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type KycStatusCallbackMutationResult = NonNullable<Awaited<ReturnType<typeof kycStatusCallback>>>;

export type KycStatusCallbackMutationError = ErrorType<unknown>;

/**
 * @summary This method is called by corepass connector when request status gets updated
 */
export const useKycStatusCallback = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycStatusCallback>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof kycStatusCallback>>, TError, void, TContext> => {
  const mutationOptions = getKycStatusCallbackMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary This method uses corepassId in the claim and creates a request for kyc data after
    Checking that the data is verified. The request is returned in qrcode and link
    Which can be returned to user to sign.
 */
export const kycRequest = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<QrCodeData>({ url: `/frontoffice/api/v2/kyc/request`, method: 'POST' }, options);
};

export const getKycRequestMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycRequest>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof kycRequest>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof kycRequest>>, void> = () => {
    return kycRequest(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type KycRequestMutationResult = NonNullable<Awaited<ReturnType<typeof kycRequest>>>;

export type KycRequestMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary This method uses corepassId in the claim and creates a request for kyc data after
    Checking that the data is verified. The request is returned in qrcode and link
    Which can be returned to user to sign.
 */
export const useKycRequest = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof kycRequest>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof kycRequest>>, TError, void, TContext> => {
  const mutationOptions = getKycRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Invalidates vault data
 */
export const invalidateKyc = (params: InvalidateKycParams, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/kyc/invalidate`, method: 'POST', params }, options);
};

export const getInvalidateKycMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invalidateKyc>>,
    TError,
    { params: InvalidateKycParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invalidateKyc>>,
  TError,
  { params: InvalidateKycParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invalidateKyc>>,
    { params: InvalidateKycParams }
  > = props => {
    const { params } = props ?? {};

    return invalidateKyc(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvalidateKycMutationResult = NonNullable<Awaited<ReturnType<typeof invalidateKyc>>>;

export type InvalidateKycMutationError = ErrorType<void>;

/**
 * @summary Invalidates vault data
 */
export const useInvalidateKyc = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invalidateKyc>>,
    TError,
    { params: InvalidateKycParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof invalidateKyc>>, TError, { params: InvalidateKycParams }, TContext> => {
  const mutationOptions = getInvalidateKycMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Checks if given email has done KYC or not.
 */
export const checkKycByEmail = (
  email: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/kyc/valid-emails/${email}`, method: 'GET', signal }, options);
};

export const getCheckKycByEmailQueryKey = (email: string) => {
  return [`/frontoffice/api/v2/kyc/valid-emails/${email}`] as const;
};

export const getCheckKycByEmailQueryOptions = <
  TData = Awaited<ReturnType<typeof checkKycByEmail>>,
  TError = ErrorType<ProblemDetails>
>(
  email: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof checkKycByEmail>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckKycByEmailQueryKey(email);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkKycByEmail>>> = ({ signal }) =>
    checkKycByEmail(email, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!email, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkKycByEmail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckKycByEmailQueryResult = NonNullable<Awaited<ReturnType<typeof checkKycByEmail>>>;
export type CheckKycByEmailQueryError = ErrorType<ProblemDetails>;

/**
 * @summary Checks if given email has done KYC or not.
 */
export const useCheckKycByEmail = <
  TData = Awaited<ReturnType<typeof checkKycByEmail>>,
  TError = ErrorType<ProblemDetails>
>(
  email: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof checkKycByEmail>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckKycByEmailQueryOptions(email, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary The controller returns users' current asset values with the estimated price based on the current currency.
Sorted by the estimated value in descending order.
param: CurrencyId - the id of the currency which the amount has been converted to.
 */
export const getPortfolio = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<PortfolioResponse>({ url: `/frontoffice/api/v2/portfolio`, method: 'GET', signal }, options);
};

export const getGetPortfolioQueryKey = () => {
  return [`/frontoffice/api/v2/portfolio`] as const;
};

export const getGetPortfolioQueryOptions = <
  TData = Awaited<ReturnType<typeof getPortfolio>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPortfolio>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPortfolioQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPortfolio>>> = ({ signal }) =>
    getPortfolio(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPortfolio>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPortfolioQueryResult = NonNullable<Awaited<ReturnType<typeof getPortfolio>>>;
export type GetPortfolioQueryError = ErrorType<void>;

/**
 * @summary The controller returns users' current asset values with the estimated price based on the current currency.
Sorted by the estimated value in descending order.
param: CurrencyId - the id of the currency which the amount has been converted to.
 */
export const useGetPortfolio = <TData = Awaited<ReturnType<typeof getPortfolio>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPortfolio>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPortfolioQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary This is for testing purposes and should not be used by any part of UI. 
This endpoint replaces the corepassId with some random Id
This will not be available in the production
 */
export const resetCorepassIdProfile = (
  params?: ResetCorepassIdProfileParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/frontoffice/api/profile/reset`, method: 'POST', params }, options);
};

export const getResetCorepassIdProfileMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetCorepassIdProfile>>,
    TError,
    { params?: ResetCorepassIdProfileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetCorepassIdProfile>>,
  TError,
  { params?: ResetCorepassIdProfileParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetCorepassIdProfile>>,
    { params?: ResetCorepassIdProfileParams }
  > = props => {
    const { params } = props ?? {};

    return resetCorepassIdProfile(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetCorepassIdProfileMutationResult = NonNullable<Awaited<ReturnType<typeof resetCorepassIdProfile>>>;

export type ResetCorepassIdProfileMutationError = ErrorType<void>;

/**
 * @summary This is for testing purposes and should not be used by any part of UI. 
This endpoint replaces the corepassId with some random Id
This will not be available in the production
 */
export const useResetCorepassIdProfile = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetCorepassIdProfile>>,
    TError,
    { params?: ResetCorepassIdProfileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetCorepassIdProfile>>,
  TError,
  { params?: ResetCorepassIdProfileParams },
  TContext
> => {
  const mutationOptions = getResetCorepassIdProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary This is for testing purposes only and should not be used by any part of the UI. 
This endpoint sends a message to specific user identified by the corepassId
 */
export const notificationProfile = (
  params?: NotificationProfileParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>({ url: `/frontoffice/api/profile/notification`, method: 'POST', params }, options);
};

export const getNotificationProfileMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationProfile>>,
    TError,
    { params?: NotificationProfileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationProfile>>,
  TError,
  { params?: NotificationProfileParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationProfile>>,
    { params?: NotificationProfileParams }
  > = props => {
    const { params } = props ?? {};

    return notificationProfile(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationProfileMutationResult = NonNullable<Awaited<ReturnType<typeof notificationProfile>>>;

export type NotificationProfileMutationError = ErrorType<void>;

/**
 * @summary This is for testing purposes only and should not be used by any part of the UI. 
This endpoint sends a message to specific user identified by the corepassId
 */
export const useNotificationProfile = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationProfile>>,
    TError,
    { params?: NotificationProfileParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationProfile>>,
  TError,
  { params?: NotificationProfileParams },
  TContext
> => {
  const mutationOptions = getNotificationProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets the user profile based on the corepassId or creates a new user if doesn't exist and returns it
 */
export const getOrCreateProfile = (
  params?: GetOrCreateProfileParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProfileResponse>({ url: `/frontoffice/api/profile`, method: 'GET', params, signal }, options);
};

export const getGetOrCreateProfileQueryKey = (params?: GetOrCreateProfileParams) => {
  return [`/frontoffice/api/profile`, ...(params ? [params] : [])] as const;
};

export const getGetOrCreateProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrCreateProfile>>,
  TError = ErrorType<void>
>(
  params?: GetOrCreateProfileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrCreateProfile>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrCreateProfileQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrCreateProfile>>> = ({ signal }) =>
    getOrCreateProfile(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrCreateProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrCreateProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getOrCreateProfile>>>;
export type GetOrCreateProfileQueryError = ErrorType<void>;

/**
 * @summary Gets the user profile based on the corepassId or creates a new user if doesn't exist and returns it
 */
export const useGetOrCreateProfile = <TData = Awaited<ReturnType<typeof getOrCreateProfile>>, TError = ErrorType<void>>(
  params?: GetOrCreateProfileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrCreateProfile>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrCreateProfileQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Steps to call ramp APIs:
- Call `api/ramp/countries` to get countries from active providers
- Call `api/ramp/assets` to get assets from active providers
- Call `api/v1/ramp/payment-methods` to get available payment-methods (here if user selected source and target assets, we can pass them as parameters to `payment-methods` API)
- Call `api/ramp/quotes` to get quotes based on provided paramters(here we can call this API every N seconds and/or when user changed the inputs)
- Call `api/ramp/orders` to create a ramp order based on parameters, result will be a redirect url, that user should be redirected to.
 * @summary Get countries based on active providers
 */
export const getCountriesRamp = (
  params: GetCountriesRampParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetCountriesResponse>(
    { url: `/frontoffice/api/ramp/countries`, method: 'GET', params, signal },
    options
  );
};

export const getGetCountriesRampQueryKey = (params: GetCountriesRampParams) => {
  return [`/frontoffice/api/ramp/countries`, ...(params ? [params] : [])] as const;
};

export const getGetCountriesRampQueryOptions = <
  TData = Awaited<ReturnType<typeof getCountriesRamp>>,
  TError = ErrorType<void>
>(
  params: GetCountriesRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCountriesRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCountriesRampQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountriesRamp>>> = ({ signal }) =>
    getCountriesRamp(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCountriesRamp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCountriesRampQueryResult = NonNullable<Awaited<ReturnType<typeof getCountriesRamp>>>;
export type GetCountriesRampQueryError = ErrorType<void>;

/**
 * @summary Get countries based on active providers
 */
export const useGetCountriesRamp = <TData = Awaited<ReturnType<typeof getCountriesRamp>>, TError = ErrorType<void>>(
  params: GetCountriesRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCountriesRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCountriesRampQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAssetsRamp = (
  params: GetAssetsRampParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RampingAssetData[]>(
    { url: `/frontoffice/api/ramp/assets`, method: 'GET', params, signal },
    options
  );
};

export const getGetAssetsRampQueryKey = (params: GetAssetsRampParams) => {
  return [`/frontoffice/api/ramp/assets`, ...(params ? [params] : [])] as const;
};

export const getGetAssetsRampQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssetsRamp>>,
  TError = ErrorType<void>
>(
  params: GetAssetsRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetsRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetsRampQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsRamp>>> = ({ signal }) =>
    getAssetsRamp(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAssetsRamp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAssetsRampQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsRamp>>>;
export type GetAssetsRampQueryError = ErrorType<void>;

export const useGetAssetsRamp = <TData = Awaited<ReturnType<typeof getAssetsRamp>>, TError = ErrorType<void>>(
  params: GetAssetsRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetsRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAssetsRampQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPaymentMethodsRamp = (
  params: GetPaymentMethodsRampParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaymentMethodData[]>(
    { url: `/frontoffice/api/ramp/payment-methods`, method: 'GET', params, signal },
    options
  );
};

export const getGetPaymentMethodsRampQueryKey = (params: GetPaymentMethodsRampParams) => {
  return [`/frontoffice/api/ramp/payment-methods`, ...(params ? [params] : [])] as const;
};

export const getGetPaymentMethodsRampQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMethodsRamp>>,
  TError = ErrorType<void>
>(
  params: GetPaymentMethodsRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodsRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodsRampQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentMethodsRamp>>> = ({ signal }) =>
    getPaymentMethodsRamp(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMethodsRamp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPaymentMethodsRampQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentMethodsRamp>>>;
export type GetPaymentMethodsRampQueryError = ErrorType<void>;

export const useGetPaymentMethodsRamp = <
  TData = Awaited<ReturnType<typeof getPaymentMethodsRamp>>,
  TError = ErrorType<void>
>(
  params: GetPaymentMethodsRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethodsRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentMethodsRampQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getQuotesRamp = (
  params: GetQuotesRampParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<QuoteData[]>({ url: `/frontoffice/api/ramp/quotes`, method: 'GET', params, signal }, options);
};

export const getGetQuotesRampQueryKey = (params: GetQuotesRampParams) => {
  return [`/frontoffice/api/ramp/quotes`, ...(params ? [params] : [])] as const;
};

export const getGetQuotesRampQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuotesRamp>>,
  TError = ErrorType<void>
>(
  params: GetQuotesRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getQuotesRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuotesRampQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuotesRamp>>> = ({ signal }) =>
    getQuotesRamp(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuotesRamp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuotesRampQueryResult = NonNullable<Awaited<ReturnType<typeof getQuotesRamp>>>;
export type GetQuotesRampQueryError = ErrorType<void>;

export const useGetQuotesRamp = <TData = Awaited<ReturnType<typeof getQuotesRamp>>, TError = ErrorType<void>>(
  params: GetQuotesRampParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getQuotesRamp>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetQuotesRampQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createOrderRamp = (
  createRampingOrderRequest: CreateRampingOrderRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CreateOrderResponse>(
    {
      url: `/frontoffice/api/ramp/orders`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createRampingOrderRequest,
    },
    options
  );
};

export const getCreateOrderRampMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderRamp>>,
    TError,
    { data: CreateRampingOrderRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrderRamp>>,
  TError,
  { data: CreateRampingOrderRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrderRamp>>,
    { data: CreateRampingOrderRequest }
  > = props => {
    const { data } = props ?? {};

    return createOrderRamp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOrderRampMutationResult = NonNullable<Awaited<ReturnType<typeof createOrderRamp>>>;
export type CreateOrderRampMutationBody = CreateRampingOrderRequest;
export type CreateOrderRampMutationError = ErrorType<void>;

export const useCreateOrderRamp = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderRamp>>,
    TError,
    { data: CreateRampingOrderRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createOrderRamp>>,
  TError,
  { data: CreateRampingOrderRequest },
  TContext
> => {
  const mutationOptions = getCreateOrderRampMutationOptions(options);

  return useMutation(mutationOptions);
};

export const callbackRamp = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<RampingCallbackResponse>(
    { url: `/frontoffice/api/ramp/callback`, method: 'GET', signal },
    options
  );
};

export const getCallbackRampQueryKey = () => {
  return [`/frontoffice/api/ramp/callback`] as const;
};

export const getCallbackRampQueryOptions = <
  TData = Awaited<ReturnType<typeof callbackRamp>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof callbackRamp>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCallbackRampQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof callbackRamp>>> = ({ signal }) =>
    callbackRamp(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof callbackRamp>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CallbackRampQueryResult = NonNullable<Awaited<ReturnType<typeof callbackRamp>>>;
export type CallbackRampQueryError = ErrorType<void>;

export const useCallbackRamp = <TData = Awaited<ReturnType<typeof callbackRamp>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof callbackRamp>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCallbackRampQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getReferral = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ReferralResponse>({ url: `/frontoffice/api/v2/referral`, method: 'GET', signal }, options);
};

export const getGetReferralQueryKey = () => {
  return [`/frontoffice/api/v2/referral`] as const;
};

export const getGetReferralQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferral>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getReferral>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferralQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferral>>> = ({ signal }) =>
    getReferral(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReferral>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferralQueryResult = NonNullable<Awaited<ReturnType<typeof getReferral>>>;
export type GetReferralQueryError = ErrorType<void>;

export const useGetReferral = <TData = Awaited<ReturnType<typeof getReferral>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getReferral>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReferralQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getReferralStatus = (
  params?: GetReferralStatusParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ReferralStatusResponse>(
    { url: `/frontoffice/api/v2/referral/status`, method: 'GET', params, signal },
    options
  );
};

export const getGetReferralStatusQueryKey = (params?: GetReferralStatusParams) => {
  return [`/frontoffice/api/v2/referral/status`, ...(params ? [params] : [])] as const;
};

export const getGetReferralStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getReferralStatus>>,
  TError = ErrorType<void>
>(
  params?: GetReferralStatusParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferralStatus>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferralStatusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferralStatus>>> = ({ signal }) =>
    getReferralStatus(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReferralStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReferralStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getReferralStatus>>>;
export type GetReferralStatusQueryError = ErrorType<void>;

export const useGetReferralStatus = <TData = Awaited<ReturnType<typeof getReferralStatus>>, TError = ErrorType<void>>(
  params?: GetReferralStatusParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReferralStatus>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReferralStatusQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Used for claiming the rewards available to the user. The amount will be deposited to the user's portfolio.
 */
export const claimReward = (
  claimRewardRequest: ClaimRewardRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/rewards/claim`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: claimRewardRequest,
    },
    options
  );
};

export const getClaimRewardMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimReward>>,
    TError,
    { data: ClaimRewardRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof claimReward>>, TError, { data: ClaimRewardRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof claimReward>>, { data: ClaimRewardRequest }> = props => {
    const { data } = props ?? {};

    return claimReward(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ClaimRewardMutationResult = NonNullable<Awaited<ReturnType<typeof claimReward>>>;
export type ClaimRewardMutationBody = ClaimRewardRequest;
export type ClaimRewardMutationError = ErrorType<void>;

/**
 * @summary Used for claiming the rewards available to the user. The amount will be deposited to the user's portfolio.
 */
export const useClaimReward = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof claimReward>>,
    TError,
    { data: ClaimRewardRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof claimReward>>, TError, { data: ClaimRewardRequest }, TContext> => {
  const mutationOptions = getClaimRewardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Returns a list of available rewards to the user
 */
export const getRewards = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<RewardResponse[]>({ url: `/frontoffice/api/rewards`, method: 'GET', signal }, options);
};

export const getGetRewardsQueryKey = () => {
  return [`/frontoffice/api/rewards`] as const;
};

export const getGetRewardsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRewards>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRewards>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewards>>> = ({ signal }) =>
    getRewards(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRewards>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRewardsQueryResult = NonNullable<Awaited<ReturnType<typeof getRewards>>>;
export type GetRewardsQueryError = ErrorType<void>;

/**
 * @summary Returns a list of available rewards to the user
 */
export const useGetRewards = <TData = Awaited<ReturnType<typeof getRewards>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRewards>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRewardsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createTrade = (orderRequest: OrderRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrderResponse>(
    {
      url: `/frontoffice/api/order`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: orderRequest,
    },
    options
  );
};

export const getCreateTradeMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTrade>>, { data: OrderRequest }> = props => {
    const { data } = props ?? {};

    return createTrade(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTradeMutationResult = NonNullable<Awaited<ReturnType<typeof createTrade>>>;
export type CreateTradeMutationBody = OrderRequest;
export type CreateTradeMutationError = ErrorType<ApiErrorResponse | void>;

export const useCreateTrade = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext> => {
  const mutationOptions = getCreateTradeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const cancelTrade = (orderId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrderResponse>({ url: `/frontoffice/api/orders/${orderId}`, method: 'DELETE' }, options);
};

export const getCancelTradeMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTrade>>, { orderId: string }> = props => {
    const { orderId } = props ?? {};

    return cancelTrade(orderId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelTradeMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTrade>>>;

export type CancelTradeMutationError = ErrorType<void>;

export const useCancelTrade = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext> => {
  const mutationOptions = getCancelTradeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Gets an order related to current logged-in user.
 */
export const getOrderTrade = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderInfoResponse>(
    { url: `/frontoffice/api/orders/${orderId}`, method: 'GET', signal },
    options
  );
};

export const getGetOrderTradeQueryKey = (orderId: string) => {
  return [`/frontoffice/api/orders/${orderId}`] as const;
};

export const getGetOrderTradeQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderTrade>>,
  TError = ErrorType<void>
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrderTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderTradeQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderTrade>>> = ({ signal }) =>
    getOrderTrade(orderId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!orderId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderTradeQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderTrade>>>;
export type GetOrderTradeQueryError = ErrorType<void>;

/**
 * @summary Gets an order related to current logged-in user.
 */
export const useGetOrderTrade = <TData = Awaited<ReturnType<typeof getOrderTrade>>, TError = ErrorType<void>>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrderTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderTradeQueryOptions(orderId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets a paginated list of open orders related to current logged-in user.
 */
export const myTrade = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<OrderInfoResponse[]>({ url: `/frontoffice/api/orders/my`, method: 'GET', signal }, options);
};

export const getMyTradeQueryKey = () => {
  return [`/frontoffice/api/orders/my`] as const;
};

export const getMyTradeQueryOptions = <
  TData = Awaited<ReturnType<typeof myTrade>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof myTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMyTradeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof myTrade>>> = ({ signal }) => myTrade(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof myTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MyTradeQueryResult = NonNullable<Awaited<ReturnType<typeof myTrade>>>;
export type MyTradeQueryError = ErrorType<void>;

/**
 * @summary Gets a paginated list of open orders related to current logged-in user.
 */
export const useMyTrade = <TData = Awaited<ReturnType<typeof myTrade>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof myTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMyTradeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Tries to cancel all user's active orders.
            
On success reports the number of cancelled orders.
 */
export const cancelAllActiveOrdersTrade = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Int32Response>({ url: `/frontoffice/api/orders`, method: 'DELETE' }, options);
};

export const getCancelAllActiveOrdersTradeMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, void> = () => {
    return cancelAllActiveOrdersTrade(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelAllActiveOrdersTradeMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>
>;

export type CancelAllActiveOrdersTradeMutationError = ErrorType<void>;

/**
 * @summary Tries to cancel all user's active orders.
            
On success reports the number of cancelled orders.
 */
export const useCancelAllActiveOrdersTrade = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, void, TContext> => {
  const mutationOptions = getCancelAllActiveOrdersTradeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Returns the user order history. 
The user is detected form the logged in user token
 */
export const orderHistoryTrade = (
  params?: OrderHistoryTradeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderInfoResponseFilteredResponse>(
    { url: `/frontoffice/api/order_history`, method: 'GET', params, signal },
    options
  );
};

export const getOrderHistoryTradeQueryKey = (params?: OrderHistoryTradeParams) => {
  return [`/frontoffice/api/order_history`, ...(params ? [params] : [])] as const;
};

export const getOrderHistoryTradeQueryOptions = <
  TData = Awaited<ReturnType<typeof orderHistoryTrade>>,
  TError = ErrorType<void>
>(
  params?: OrderHistoryTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof orderHistoryTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderHistoryTradeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orderHistoryTrade>>> = ({ signal }) =>
    orderHistoryTrade(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof orderHistoryTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderHistoryTradeQueryResult = NonNullable<Awaited<ReturnType<typeof orderHistoryTrade>>>;
export type OrderHistoryTradeQueryError = ErrorType<void>;

/**
 * @summary Returns the user order history. 
The user is detected form the logged in user token
 */
export const useOrderHistoryTrade = <TData = Awaited<ReturnType<typeof orderHistoryTrade>>, TError = ErrorType<void>>(
  params?: OrderHistoryTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof orderHistoryTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrderHistoryTradeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the user trade history
 */
export const getTradeHistory = (
  params?: GetTradeHistoryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TradeHistoryItemFilteredResponse>(
    { url: `/frontoffice/api/trade_history`, method: 'GET', params, signal },
    options
  );
};

export const getGetTradeHistoryQueryKey = (params?: GetTradeHistoryParams) => {
  return [`/frontoffice/api/trade_history`, ...(params ? [params] : [])] as const;
};

export const getGetTradeHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getTradeHistory>>,
  TError = ErrorType<void>
>(
  params?: GetTradeHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTradeHistory>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTradeHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTradeHistory>>> = ({ signal }) =>
    getTradeHistory(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTradeHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTradeHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getTradeHistory>>>;
export type GetTradeHistoryQueryError = ErrorType<void>;

/**
 * @summary Returns the user trade history
 */
export const useGetTradeHistory = <TData = Awaited<ReturnType<typeof getTradeHistory>>, TError = ErrorType<void>>(
  params?: GetTradeHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTradeHistory>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTradeHistoryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fullAssetsTrade = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AccountAssetResponse[]>({ url: `/frontoffice/api/assets`, method: 'GET', signal }, options);
};

export const getFullAssetsTradeQueryKey = () => {
  return [`/frontoffice/api/assets`] as const;
};

export const getFullAssetsTradeQueryOptions = <
  TData = Awaited<ReturnType<typeof fullAssetsTrade>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fullAssetsTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFullAssetsTradeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fullAssetsTrade>>> = ({ signal }) =>
    fullAssetsTrade(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fullAssetsTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FullAssetsTradeQueryResult = NonNullable<Awaited<ReturnType<typeof fullAssetsTrade>>>;
export type FullAssetsTradeQueryError = ErrorType<void>;

export const useFullAssetsTrade = <
  TData = Awaited<ReturnType<typeof fullAssetsTrade>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fullAssetsTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFullAssetsTradeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Tries to get the latest price for the specified instrument, if couldn't be found gets from coingecko.
 */
export const swapTrade = (
  params: SwapTradeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SwapResponse>({ url: `/frontoffice/api/swap`, method: 'GET', params, signal }, options);
};

export const getSwapTradeQueryKey = (params: SwapTradeParams) => {
  return [`/frontoffice/api/swap`, ...(params ? [params] : [])] as const;
};

export const getSwapTradeQueryOptions = <
  TData = Awaited<ReturnType<typeof swapTrade>>,
  TError = ErrorType<Error | ApiErrorResponse>
>(
  params: SwapTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof swapTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSwapTradeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof swapTrade>>> = ({ signal }) =>
    swapTrade(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof swapTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SwapTradeQueryResult = NonNullable<Awaited<ReturnType<typeof swapTrade>>>;
export type SwapTradeQueryError = ErrorType<Error | ApiErrorResponse>;

/**
 * @summary Tries to get the latest price for the specified instrument, if couldn't be found gets from coingecko.
 */
export const useSwapTrade = <
  TData = Awaited<ReturnType<typeof swapTrade>>,
  TError = ErrorType<Error | ApiErrorResponse>
>(
  params: SwapTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof swapTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSwapTradeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns 50 recent trades that have completed.
 */
export const recentTrades = (
  params?: RecentTradesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<RecentTradesResponse[]>(
    { url: `/frontoffice/api/recent`, method: 'GET', params, signal },
    options
  );
};

export const getRecentTradesQueryKey = (params?: RecentTradesParams) => {
  return [`/frontoffice/api/recent`, ...(params ? [params] : [])] as const;
};

export const getRecentTradesQueryOptions = <
  TData = Awaited<ReturnType<typeof recentTrades>>,
  TError = ErrorType<unknown>
>(
  params?: RecentTradesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentTrades>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecentTradesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recentTrades>>> = ({ signal }) =>
    recentTrades(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recentTrades>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecentTradesQueryResult = NonNullable<Awaited<ReturnType<typeof recentTrades>>>;
export type RecentTradesQueryError = ErrorType<unknown>;

/**
 * @summary Returns 50 recent trades that have completed.
 */
export const useRecentTrades = <TData = Awaited<ReturnType<typeof recentTrades>>, TError = ErrorType<unknown>>(
  params?: RecentTradesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentTrades>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecentTradesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns a quote for the specified amount and market.
 */
export const quoteTrade = (
  params?: QuoteTradeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetQuoteResponse>({ url: `/frontoffice/api/quote`, method: 'GET', params, signal }, options);
};

export const getQuoteTradeQueryKey = (params?: QuoteTradeParams) => {
  return [`/frontoffice/api/quote`, ...(params ? [params] : [])] as const;
};

export const getQuoteTradeQueryOptions = <TData = Awaited<ReturnType<typeof quoteTrade>>, TError = ErrorType<void>>(
  params?: QuoteTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof quoteTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuoteTradeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof quoteTrade>>> = ({ signal }) =>
    quoteTrade(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof quoteTrade>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type QuoteTradeQueryResult = NonNullable<Awaited<ReturnType<typeof quoteTrade>>>;
export type QuoteTradeQueryError = ErrorType<void>;

/**
 * @summary Returns a quote for the specified amount and market.
 */
export const useQuoteTrade = <TData = Awaited<ReturnType<typeof quoteTrade>>, TError = ErrorType<void>>(
  params?: QuoteTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof quoteTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getQuoteTradeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Verifies whether the provided wallet address is associated with PingExchange or not.
- Before using this API, providers must submit their profile to PingExchange, including the Virtual Asset Service Provider's name (VASP) and a secret key. The VASP's name will be referred to as the vaspId. Based on `RFC #2104 section.3` and also `RFC #4848 page.5`, key needs to be chosen at random (or using a cryptographically strong pseudo-random generator seeded with a random seed). This key is required by `HMAC` cryptography mechanism.
- To comply with the `FATF Travel Rule` verify if a wallet address is linked to PingExchange, a call to the following API is necessary.
 * @summary Check Address Validity
 */
export const checkAddressValidityTravel = (
  travelCheckRequest: TravelCheckRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v1/travel/address`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: travelCheckRequest,
    },
    options
  );
};

export const getCheckAddressValidityTravelMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkAddressValidityTravel>>,
    TError,
    { data: TravelCheckRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checkAddressValidityTravel>>,
  TError,
  { data: TravelCheckRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkAddressValidityTravel>>,
    { data: TravelCheckRequest }
  > = props => {
    const { data } = props ?? {};

    return checkAddressValidityTravel(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckAddressValidityTravelMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkAddressValidityTravel>>
>;
export type CheckAddressValidityTravelMutationBody = TravelCheckRequest;
export type CheckAddressValidityTravelMutationError = ErrorType<void>;

/**
 * @summary Check Address Validity
 */
export const useCheckAddressValidityTravel = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkAddressValidityTravel>>,
    TError,
    { data: TravelCheckRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof checkAddressValidityTravel>>,
  TError,
  { data: TravelCheckRequest },
  TContext
> => {
  const mutationOptions = getCheckAddressValidityTravelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Collecting travel information and saving it in PingExchange, if given signature is valid.
- the supplier needs to initially submit their information in the `PingExchange`.
- To transmit and save information in the `PingExchange`, it is essential to invoke this API.
 * @summary Store Travel Address
 */
export const storeAddressTravel = (travelRequest: TravelRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v1/travel/data`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: travelRequest,
    },
    options
  );
};

export const getStoreAddressTravelMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof storeAddressTravel>>,
    TError,
    { data: TravelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof storeAddressTravel>>, TError, { data: TravelRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof storeAddressTravel>>,
    { data: TravelRequest }
  > = props => {
    const { data } = props ?? {};

    return storeAddressTravel(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StoreAddressTravelMutationResult = NonNullable<Awaited<ReturnType<typeof storeAddressTravel>>>;
export type StoreAddressTravelMutationBody = TravelRequest;
export type StoreAddressTravelMutationError = ErrorType<void>;

/**
 * @summary Store Travel Address
 */
export const useStoreAddressTravel = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof storeAddressTravel>>,
    TError,
    { data: TravelRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof storeAddressTravel>>, TError, { data: TravelRequest }, TContext> => {
  const mutationOptions = getStoreAddressTravelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary The corepass app signs the request and calls this callback afterwards.
 */
export const callbackTwoFactorAuth = (
  twoFactorAuthCallbackRequest: TwoFactorAuthCallbackRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v2/2fa`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: twoFactorAuthCallbackRequest,
    },
    options
  );
};

export const getCallbackTwoFactorAuthMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    TError,
    { data: TwoFactorAuthCallbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
  TError,
  { data: TwoFactorAuthCallbackRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    { data: TwoFactorAuthCallbackRequest }
  > = props => {
    const { data } = props ?? {};

    return callbackTwoFactorAuth(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CallbackTwoFactorAuthMutationResult = NonNullable<Awaited<ReturnType<typeof callbackTwoFactorAuth>>>;
export type CallbackTwoFactorAuthMutationBody = TwoFactorAuthCallbackRequest;
export type CallbackTwoFactorAuthMutationError = ErrorType<unknown>;

/**
 * @summary The corepass app signs the request and calls this callback afterwards.
 */
export const useCallbackTwoFactorAuth = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    TError,
    { data: TwoFactorAuthCallbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
  TError,
  { data: TwoFactorAuthCallbackRequest },
  TContext
> => {
  const mutationOptions = getCallbackTwoFactorAuthMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Receives UserId and returns list of user documents. This action works with admin credentials.
 */
export const getByUserIdUserDocuments = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserDocumentDto[]>(
    { url: `/frontoffice/api/v1/user-document/${userId}`, method: 'GET', signal },
    options
  );
};

export const getGetByUserIdUserDocumentsQueryKey = (userId: string) => {
  return [`/frontoffice/api/v1/user-document/${userId}`] as const;
};

export const getGetByUserIdUserDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getByUserIdUserDocuments>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getByUserIdUserDocuments>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetByUserIdUserDocumentsQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getByUserIdUserDocuments>>> = ({ signal }) =>
    getByUserIdUserDocuments(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getByUserIdUserDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetByUserIdUserDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getByUserIdUserDocuments>>>;
export type GetByUserIdUserDocumentsQueryError = ErrorType<void>;

/**
 * @summary Receives UserId and returns list of user documents. This action works with admin credentials.
 */
export const useGetByUserIdUserDocuments = <
  TData = Awaited<ReturnType<typeof getByUserIdUserDocuments>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getByUserIdUserDocuments>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetByUserIdUserDocumentsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets the user info for the specified user.
 */
export const getUserInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserInfo>({ url: `/frontoffice/api/userinfo`, method: 'GET', signal }, options);
};

export const getGetUserInfoQueryKey = () => {
  return [`/frontoffice/api/userinfo`] as const;
};

export const getGetUserInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserInfo>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserInfo>>> = ({ signal }) =>
    getUserInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInfo>>>;
export type GetUserInfoQueryError = ErrorType<void>;

/**
 * @summary Gets the user info for the specified user.
 */
export const useGetUserInfo = <TData = Awaited<ReturnType<typeof getUserInfo>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Sets the user info for the specified user.
 */
export const setUserInfo = (userInfo: UserInfo, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/userinfo`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userInfo,
    },
    options
  );
};

export const getSetUserInfoMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof setUserInfo>>, TError, { data: UserInfo }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof setUserInfo>>, TError, { data: UserInfo }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof setUserInfo>>, { data: UserInfo }> = props => {
    const { data } = props ?? {};

    return setUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetUserInfoMutationResult = NonNullable<Awaited<ReturnType<typeof setUserInfo>>>;
export type SetUserInfoMutationBody = UserInfo;
export type SetUserInfoMutationError = ErrorType<void>;

/**
 * @summary Sets the user info for the specified user.
 */
export const useSetUserInfo = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof setUserInfo>>, TError, { data: UserInfo }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof setUserInfo>>, TError, { data: UserInfo }, TContext> => {
  const mutationOptions = getSetUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getFormAUserInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<FormAResponse>(
    { url: `/frontoffice/api/userinfo/request/forma`, method: 'GET', signal },
    options
  );
};

export const getGetFormAUserInfoQueryKey = () => {
  return [`/frontoffice/api/userinfo/request/forma`] as const;
};

export const getGetFormAUserInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getFormAUserInfo>>,
  TError = ErrorType<ApiErrorResponse | void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFormAUserInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFormAUserInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFormAUserInfo>>> = ({ signal }) =>
    getFormAUserInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFormAUserInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFormAUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getFormAUserInfo>>>;
export type GetFormAUserInfoQueryError = ErrorType<ApiErrorResponse | void>;

export const useGetFormAUserInfo = <
  TData = Awaited<ReturnType<typeof getFormAUserInfo>>,
  TError = ErrorType<ApiErrorResponse | void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFormAUserInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFormAUserInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary This method is called by CorePass connector when data is requested data is signed.
 */
export const formACallbackUserInfo = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/userinfo/formacallback`, method: 'POST' }, options);
};

export const getFormACallbackUserInfoMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof formACallbackUserInfo>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof formACallbackUserInfo>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof formACallbackUserInfo>>, void> = () => {
    return formACallbackUserInfo(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type FormACallbackUserInfoMutationResult = NonNullable<Awaited<ReturnType<typeof formACallbackUserInfo>>>;

export type FormACallbackUserInfoMutationError = ErrorType<unknown>;

/**
 * @summary This method is called by CorePass connector when data is requested data is signed.
 */
export const useFormACallbackUserInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof formACallbackUserInfo>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof formACallbackUserInfo>>, TError, void, TContext> => {
  const mutationOptions = getFormACallbackUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userSettingsGet = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<GetUserSettingResponseResponse>(
    { url: `/frontoffice/api/settings`, method: 'GET', signal },
    options
  );
};

export const getUserSettingsGetQueryKey = () => {
  return [`/frontoffice/api/settings`] as const;
};

export const getUserSettingsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof userSettingsGet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userSettingsGet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserSettingsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userSettingsGet>>> = ({ signal }) =>
    userSettingsGet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userSettingsGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserSettingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof userSettingsGet>>>;
export type UserSettingsGetQueryError = ErrorType<void>;

export const useUserSettingsGet = <
  TData = Awaited<ReturnType<typeof userSettingsGet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userSettingsGet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserSettingsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userSettingsUpdate = (
  profileSettingsUpdateRequest: ProfileSettingsUpdateRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/setting`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: profileSettingsUpdateRequest,
    },
    options
  );
};

export const getUserSettingsUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userSettingsUpdate>>,
  TError,
  { data: ProfileSettingsUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    { data: ProfileSettingsUpdateRequest }
  > = props => {
    const { data } = props ?? {};

    return userSettingsUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userSettingsUpdate>>>;
export type UserSettingsUpdateMutationBody = ProfileSettingsUpdateRequest;
export type UserSettingsUpdateMutationError = ErrorType<void>;

export const useUserSettingsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userSettingsUpdate>>,
  TError,
  { data: ProfileSettingsUpdateRequest },
  TContext
> => {
  const mutationOptions = getUserSettingsUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userSettingsDelete = (
  profileSettingsUpdateRequest: ProfileSettingsUpdateRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/setting`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: profileSettingsUpdateRequest,
    },
    options
  );
};

export const getUserSettingsDeleteMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userSettingsDelete>>,
  TError,
  { data: ProfileSettingsUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    { data: ProfileSettingsUpdateRequest }
  > = props => {
    const { data } = props ?? {};

    return userSettingsDelete(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserSettingsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof userSettingsDelete>>>;
export type UserSettingsDeleteMutationBody = ProfileSettingsUpdateRequest;
export type UserSettingsDeleteMutationError = ErrorType<void>;

export const useUserSettingsDelete = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userSettingsDelete>>,
  TError,
  { data: ProfileSettingsUpdateRequest },
  TContext
> => {
  const mutationOptions = getUserSettingsDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const toggleSubscriptionUserTier = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/usertiers`, method: 'POST' }, options);
};

export const getToggleSubscriptionUserTierMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, void> = () => {
    return toggleSubscriptionUserTier(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ToggleSubscriptionUserTierMutationResult = NonNullable<
  Awaited<ReturnType<typeof toggleSubscriptionUserTier>>
>;

export type ToggleSubscriptionUserTierMutationError = ErrorType<void>;

export const useToggleSubscriptionUserTier = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, void, TContext> => {
  const mutationOptions = getToggleSubscriptionUserTierMutationOptions(options);

  return useMutation(mutationOptions);
};

export const depositWallet = (
  depositFundsRequest: NonReadonly<DepositFundsRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DepositFundsResponse>(
    {
      url: `/frontoffice/api/wallet/deposit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: depositFundsRequest,
    },
    options
  );
};

export const getDepositWalletMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof depositWallet>>,
    TError,
    { data: NonReadonly<DepositFundsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof depositWallet>>,
  TError,
  { data: NonReadonly<DepositFundsRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof depositWallet>>,
    { data: NonReadonly<DepositFundsRequest> }
  > = props => {
    const { data } = props ?? {};

    return depositWallet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepositWalletMutationResult = NonNullable<Awaited<ReturnType<typeof depositWallet>>>;
export type DepositWalletMutationBody = NonReadonly<DepositFundsRequest>;
export type DepositWalletMutationError = ErrorType<void>;

export const useDepositWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof depositWallet>>,
    TError,
    { data: NonReadonly<DepositFundsRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof depositWallet>>,
  TError,
  { data: NonReadonly<DepositFundsRequest> },
  TContext
> => {
  const mutationOptions = getDepositWalletMutationOptions(options);

  return useMutation(mutationOptions);
};

export const withdrawalWallet = (
  withdrawalRequest: NonReadonly<WithdrawalRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/frontoffice/api/wallet/withdrawal`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: withdrawalRequest,
    },
    options
  );
};

export const getWithdrawalWalletMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawalWallet>>,
    TError,
    { data: NonReadonly<WithdrawalRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof withdrawalWallet>>,
  TError,
  { data: NonReadonly<WithdrawalRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawalWallet>>,
    { data: NonReadonly<WithdrawalRequest> }
  > = props => {
    const { data } = props ?? {};

    return withdrawalWallet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawalWalletMutationResult = NonNullable<Awaited<ReturnType<typeof withdrawalWallet>>>;
export type WithdrawalWalletMutationBody = NonReadonly<WithdrawalRequest>;
export type WithdrawalWalletMutationError = ErrorType<void>;

export const useWithdrawalWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawalWallet>>,
    TError,
    { data: NonReadonly<WithdrawalRequest> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof withdrawalWallet>>,
  TError,
  { data: NonReadonly<WithdrawalRequest> },
  TContext
> => {
  const mutationOptions = getWithdrawalWalletMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTransferHistoryWallet = (
  params?: GetTransferHistoryWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransferHistoryResponseFilteredResponse>(
    { url: `/frontoffice/api/wallet/transfer_history`, method: 'GET', params, signal },
    options
  );
};

export const getGetTransferHistoryWalletQueryKey = (params?: GetTransferHistoryWalletParams) => {
  return [`/frontoffice/api/wallet/transfer_history`, ...(params ? [params] : [])] as const;
};

export const getGetTransferHistoryWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransferHistoryWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferHistoryWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferHistoryWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransferHistoryWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransferHistoryWallet>>> = ({ signal }) =>
    getTransferHistoryWallet(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransferHistoryWallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransferHistoryWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getTransferHistoryWallet>>>;
export type GetTransferHistoryWalletQueryError = ErrorType<void>;

export const useGetTransferHistoryWallet = <
  TData = Awaited<ReturnType<typeof getTransferHistoryWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferHistoryWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferHistoryWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransferHistoryWalletQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNetworkFeeWallet = (
  params: GetNetworkFeeWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NetworkFeeResponse>(
    { url: `/frontoffice/api/wallet/network-fee`, method: 'GET', params, signal },
    options
  );
};

export const getGetNetworkFeeWalletQueryKey = (params: GetNetworkFeeWalletParams) => {
  return [`/frontoffice/api/wallet/network-fee`, ...(params ? [params] : [])] as const;
};

export const getGetNetworkFeeWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getNetworkFeeWallet>>,
  TError = ErrorType<unknown>
>(
  params: GetNetworkFeeWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworkFeeWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNetworkFeeWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNetworkFeeWallet>>> = ({ signal }) =>
    getNetworkFeeWallet(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNetworkFeeWallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNetworkFeeWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getNetworkFeeWallet>>>;
export type GetNetworkFeeWalletQueryError = ErrorType<unknown>;

export const useGetNetworkFeeWallet = <
  TData = Awaited<ReturnType<typeof getNetworkFeeWallet>>,
  TError = ErrorType<unknown>
>(
  params: GetNetworkFeeWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworkFeeWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNetworkFeeWalletQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNetworksWallet = (
  params?: GetNetworksWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NetworkItemInfo[]>(
    { url: `/frontoffice/api/wallet/networks`, method: 'GET', params, signal },
    options
  );
};

export const getGetNetworksWalletQueryKey = (params?: GetNetworksWalletParams) => {
  return [`/frontoffice/api/wallet/networks`, ...(params ? [params] : [])] as const;
};

export const getGetNetworksWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getNetworksWallet>>,
  TError = ErrorType<void>
>(
  params?: GetNetworksWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworksWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNetworksWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNetworksWallet>>> = ({ signal }) =>
    getNetworksWallet(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNetworksWallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNetworksWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getNetworksWallet>>>;
export type GetNetworksWalletQueryError = ErrorType<void>;

export const useGetNetworksWallet = <TData = Awaited<ReturnType<typeof getNetworksWallet>>, TError = ErrorType<void>>(
  params?: GetNetworksWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworksWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNetworksWalletQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns withdrawal limits for current user
 */
export const getWithdrawalLimit = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<WithdrawalLimitResponse>(
    { url: `/frontoffice/api/wallet/withdrawal/limits`, method: 'GET', signal },
    options
  );
};

export const getGetWithdrawalLimitQueryKey = () => {
  return [`/frontoffice/api/wallet/withdrawal/limits`] as const;
};

export const getGetWithdrawalLimitQueryOptions = <
  TData = Awaited<ReturnType<typeof getWithdrawalLimit>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalLimit>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalLimitQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWithdrawalLimit>>> = ({ signal }) =>
    getWithdrawalLimit(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getWithdrawalLimit>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetWithdrawalLimitQueryResult = NonNullable<Awaited<ReturnType<typeof getWithdrawalLimit>>>;
export type GetWithdrawalLimitQueryError = ErrorType<void>;

/**
 * @summary Returns withdrawal limits for current user
 */
export const useGetWithdrawalLimit = <
  TData = Awaited<ReturnType<typeof getWithdrawalLimit>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalLimit>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetWithdrawalLimitQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Roles: Admin
 * @summary Get a list of tags that can be assigned to user accounts
 */
export const getTagsInfoAccountTagsInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AccountTagInfo[]>(
    { url: `/back-api/api/v1/account-tags/info`, method: 'GET', signal },
    options
  );
};

export const getGetTagsInfoAccountTagsInfoQueryKey = () => {
  return [`/back-api/api/v1/account-tags/info`] as const;
};

export const getGetTagsInfoAccountTagsInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagsInfoAccountTagsInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>> = ({ signal }) =>
    getTagsInfoAccountTagsInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagsInfoAccountTagsInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>>;
export type GetTagsInfoAccountTagsInfoQueryError = ErrorType<void>;

/**
 * @summary Get a list of tags that can be assigned to user accounts
 */
export const useGetTagsInfoAccountTagsInfo = <
  TData = Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTagsInfoAccountTagsInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Total asset deposit and wallet value by username or email
 */
export const getUserDepositInfoAdminAccounting = (
  params?: GetUserDepositInfoAdminAccountingParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<unknown>(
    { url: `/back-api/backoffice/admin-accounting/deposits`, method: 'GET', params, signal },
    options
  );
};

export const getGetUserDepositInfoAdminAccountingQueryKey = (params?: GetUserDepositInfoAdminAccountingParams) => {
  return [`/back-api/backoffice/admin-accounting/deposits`, ...(params ? [params] : [])] as const;
};

export const getGetUserDepositInfoAdminAccountingQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>,
  TError = ErrorType<void | ApiErrorResponse>
>(
  params?: GetUserDepositInfoAdminAccountingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDepositInfoAdminAccountingQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>> = ({ signal }) =>
    getUserDepositInfoAdminAccounting(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserDepositInfoAdminAccountingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>
>;
export type GetUserDepositInfoAdminAccountingQueryError = ErrorType<void | ApiErrorResponse>;

/**
 * @summary Get Total asset deposit and wallet value by username or email
 */
export const useGetUserDepositInfoAdminAccounting = <
  TData = Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>,
  TError = ErrorType<void | ApiErrorResponse>
>(
  params?: GetUserDepositInfoAdminAccountingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserDepositInfoAdminAccounting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserDepositInfoAdminAccountingQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary This endpoint is used to retrieve the balance and expected balance of user's asset account.
 */
export const getUserBalanceCheckAdminAccounting = (
  params?: GetUserBalanceCheckAdminAccountingParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserBalanceCheckResponse>(
    { url: `/back-api/backoffice/admin-accounting/balancecheck`, method: 'GET', params, signal },
    options
  );
};

export const getGetUserBalanceCheckAdminAccountingQueryKey = (params?: GetUserBalanceCheckAdminAccountingParams) => {
  return [`/back-api/backoffice/admin-accounting/balancecheck`, ...(params ? [params] : [])] as const;
};

export const getGetUserBalanceCheckAdminAccountingQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>,
  TError = ErrorType<void>
>(
  params?: GetUserBalanceCheckAdminAccountingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserBalanceCheckAdminAccountingQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>> = ({ signal }) =>
    getUserBalanceCheckAdminAccounting(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserBalanceCheckAdminAccountingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>
>;
export type GetUserBalanceCheckAdminAccountingQueryError = ErrorType<void>;

/**
 * @summary This endpoint is used to retrieve the balance and expected balance of user's asset account.
 */
export const useGetUserBalanceCheckAdminAccounting = <
  TData = Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>,
  TError = ErrorType<void>
>(
  params?: GetUserBalanceCheckAdminAccountingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBalanceCheckAdminAccounting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserBalanceCheckAdminAccountingQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all executions and all successful transfers; calculates the total amount of each asset and compares it to the total amount of the asset in the portfolio
 */
export const getSystemBalanceAdminAccounting = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SystemBalanceReportItem[]>(
    { url: `/back-api/backoffice/admin-accounting/system-balance-check`, method: 'GET', signal },
    options
  );
};

export const getGetSystemBalanceAdminAccountingQueryKey = () => {
  return [`/back-api/backoffice/admin-accounting/system-balance-check`] as const;
};

export const getGetSystemBalanceAdminAccountingQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSystemBalanceAdminAccountingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>> = ({ signal }) =>
    getSystemBalanceAdminAccounting(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSystemBalanceAdminAccountingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>
>;
export type GetSystemBalanceAdminAccountingQueryError = ErrorType<void>;

/**
 * @summary Gets all executions and all successful transfers; calculates the total amount of each asset and compares it to the total amount of the asset in the portfolio
 */
export const useGetSystemBalanceAdminAccounting = <
  TData = Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSystemBalanceAdminAccounting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemBalanceAdminAccountingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateUserTierAdminUserTier = (
  adminUserTierRequest: AdminUserTierRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/usertiers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: adminUserTierRequest,
    },
    options
  );
};

export const getUpdateUserTierAdminUserTierMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    TError,
    { data: AdminUserTierRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
  TError,
  { data: AdminUserTierRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    { data: AdminUserTierRequest }
  > = props => {
    const { data } = props ?? {};

    return updateUserTierAdminUserTier(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserTierAdminUserTierMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserTierAdminUserTier>>
>;
export type UpdateUserTierAdminUserTierMutationBody = AdminUserTierRequest;
export type UpdateUserTierAdminUserTierMutationError = ErrorType<void>;

export const useUpdateUserTierAdminUserTier = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    TError,
    { data: AdminUserTierRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
  TError,
  { data: AdminUserTierRequest },
  TContext
> => {
  const mutationOptions = getUpdateUserTierAdminUserTierMutationOptions(options);

  return useMutation(mutationOptions);
};

export const apiKeysCreateApiKeys = (
  createUserApiKeyRequest: CreateUserApiKeyRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CreateApiKeyResponse>(
    {
      url: `/back-api/backoffice/api-keys/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createUserApiKeyRequest,
    },
    options
  );
};

export const getApiKeysCreateApiKeysMutationOptions = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysCreateApiKeys>>,
    TError,
    { data: CreateUserApiKeyRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof apiKeysCreateApiKeys>>,
  TError,
  { data: CreateUserApiKeyRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof apiKeysCreateApiKeys>>,
    { data: CreateUserApiKeyRequest }
  > = props => {
    const { data } = props ?? {};

    return apiKeysCreateApiKeys(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApiKeysCreateApiKeysMutationResult = NonNullable<Awaited<ReturnType<typeof apiKeysCreateApiKeys>>>;
export type ApiKeysCreateApiKeysMutationBody = CreateUserApiKeyRequest;
export type ApiKeysCreateApiKeysMutationError = ErrorType<void | ApiErrorResponse>;

export const useApiKeysCreateApiKeys = <TError = ErrorType<void | ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof apiKeysCreateApiKeys>>,
    TError,
    { data: CreateUserApiKeyRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof apiKeysCreateApiKeys>>,
  TError,
  { data: CreateUserApiKeyRequest },
  TContext
> => {
  const mutationOptions = getApiKeysCreateApiKeysMutationOptions(options);

  return useMutation(mutationOptions);
};

export const apiKeysGetUserApiKeys = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<void>({ url: `/back-api/backoffice/api-keys/list`, method: 'GET', signal }, options);
};

export const getApiKeysGetUserApiKeysQueryKey = () => {
  return [`/back-api/backoffice/api-keys/list`] as const;
};

export const getApiKeysGetUserApiKeysQueryOptions = <
  TData = Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApiKeysGetUserApiKeysQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>> = ({ signal }) =>
    apiKeysGetUserApiKeys(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ApiKeysGetUserApiKeysQueryResult = NonNullable<Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>>;
export type ApiKeysGetUserApiKeysQueryError = ErrorType<void>;

export const useApiKeysGetUserApiKeys = <
  TData = Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof apiKeysGetUserApiKeys>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiKeysGetUserApiKeysQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Let the admin set properties of an asset
 */
export const updateAsset = (
  assetId: string,
  assetRequest: AssetRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/asset/${assetId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: assetRequest,
    },
    options
  );
};

export const getUpdateAssetMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: string; data: AssetRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAsset>>,
  TError,
  { assetId: string; data: AssetRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAsset>>,
    { assetId: string; data: AssetRequest }
  > = props => {
    const { assetId, data } = props ?? {};

    return updateAsset(assetId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAssetMutationResult = NonNullable<Awaited<ReturnType<typeof updateAsset>>>;
export type UpdateAssetMutationBody = AssetRequest;
export type UpdateAssetMutationError = ErrorType<void>;

/**
 * @summary Let the admin set properties of an asset
 */
export const useUpdateAsset = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: string; data: AssetRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAsset>>,
  TError,
  { assetId: string; data: AssetRequest },
  TContext
> => {
  const mutationOptions = getUpdateAssetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get balances for specific payment system.
 */
export const balancesBalanceAggregator = (
  paymentSystem: string,
  params?: BalancesBalanceAggregatorParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    { url: `/back-api/api/v2/wallets/aggregation/balances/${paymentSystem}`, method: 'GET', params, signal },
    options
  );
};

export const getBalancesBalanceAggregatorQueryKey = (
  paymentSystem: string,
  params?: BalancesBalanceAggregatorParams
) => {
  return [`/back-api/api/v2/wallets/aggregation/balances/${paymentSystem}`, ...(params ? [params] : [])] as const;
};

export const getBalancesBalanceAggregatorQueryOptions = <
  TData = Awaited<ReturnType<typeof balancesBalanceAggregator>>,
  TError = ErrorType<void>
>(
  paymentSystem: string,
  params?: BalancesBalanceAggregatorParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof balancesBalanceAggregator>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBalancesBalanceAggregatorQueryKey(paymentSystem, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof balancesBalanceAggregator>>> = ({ signal }) =>
    balancesBalanceAggregator(paymentSystem, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!paymentSystem, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof balancesBalanceAggregator>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BalancesBalanceAggregatorQueryResult = NonNullable<Awaited<ReturnType<typeof balancesBalanceAggregator>>>;
export type BalancesBalanceAggregatorQueryError = ErrorType<void>;

/**
 * @summary Get balances for specific payment system.
 */
export const useBalancesBalanceAggregator = <
  TData = Awaited<ReturnType<typeof balancesBalanceAggregator>>,
  TError = ErrorType<void>
>(
  paymentSystem: string,
  params?: BalancesBalanceAggregatorParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof balancesBalanceAggregator>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBalancesBalanceAggregatorQueryOptions(paymentSystem, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Aggregate balances to transfers.
 */
export const aggregateBalanceAggregator = (
  balanceAggregateRequest: BalanceAggregateRequest[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/wallets/aggregation/aggregate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: balanceAggregateRequest,
    },
    options
  );
};

export const getAggregateBalanceAggregatorMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof aggregateBalanceAggregator>>,
    TError,
    { data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof aggregateBalanceAggregator>>,
  TError,
  { data: BalanceAggregateRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof aggregateBalanceAggregator>>,
    { data: BalanceAggregateRequest[] }
  > = props => {
    const { data } = props ?? {};

    return aggregateBalanceAggregator(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AggregateBalanceAggregatorMutationResult = NonNullable<
  Awaited<ReturnType<typeof aggregateBalanceAggregator>>
>;
export type AggregateBalanceAggregatorMutationBody = BalanceAggregateRequest[];
export type AggregateBalanceAggregatorMutationError = ErrorType<void>;

/**
 * @summary Aggregate balances to transfers.
 */
export const useAggregateBalanceAggregator = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof aggregateBalanceAggregator>>,
    TError,
    { data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof aggregateBalanceAggregator>>,
  TError,
  { data: BalanceAggregateRequest[] },
  TContext
> => {
  const mutationOptions = getAggregateBalanceAggregatorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * aggregate-sponser/{token} api was merged into this api
 * @summary Aggregate token balances to transfers
 */
export const aggregateTokenBalanceAggregator = (
  token: string,
  balanceAggregateRequest: BalanceAggregateRequest[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/wallets/aggregation/aggregate-token/${token}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: balanceAggregateRequest,
    },
    options
  );
};

export const getAggregateTokenBalanceAggregatorMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>,
    TError,
    { token: string; data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>,
  TError,
  { token: string; data: BalanceAggregateRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>,
    { token: string; data: BalanceAggregateRequest[] }
  > = props => {
    const { token, data } = props ?? {};

    return aggregateTokenBalanceAggregator(token, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AggregateTokenBalanceAggregatorMutationResult = NonNullable<
  Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>
>;
export type AggregateTokenBalanceAggregatorMutationBody = BalanceAggregateRequest[];
export type AggregateTokenBalanceAggregatorMutationError = ErrorType<void>;

/**
 * @summary Aggregate token balances to transfers
 */
export const useAggregateTokenBalanceAggregator = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>,
    TError,
    { token: string; data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof aggregateTokenBalanceAggregator>>,
  TError,
  { token: string; data: BalanceAggregateRequest[] },
  TContext
> => {
  const mutationOptions = getAggregateTokenBalanceAggregatorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get sponser with his balances wich will pay for token transfers for specific payment system.
 */
export const sponserBalanceAggregator = (
  paymentSystem: string,
  params?: SponserBalanceAggregatorParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    { url: `/back-api/api/v2/wallets/aggregation/sponser/${paymentSystem}`, method: 'GET', params, signal },
    options
  );
};

export const getSponserBalanceAggregatorQueryKey = (paymentSystem: string, params?: SponserBalanceAggregatorParams) => {
  return [`/back-api/api/v2/wallets/aggregation/sponser/${paymentSystem}`, ...(params ? [params] : [])] as const;
};

export const getSponserBalanceAggregatorQueryOptions = <
  TData = Awaited<ReturnType<typeof sponserBalanceAggregator>>,
  TError = ErrorType<void>
>(
  paymentSystem: string,
  params?: SponserBalanceAggregatorParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sponserBalanceAggregator>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSponserBalanceAggregatorQueryKey(paymentSystem, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sponserBalanceAggregator>>> = ({ signal }) =>
    sponserBalanceAggregator(paymentSystem, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!paymentSystem, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sponserBalanceAggregator>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SponserBalanceAggregatorQueryResult = NonNullable<Awaited<ReturnType<typeof sponserBalanceAggregator>>>;
export type SponserBalanceAggregatorQueryError = ErrorType<void>;

/**
 * @summary Get sponser with his balances wich will pay for token transfers for specific payment system.
 */
export const useSponserBalanceAggregator = <
  TData = Awaited<ReturnType<typeof sponserBalanceAggregator>>,
  TError = ErrorType<void>
>(
  paymentSystem: string,
  params?: SponserBalanceAggregatorParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sponserBalanceAggregator>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSponserBalanceAggregatorQueryOptions(paymentSystem, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Approve sponser for specific token and the balances wich will pay for token transfers.
 */
export const approveBalanceAggregator = (
  token: string,
  balanceAggregateRequest: BalanceAggregateRequest[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/wallets/aggregation/approve/${token}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: balanceAggregateRequest,
    },
    options
  );
};

export const getApproveBalanceAggregatorMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveBalanceAggregator>>,
    TError,
    { token: string; data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof approveBalanceAggregator>>,
  TError,
  { token: string; data: BalanceAggregateRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approveBalanceAggregator>>,
    { token: string; data: BalanceAggregateRequest[] }
  > = props => {
    const { token, data } = props ?? {};

    return approveBalanceAggregator(token, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApproveBalanceAggregatorMutationResult = NonNullable<Awaited<ReturnType<typeof approveBalanceAggregator>>>;
export type ApproveBalanceAggregatorMutationBody = BalanceAggregateRequest[];
export type ApproveBalanceAggregatorMutationError = ErrorType<void>;

/**
 * @summary Approve sponser for specific token and the balances wich will pay for token transfers.
 */
export const useApproveBalanceAggregator = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveBalanceAggregator>>,
    TError,
    { token: string; data: BalanceAggregateRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof approveBalanceAggregator>>,
  TError,
  { token: string; data: BalanceAggregateRequest[] },
  TContext
> => {
  const mutationOptions = getApproveBalanceAggregatorMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Broadcast the signed transfers to the blockchain network
 */
export const broadcastBalanceAggregator = (
  aggregationBroadcastRequest: AggregationBroadcastRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/wallets/aggregation/broadcast`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: aggregationBroadcastRequest,
    },
    options
  );
};

export const getBroadcastBalanceAggregatorMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof broadcastBalanceAggregator>>,
    TError,
    { data: AggregationBroadcastRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof broadcastBalanceAggregator>>,
  TError,
  { data: AggregationBroadcastRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof broadcastBalanceAggregator>>,
    { data: AggregationBroadcastRequest }
  > = props => {
    const { data } = props ?? {};

    return broadcastBalanceAggregator(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BroadcastBalanceAggregatorMutationResult = NonNullable<
  Awaited<ReturnType<typeof broadcastBalanceAggregator>>
>;
export type BroadcastBalanceAggregatorMutationBody = AggregationBroadcastRequest;
export type BroadcastBalanceAggregatorMutationError = ErrorType<void>;

/**
 * @summary Broadcast the signed transfers to the blockchain network
 */
export const useBroadcastBalanceAggregator = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof broadcastBalanceAggregator>>,
    TError,
    { data: AggregationBroadcastRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof broadcastBalanceAggregator>>,
  TError,
  { data: AggregationBroadcastRequest },
  TContext
> => {
  const mutationOptions = getBroadcastBalanceAggregatorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const resolveCompliance = (
  userReviewResolveRequest: UserReviewResolveRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/compliance/resolve`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userReviewResolveRequest,
    },
    options
  );
};

export const getResolveComplianceMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveCompliance>>,
    TError,
    { data: UserReviewResolveRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resolveCompliance>>,
  TError,
  { data: UserReviewResolveRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveCompliance>>,
    { data: UserReviewResolveRequest }
  > = props => {
    const { data } = props ?? {};

    return resolveCompliance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveComplianceMutationResult = NonNullable<Awaited<ReturnType<typeof resolveCompliance>>>;
export type ResolveComplianceMutationBody = UserReviewResolveRequest;
export type ResolveComplianceMutationError = ErrorType<ApiErrorResponse | void>;

export const useResolveCompliance = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveCompliance>>,
    TError,
    { data: UserReviewResolveRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resolveCompliance>>,
  TError,
  { data: UserReviewResolveRequest },
  TContext
> => {
  const mutationOptions = getResolveComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const changeUserStatusCompliance = (
  complianceChangeUserStatusRequest: ComplianceChangeUserStatusRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string[]>(
    {
      url: `/back-api/backoffice/compliance/changeUserStatus`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceChangeUserStatusRequest,
    },
    options
  );
};

export const getChangeUserStatusComplianceMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUserStatusCompliance>>,
    TError,
    { data: ComplianceChangeUserStatusRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeUserStatusCompliance>>,
  TError,
  { data: ComplianceChangeUserStatusRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeUserStatusCompliance>>,
    { data: ComplianceChangeUserStatusRequest }
  > = props => {
    const { data } = props ?? {};

    return changeUserStatusCompliance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeUserStatusComplianceMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeUserStatusCompliance>>
>;
export type ChangeUserStatusComplianceMutationBody = ComplianceChangeUserStatusRequest;
export type ChangeUserStatusComplianceMutationError = ErrorType<ApiErrorResponse | void>;

export const useChangeUserStatusCompliance = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUserStatusCompliance>>,
    TError,
    { data: ComplianceChangeUserStatusRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeUserStatusCompliance>>,
  TError,
  { data: ComplianceChangeUserStatusRequest },
  TContext
> => {
  const mutationOptions = getChangeUserStatusComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const complianceUsers = (
  params?: ComplianceUsersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ComplianceUsersResponse>(
    { url: `/back-api/backoffice/compliance/users`, method: 'GET', params, signal },
    options
  );
};

export const getComplianceUsersQueryKey = (params?: ComplianceUsersParams) => {
  return [`/back-api/backoffice/compliance/users`, ...(params ? [params] : [])] as const;
};

export const getComplianceUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof complianceUsers>>,
  TError = ErrorType<void>
>(
  params?: ComplianceUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof complianceUsers>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getComplianceUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof complianceUsers>>> = ({ signal }) =>
    complianceUsers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof complianceUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ComplianceUsersQueryResult = NonNullable<Awaited<ReturnType<typeof complianceUsers>>>;
export type ComplianceUsersQueryError = ErrorType<void>;

export const useComplianceUsers = <TData = Awaited<ReturnType<typeof complianceUsers>>, TError = ErrorType<void>>(
  params?: ComplianceUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof complianceUsers>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getComplianceUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets compliance user details
 */
export const complianceUserDetails = (
  complianceUserDetailsRequest: ComplianceUserDetailsRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ComplianceUserDetailsResponse>(
    {
      url: `/back-api/backoffice/compliance/userDetails`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceUserDetailsRequest,
    },
    options
  );
};

export const getComplianceUserDetailsMutationOptions = <
  TError = ErrorType<void | ProblemDetails | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof complianceUserDetails>>,
    TError,
    { data: ComplianceUserDetailsRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof complianceUserDetails>>,
  TError,
  { data: ComplianceUserDetailsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof complianceUserDetails>>,
    { data: ComplianceUserDetailsRequest }
  > = props => {
    const { data } = props ?? {};

    return complianceUserDetails(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ComplianceUserDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof complianceUserDetails>>>;
export type ComplianceUserDetailsMutationBody = ComplianceUserDetailsRequest;
export type ComplianceUserDetailsMutationError = ErrorType<void | ProblemDetails | ApiErrorResponse>;

/**
 * @summary Gets compliance user details
 */
export const useComplianceUserDetails = <
  TError = ErrorType<void | ProblemDetails | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof complianceUserDetails>>,
    TError,
    { data: ComplianceUserDetailsRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof complianceUserDetails>>,
  TError,
  { data: ComplianceUserDetailsRequest },
  TContext
> => {
  const mutationOptions = getComplianceUserDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sets the compliance tier of the user to one lower level (If it's Tier1 we return an error that this is the minimum level). Only accessible from the admin and compliance officer
 */
export const downgradeUserTierCompliance = (
  complianceTierDowngradeRequest: ComplianceTierDowngradeRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/compliance/downgrade`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceTierDowngradeRequest,
    },
    options
  );
};

export const getDowngradeUserTierComplianceMutationOptions = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downgradeUserTierCompliance>>,
    TError,
    { data: ComplianceTierDowngradeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downgradeUserTierCompliance>>,
  TError,
  { data: ComplianceTierDowngradeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downgradeUserTierCompliance>>,
    { data: ComplianceTierDowngradeRequest }
  > = props => {
    const { data } = props ?? {};

    return downgradeUserTierCompliance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DowngradeUserTierComplianceMutationResult = NonNullable<
  Awaited<ReturnType<typeof downgradeUserTierCompliance>>
>;
export type DowngradeUserTierComplianceMutationBody = ComplianceTierDowngradeRequest;
export type DowngradeUserTierComplianceMutationError = ErrorType<void | ApiErrorResponse>;

/**
 * @summary Sets the compliance tier of the user to one lower level (If it's Tier1 we return an error that this is the minimum level). Only accessible from the admin and compliance officer
 */
export const useDowngradeUserTierCompliance = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downgradeUserTierCompliance>>,
    TError,
    { data: ComplianceTierDowngradeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof downgradeUserTierCompliance>>,
  TError,
  { data: ComplianceTierDowngradeRequest },
  TContext
> => {
  const mutationOptions = getDowngradeUserTierComplianceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get information about managed hot wallets.
 */
export const getInfoHotWallet = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<HotWalletResponse[]>({ url: `/back-api/api/v2/wallets/hot`, method: 'GET', signal }, options);
};

export const getGetInfoHotWalletQueryKey = () => {
  return [`/back-api/api/v2/wallets/hot`] as const;
};

export const getGetInfoHotWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getInfoHotWallet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfoHotWallet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInfoHotWalletQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfoHotWallet>>> = ({ signal }) =>
    getInfoHotWallet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInfoHotWallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInfoHotWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getInfoHotWallet>>>;
export type GetInfoHotWalletQueryError = ErrorType<void>;

/**
 * @summary Get information about managed hot wallets.
 */
export const useGetInfoHotWallet = <
  TData = Awaited<ReturnType<typeof getInfoHotWallet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfoHotWallet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInfoHotWalletQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const transferToColdWalletHotWallet = (
  coldWalletTransferRequest: ColdWalletTransferRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TransactionEventData>(
    {
      url: `/back-api/api/v2/wallets/hot/withdrawal`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: coldWalletTransferRequest,
    },
    options
  );
};

export const getTransferToColdWalletHotWalletMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    TError,
    { data: ColdWalletTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
  TError,
  { data: ColdWalletTransferRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    { data: ColdWalletTransferRequest }
  > = props => {
    const { data } = props ?? {};

    return transferToColdWalletHotWallet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransferToColdWalletHotWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof transferToColdWalletHotWallet>>
>;
export type TransferToColdWalletHotWalletMutationBody = ColdWalletTransferRequest;
export type TransferToColdWalletHotWalletMutationError = ErrorType<void>;

export const useTransferToColdWalletHotWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    TError,
    { data: ColdWalletTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
  TError,
  { data: ColdWalletTransferRequest },
  TContext
> => {
  const mutationOptions = getTransferToColdWalletHotWalletMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTransferBalanceHotWallet = (
  params?: GetTransferBalanceHotWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<number>(
    { url: `/back-api/api/v2/wallets/hot/transfers-balance`, method: 'GET', params, signal },
    options
  );
};

export const getGetTransferBalanceHotWalletQueryKey = (params?: GetTransferBalanceHotWalletParams) => {
  return [`/back-api/api/v2/wallets/hot/transfers-balance`, ...(params ? [params] : [])] as const;
};

export const getGetTransferBalanceHotWalletQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransferBalanceHotWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferBalanceHotWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransferBalanceHotWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>> = ({ signal }) =>
    getTransferBalanceHotWallet(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransferBalanceHotWallet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransferBalanceHotWalletQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransferBalanceHotWallet>>
>;
export type GetTransferBalanceHotWalletQueryError = ErrorType<void>;

export const useGetTransferBalanceHotWallet = <
  TData = Awaited<ReturnType<typeof getTransferBalanceHotWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferBalanceHotWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransferBalanceHotWalletQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Try to recover a not included transaction
 */
export const recoverHotWallet = (
  walletTransactionRecoverRequest: WalletTransactionRecoverRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TransactionEmergencyRecoverResult>(
    {
      url: `/back-api/api/v2/wallets/recover`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: walletTransactionRecoverRequest,
    },
    options
  );
};

export const getRecoverHotWalletMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverHotWallet>>,
    TError,
    { data: WalletTransactionRecoverRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recoverHotWallet>>,
  TError,
  { data: WalletTransactionRecoverRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recoverHotWallet>>,
    { data: WalletTransactionRecoverRequest }
  > = props => {
    const { data } = props ?? {};

    return recoverHotWallet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecoverHotWalletMutationResult = NonNullable<Awaited<ReturnType<typeof recoverHotWallet>>>;
export type RecoverHotWalletMutationBody = WalletTransactionRecoverRequest;
export type RecoverHotWalletMutationError = ErrorType<void>;

/**
 * @summary Try to recover a not included transaction
 */
export const useRecoverHotWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverHotWallet>>,
    TError,
    { data: WalletTransactionRecoverRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof recoverHotWallet>>,
  TError,
  { data: WalletTransactionRecoverRequest },
  TContext
> => {
  const mutationOptions = getRecoverHotWalletMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary This method can be used to update all market properties
if market is stoped we change the market status to pause to prevent new orders then wait for order matcher
cancelation result then we change the market status to stoped
if market request is pause we send the request to order matcher and we wait for order cancelation result then
we change the status to paused
 */
export const updateMarket = (
  marketId: string,
  updateMarketRequest: UpdateMarketRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<MarketResponse>(
    {
      url: `/back-api/backoffice/market/${marketId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateMarketRequest,
    },
    options
  );
};

export const getUpdateMarketMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMarket>>,
    TError,
    { marketId: string; data: UpdateMarketRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMarket>>,
  TError,
  { marketId: string; data: UpdateMarketRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMarket>>,
    { marketId: string; data: UpdateMarketRequest }
  > = props => {
    const { marketId, data } = props ?? {};

    return updateMarket(marketId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMarketMutationResult = NonNullable<Awaited<ReturnType<typeof updateMarket>>>;
export type UpdateMarketMutationBody = UpdateMarketRequest;
export type UpdateMarketMutationError = ErrorType<void>;

/**
 * @summary This method can be used to update all market properties
if market is stoped we change the market status to pause to prevent new orders then wait for order matcher
cancelation result then we change the market status to stoped
if market request is pause we send the request to order matcher and we wait for order cancelation result then
we change the status to paused
 */
export const useUpdateMarket = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMarket>>,
    TError,
    { marketId: string; data: UpdateMarketRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateMarket>>,
  TError,
  { marketId: string; data: UpdateMarketRequest },
  TContext
> => {
  const mutationOptions = getUpdateMarketMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Returns market by market instrument
 */
export const getMarket = (marketId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<MarketResponse>(
    { url: `/back-api/backoffice/market/${marketId}`, method: 'GET', signal },
    options
  );
};

export const getGetMarketQueryKey = (marketId: string) => {
  return [`/back-api/backoffice/market/${marketId}`] as const;
};

export const getGetMarketQueryOptions = <TData = Awaited<ReturnType<typeof getMarket>>, TError = ErrorType<void>>(
  marketId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMarket>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketQueryKey(marketId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarket>>> = ({ signal }) =>
    getMarket(marketId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!marketId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMarket>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMarketQueryResult = NonNullable<Awaited<ReturnType<typeof getMarket>>>;
export type GetMarketQueryError = ErrorType<void>;

/**
 * @summary Returns market by market instrument
 */
export const useGetMarket = <TData = Awaited<ReturnType<typeof getMarket>>, TError = ErrorType<void>>(
  marketId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMarket>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMarketQueryOptions(marketId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary This method is called to make a market stable by marketId
 */
export const updateMarketStable = (
  marketStableRequest: MarketStableRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<MarketResponse>(
    {
      url: `/back-api/backoffice/market/stable`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: marketStableRequest,
    },
    options
  );
};

export const getUpdateMarketStableMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMarketStable>>,
    TError,
    { data: MarketStableRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMarketStable>>,
  TError,
  { data: MarketStableRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMarketStable>>,
    { data: MarketStableRequest }
  > = props => {
    const { data } = props ?? {};

    return updateMarketStable(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMarketStableMutationResult = NonNullable<Awaited<ReturnType<typeof updateMarketStable>>>;
export type UpdateMarketStableMutationBody = MarketStableRequest;
export type UpdateMarketStableMutationError = ErrorType<void>;

/**
 * @summary This method is called to make a market stable by marketId
 */
export const useUpdateMarketStable = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMarketStable>>,
    TError,
    { data: MarketStableRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateMarketStable>>,
  TError,
  { data: MarketStableRequest },
  TContext
> => {
  const mutationOptions = getUpdateMarketStableMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Query a single order by id
 */
export const getOrderByIdOrders = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderDetails>({ url: `/back-api/api/v2/orders/${orderId}`, method: 'GET', signal }, options);
};

export const getGetOrderByIdOrdersQueryKey = (orderId: string) => {
  return [`/back-api/api/v2/orders/${orderId}`] as const;
};

export const getGetOrderByIdOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderByIdOrders>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrderByIdOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderByIdOrdersQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderByIdOrders>>> = ({ signal }) =>
    getOrderByIdOrders(orderId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!orderId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderByIdOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderByIdOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderByIdOrders>>>;
export type GetOrderByIdOrdersQueryError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Query a single order by id
 */
export const useGetOrderByIdOrders = <
  TData = Awaited<ReturnType<typeof getOrderByIdOrders>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrderByIdOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderByIdOrdersQueryOptions(orderId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Query orders that match the filter.
Cursor to fetch the data; in request next is current page ex: {next:0,Prev:-1}.
In response If the next is 0 means there is no more data to fetch; if Prev is -1, there is not any previous data
 */
export const ordersGetOrders = (ordersRequest: OrdersRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrdersResponse>(
    {
      url: `/back-api/api/v2/orders`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: ordersRequest,
    },
    options
  );
};

export const getOrdersGetOrdersMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersGetOrders>>, TError, { data: OrdersRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof ordersGetOrders>>, TError, { data: OrdersRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersGetOrders>>, { data: OrdersRequest }> = props => {
    const { data } = props ?? {};

    return ordersGetOrders(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrdersGetOrdersMutationResult = NonNullable<Awaited<ReturnType<typeof ordersGetOrders>>>;
export type OrdersGetOrdersMutationBody = OrdersRequest;
export type OrdersGetOrdersMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Query orders that match the filter.
Cursor to fetch the data; in request next is current page ex: {next:0,Prev:-1}.
In response If the next is 0 means there is no more data to fetch; if Prev is -1, there is not any previous data
 */
export const useOrdersGetOrders = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersGetOrders>>, TError, { data: OrdersRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof ordersGetOrders>>, TError, { data: OrdersRequest }, TContext> => {
  const mutationOptions = getOrdersGetOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const ordersGetOrdersAsCsv = (
  ordersRequest: OrdersRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrdersResponse>(
    {
      url: `/back-api/api/v2/orders.csv`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: ordersRequest,
    },
    options
  );
};

export const getOrdersGetOrdersAsCsvMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>,
    TError,
    { data: OrdersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>, TError, { data: OrdersRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>,
    { data: OrdersRequest }
  > = props => {
    const { data } = props ?? {};

    return ordersGetOrdersAsCsv(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrdersGetOrdersAsCsvMutationResult = NonNullable<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>>;
export type OrdersGetOrdersAsCsvMutationBody = OrdersRequest;
export type OrdersGetOrdersAsCsvMutationError = ErrorType<ApiErrorResponse | void>;

export const useOrdersGetOrdersAsCsv = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>,
    TError,
    { data: OrdersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>, TError, { data: OrdersRequest }, TContext> => {
  const mutationOptions = getOrdersGetOrdersAsCsvMutationOptions(options);

  return useMutation(mutationOptions);
};

export const cancelOrderOrders = (orderId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/back-api/api/v2/orders/cancel/${orderId}`, method: 'DELETE' }, options);
};

export const getCancelOrderOrdersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelOrderOrders>>, TError, { orderId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelOrderOrders>>, TError, { orderId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelOrderOrders>>, { orderId: string }> = props => {
    const { orderId } = props ?? {};

    return cancelOrderOrders(orderId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelOrderOrdersMutationResult = NonNullable<Awaited<ReturnType<typeof cancelOrderOrders>>>;

export type CancelOrderOrdersMutationError = ErrorType<void>;

export const useCancelOrderOrders = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelOrderOrders>>, TError, { orderId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof cancelOrderOrders>>, TError, { orderId: string }, TContext> => {
  const mutationOptions = getCancelOrderOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getProfit = (
  params: GetProfitParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProfitResponse>({ url: `/back-api/backoffice/profit`, method: 'GET', params, signal }, options);
};

export const getGetProfitQueryKey = (params: GetProfitParams) => {
  return [`/back-api/backoffice/profit`, ...(params ? [params] : [])] as const;
};

export const getGetProfitQueryOptions = <TData = Awaited<ReturnType<typeof getProfit>>, TError = ErrorType<void>>(
  params: GetProfitParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProfit>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfitQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfit>>> = ({ signal }) =>
    getProfit(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProfit>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProfitQueryResult = NonNullable<Awaited<ReturnType<typeof getProfit>>>;
export type GetProfitQueryError = ErrorType<void>;

export const useGetProfit = <TData = Awaited<ReturnType<typeof getProfit>>, TError = ErrorType<void>>(
  params: GetProfitParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProfit>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProfitQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds rewards by admin.
 */
export const addRewardByAdmin = (
  rewardRequestModel: RewardRequestModel[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/rewards/add`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: rewardRequestModel,
    },
    options
  );
};

export const getAddRewardByAdminMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addRewardByAdmin>>,
    TError,
    { data: RewardRequestModel[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addRewardByAdmin>>,
  TError,
  { data: RewardRequestModel[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addRewardByAdmin>>,
    { data: RewardRequestModel[] }
  > = props => {
    const { data } = props ?? {};

    return addRewardByAdmin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddRewardByAdminMutationResult = NonNullable<Awaited<ReturnType<typeof addRewardByAdmin>>>;
export type AddRewardByAdminMutationBody = RewardRequestModel[];
export type AddRewardByAdminMutationError = ErrorType<void>;

/**
 * @summary Adds rewards by admin.
 */
export const useAddRewardByAdmin = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addRewardByAdmin>>,
    TError,
    { data: RewardRequestModel[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addRewardByAdmin>>,
  TError,
  { data: RewardRequestModel[] },
  TContext
> => {
  const mutationOptions = getAddRewardByAdminMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateRole = (roleRequest: RoleRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/roles`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: roleRequest,
    },
    options
  );
};

export const getUpdateRoleMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRole>>, { data: RoleRequest }> = props => {
    const { data } = props ?? {};

    return updateRole(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateRole>>>;
export type UpdateRoleMutationBody = RoleRequest;
export type UpdateRoleMutationError = ErrorType<void>;

export const useUpdateRole = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext> => {
  const mutationOptions = getUpdateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Adds role(s) to user
 */
export const addRole = (roleRequest: RoleRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/roles/add`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: roleRequest,
    },
    options
  );
};

export const getAddRoleMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addRole>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof addRole>>, TError, { data: RoleRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRole>>, { data: RoleRequest }> = props => {
    const { data } = props ?? {};

    return addRole(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddRoleMutationResult = NonNullable<Awaited<ReturnType<typeof addRole>>>;
export type AddRoleMutationBody = RoleRequest;
export type AddRoleMutationError = ErrorType<void>;

/**
 * @summary Adds role(s) to user
 */
export const useAddRole = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addRole>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof addRole>>, TError, { data: RoleRequest }, TContext> => {
  const mutationOptions = getAddRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Removes all user's roles, if any.
 */
export const removeRoles = (roleRequest: RoleRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/roles/remove`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: roleRequest,
    },
    options
  );
};

export const getRemoveRolesMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeRoles>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof removeRoles>>, TError, { data: RoleRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeRoles>>, { data: RoleRequest }> = props => {
    const { data } = props ?? {};

    return removeRoles(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveRolesMutationResult = NonNullable<Awaited<ReturnType<typeof removeRoles>>>;
export type RemoveRolesMutationBody = RoleRequest;
export type RemoveRolesMutationError = ErrorType<void>;

/**
 * @summary Removes all user's roles, if any.
 */
export const useRemoveRoles = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeRoles>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof removeRoles>>, TError, { data: RoleRequest }, TContext> => {
  const mutationOptions = getRemoveRolesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Query trades that match the filter.
 */
export const tradesGetOrders = (
  params: TradesGetOrdersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TradesResponse>({ url: `/back-api/api/v2/trades`, method: 'GET', params, signal }, options);
};

export const getTradesGetOrdersQueryKey = (params: TradesGetOrdersParams) => {
  return [`/back-api/api/v2/trades`, ...(params ? [params] : [])] as const;
};

export const getTradesGetOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof tradesGetOrders>>,
  TError = ErrorType<void>
>(
  params: TradesGetOrdersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tradesGetOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradesGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradesGetOrders>>> = ({ signal }) =>
    tradesGetOrders(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tradesGetOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TradesGetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof tradesGetOrders>>>;
export type TradesGetOrdersQueryError = ErrorType<void>;

/**
 * @summary Query trades that match the filter.
 */
export const useTradesGetOrders = <TData = Awaited<ReturnType<typeof tradesGetOrders>>, TError = ErrorType<void>>(
  params: TradesGetOrdersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tradesGetOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTradesGetOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAllTransfer = (
  accountId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransferRecord[]>(
    { url: `/back-api/backoffice/transfers/${accountId}`, method: 'GET', signal },
    options
  );
};

export const getGetAllTransferQueryKey = (accountId: number) => {
  return [`/back-api/backoffice/transfers/${accountId}`] as const;
};

export const getGetAllTransferQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllTransfer>>,
  TError = ErrorType<void>
>(
  accountId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTransfer>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTransferQueryKey(accountId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTransfer>>> = ({ signal }) =>
    getAllTransfer(accountId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!accountId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllTransfer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllTransferQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTransfer>>>;
export type GetAllTransferQueryError = ErrorType<void>;

export const useGetAllTransfer = <TData = Awaited<ReturnType<typeof getAllTransfer>>, TError = ErrorType<void>>(
  accountId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTransfer>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllTransferQueryOptions(accountId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Query deposit/withdrawal details.
 */
export const getTransfers = (
  params?: GetTransfersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransfersResponse>(
    { url: `/back-api/api/v2/transfers`, method: 'GET', params, signal },
    options
  );
};

export const getGetTransfersQueryKey = (params?: GetTransfersParams) => {
  return [`/back-api/api/v2/transfers`, ...(params ? [params] : [])] as const;
};

export const getGetTransfersQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransfers>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfers>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransfersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfers>>> = ({ signal }) =>
    getTransfers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransfers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransfersQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfers>>>;
export type GetTransfersQueryError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Query deposit/withdrawal details.
 */
export const useGetTransfers = <
  TData = Awaited<ReturnType<typeof getTransfers>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfers>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransfersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds custom transfer to specified user account which allows to manually modify user balance.
After entering invalid code for 3 times you should create a new request.
 */
export const transferTransfers = (
  manualTransferRequest: ManualTransferRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminManualTransferResponse>(
    {
      url: `/back-api/api/v2/transfers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: manualTransferRequest,
    },
    options
  );
};

export const getTransferTransfersMutationOptions = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferTransfers>>,
    TError,
    { data: ManualTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transferTransfers>>,
  TError,
  { data: ManualTransferRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transferTransfers>>,
    { data: ManualTransferRequest }
  > = props => {
    const { data } = props ?? {};

    return transferTransfers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransferTransfersMutationResult = NonNullable<Awaited<ReturnType<typeof transferTransfers>>>;
export type TransferTransfersMutationBody = ManualTransferRequest;
export type TransferTransfersMutationError = ErrorType<void | ApiErrorResponse>;

/**
 * @summary Adds custom transfer to specified user account which allows to manually modify user balance.
After entering invalid code for 3 times you should create a new request.
 */
export const useTransferTransfers = <TError = ErrorType<void | ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferTransfers>>,
    TError,
    { data: ManualTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof transferTransfers>>,
  TError,
  { data: ManualTransferRequest },
  TContext
> => {
  const mutationOptions = getTransferTransfersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTransfersAsCsv = (
  params?: GetTransfersAsCsvParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransfersResponse>(
    { url: `/back-api/api/v2/transfers.csv`, method: 'GET', params, signal },
    options
  );
};

export const getGetTransfersAsCsvQueryKey = (params?: GetTransfersAsCsvParams) => {
  return [`/back-api/api/v2/transfers.csv`, ...(params ? [params] : [])] as const;
};

export const getGetTransfersAsCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransfersAsCsv>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersAsCsvParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfersAsCsv>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransfersAsCsvQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersAsCsv>>> = ({ signal }) =>
    getTransfersAsCsv(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransfersAsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTransfersAsCsvQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersAsCsv>>>;
export type GetTransfersAsCsvQueryError = ErrorType<ApiErrorResponse | void>;

export const useGetTransfersAsCsv = <
  TData = Awaited<ReturnType<typeof getTransfersAsCsv>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersAsCsvParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfersAsCsv>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransfersAsCsvQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Approves a specific deposit that is in the PendingReview status.
 */
export const approvePendingReviewDepositTransfers = (
  approvePendingReviewDepositRequest: ApprovePendingReviewDepositRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/approve-pending-review`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: approvePendingReviewDepositRequest,
    },
    options
  );
};

export const getApprovePendingReviewDepositTransfersMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>,
    TError,
    { data: ApprovePendingReviewDepositRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>,
  TError,
  { data: ApprovePendingReviewDepositRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>,
    { data: ApprovePendingReviewDepositRequest }
  > = props => {
    const { data } = props ?? {};

    return approvePendingReviewDepositTransfers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApprovePendingReviewDepositTransfersMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>
>;
export type ApprovePendingReviewDepositTransfersMutationBody = ApprovePendingReviewDepositRequest;
export type ApprovePendingReviewDepositTransfersMutationError = ErrorType<void>;

/**
 * @summary Approves a specific deposit that is in the PendingReview status.
 */
export const useApprovePendingReviewDepositTransfers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>,
    TError,
    { data: ApprovePendingReviewDepositRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof approvePendingReviewDepositTransfers>>,
  TError,
  { data: ApprovePendingReviewDepositRequest },
  TContext
> => {
  const mutationOptions = getApprovePendingReviewDepositTransfersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAllUser = (
  params?: GetAllUserParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserItemResponseFilteredResponse>(
    { url: `/back-api/backoffice/users`, method: 'GET', params, signal },
    options
  );
};

export const getGetAllUserQueryKey = (params?: GetAllUserParams) => {
  return [`/back-api/backoffice/users`, ...(params ? [params] : [])] as const;
};

export const getGetAllUserQueryOptions = <TData = Awaited<ReturnType<typeof getAllUser>>, TError = ErrorType<void>>(
  params?: GetAllUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllUser>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllUserQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUser>>> = ({ signal }) =>
    getAllUser(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllUserQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUser>>>;
export type GetAllUserQueryError = ErrorType<void>;

export const useGetAllUser = <TData = Awaited<ReturnType<typeof getAllUser>>, TError = ErrorType<void>>(
  params?: GetAllUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllUser>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllUserQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUser = (getUserRequest: GetUserRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserResponseResponse>(
    {
      url: `/back-api/backoffice/user`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getUserRequest,
    },
    options
  );
};

export const getGetUserMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getUser>>, TError, { data: GetUserRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof getUser>>, TError, { data: GetUserRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getUser>>, { data: GetUserRequest }> = props => {
    const { data } = props ?? {};

    return getUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetUserMutationResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserMutationBody = GetUserRequest;
export type GetUserMutationError = ErrorType<void>;

export const useGetUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getUser>>, TError, { data: GetUserRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof getUser>>, TError, { data: GetUserRequest }, TContext> => {
  const mutationOptions = getGetUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary This API is deprecated. Use api/v3/users/status OR api/v3/users/status/list
 */
export const updateUser = (updateUserRequest: UpdateUserRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserResponse>(
    {
      url: `/back-api/backoffice/user`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateUserRequest,
    },
    options
  );
};

export const getUpdateUserMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError, { data: UpdateUserRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError, { data: UpdateUserRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, { data: UpdateUserRequest }> = props => {
    const { data } = props ?? {};

    return updateUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = UpdateUserRequest;
export type UpdateUserMutationError = ErrorType<void>;

/**
 * @summary This API is deprecated. Use api/v3/users/status OR api/v3/users/status/list
 */
export const useUpdateUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError, { data: UpdateUserRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof updateUser>>, TError, { data: UpdateUserRequest }, TContext> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const blockUser = (
  blockUserApiRequest: BlockUserApiRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/user/block`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: blockUserApiRequest,
    },
    options
  );
};

export const getBlockUserMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockUser>>, TError, { data: BlockUserApiRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof blockUser>>, TError, { data: BlockUserApiRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockUser>>, { data: BlockUserApiRequest }> = props => {
    const { data } = props ?? {};

    return blockUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof blockUser>>>;
export type BlockUserMutationBody = BlockUserApiRequest;
export type BlockUserMutationError = ErrorType<void>;

export const useBlockUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockUser>>, TError, { data: BlockUserApiRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof blockUser>>, TError, { data: BlockUserApiRequest }, TContext> => {
  const mutationOptions = getBlockUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const unlockUser = (
  unblockUserApiRequest: UnblockUserApiRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/user/unblock`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: unblockUserApiRequest,
    },
    options
  );
};

export const getUnlockUserMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlockUser>>,
    TError,
    { data: UnblockUserApiRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof unlockUser>>, TError, { data: UnblockUserApiRequest }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unlockUser>>,
    { data: UnblockUserApiRequest }
  > = props => {
    const { data } = props ?? {};

    return unlockUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof unlockUser>>>;
export type UnlockUserMutationBody = UnblockUserApiRequest;
export type UnlockUserMutationError = ErrorType<void>;

export const useUnlockUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlockUser>>,
    TError,
    { data: UnblockUserApiRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof unlockUser>>, TError, { data: UnblockUserApiRequest }, TContext> => {
  const mutationOptions = getUnlockUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Returns balances of all user accounts.
 */
export const getUserBalance = (
  userPortfolioRequest: UserPortfolioRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PortfolioResponse>(
    {
      url: `/back-api/api/v2/users/balance`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userPortfolioRequest,
    },
    options
  );
};

export const getGetUserBalanceMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserBalance>>,
    TError,
    { data: UserPortfolioRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getUserBalance>>,
  TError,
  { data: UserPortfolioRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getUserBalance>>,
    { data: UserPortfolioRequest }
  > = props => {
    const { data } = props ?? {};

    return getUserBalance(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetUserBalanceMutationResult = NonNullable<Awaited<ReturnType<typeof getUserBalance>>>;
export type GetUserBalanceMutationBody = UserPortfolioRequest;
export type GetUserBalanceMutationError = ErrorType<void>;

/**
 * @summary Returns balances of all user accounts.
 */
export const useGetUserBalance = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserBalance>>,
    TError,
    { data: UserPortfolioRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof getUserBalance>>, TError, { data: UserPortfolioRequest }, TContext> => {
  const mutationOptions = getGetUserBalanceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin
 * @summary Get all users by filters
 */
export const getAllUsers = (userFilters: UserFilters, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserItemResponseFilteredResponse>(
    {
      url: `/back-api/api/v3/users/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userFilters,
    },
    options
  );
};

export const getGetAllUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, { data: UserFilters }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, { data: UserFilters }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getAllUsers>>, { data: UserFilters }> = props => {
    const { data } = props ?? {};

    return getAllUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getAllUsers>>>;
export type GetAllUsersMutationBody = UserFilters;
export type GetAllUsersMutationError = ErrorType<void>;

/**
 * @summary Get all users by filters
 */
export const useGetAllUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, { data: UserFilters }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof getAllUsers>>, TError, { data: UserFilters }, TContext> => {
  const mutationOptions = getGetAllUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get reviews for all users
 */
export const getAllUserReviewsUsers = (
  complianceUsersRequest: ComplianceUsersRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ComplianceUsersResponse>(
    {
      url: `/back-api/api/v3/users/reviews/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceUsersRequest,
    },
    options
  );
};

export const getGetAllUserReviewsUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllUserReviewsUsers>>,
    TError,
    { data: ComplianceUsersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllUserReviewsUsers>>,
  TError,
  { data: ComplianceUsersRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllUserReviewsUsers>>,
    { data: ComplianceUsersRequest }
  > = props => {
    const { data } = props ?? {};

    return getAllUserReviewsUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAllUserReviewsUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getAllUserReviewsUsers>>>;
export type GetAllUserReviewsUsersMutationBody = ComplianceUsersRequest;
export type GetAllUserReviewsUsersMutationError = ErrorType<void>;

/**
 * @summary Get reviews for all users
 */
export const useGetAllUserReviewsUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllUserReviewsUsers>>,
    TError,
    { data: ComplianceUsersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAllUserReviewsUsers>>,
  TError,
  { data: ComplianceUsersRequest },
  TContext
> => {
  const mutationOptions = getGetAllUserReviewsUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get info for a single user
 */
export const getUserDetailsUsers = (
  getUserDetailsRequest: GetUserDetailsRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserDetailsResponse>(
    {
      url: `/back-api/api/v3/users/get`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getUserDetailsRequest,
    },
    options
  );
};

export const getGetUserDetailsUsersMutationOptions = <
  TError = ErrorType<void | ProblemDetails | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserDetailsUsers>>,
    TError,
    { data: GetUserDetailsRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getUserDetailsUsers>>,
  TError,
  { data: GetUserDetailsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getUserDetailsUsers>>,
    { data: GetUserDetailsRequest }
  > = props => {
    const { data } = props ?? {};

    return getUserDetailsUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetUserDetailsUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getUserDetailsUsers>>>;
export type GetUserDetailsUsersMutationBody = GetUserDetailsRequest;
export type GetUserDetailsUsersMutationError = ErrorType<void | ProblemDetails | ApiErrorResponse>;

/**
 * @summary Get info for a single user
 */
export const useGetUserDetailsUsers = <
  TError = ErrorType<void | ProblemDetails | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserDetailsUsers>>,
    TError,
    { data: GetUserDetailsRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getUserDetailsUsers>>,
  TError,
  { data: GetUserDetailsRequest },
  TContext
> => {
  const mutationOptions = getGetUserDetailsUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin
 * @summary Update user status for a single user
 */
export const updateUserStatusUsers = (
  updateUserRequest: UpdateUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserResponse>(
    {
      url: `/back-api/api/v3/users/status`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateUserRequest,
    },
    options
  );
};

export const getUpdateUserStatusUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserStatusUsers>>,
    TError,
    { data: UpdateUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserStatusUsers>>,
  TError,
  { data: UpdateUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserStatusUsers>>,
    { data: UpdateUserRequest }
  > = props => {
    const { data } = props ?? {};

    return updateUserStatusUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserStatusUsersMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserStatusUsers>>>;
export type UpdateUserStatusUsersMutationBody = UpdateUserRequest;
export type UpdateUserStatusUsersMutationError = ErrorType<void>;

/**
 * @summary Update user status for a single user
 */
export const useUpdateUserStatusUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserStatusUsers>>,
    TError,
    { data: UpdateUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserStatusUsers>>,
  TError,
  { data: UpdateUserRequest },
  TContext
> => {
  const mutationOptions = getUpdateUserStatusUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin
 * @summary Update users status for a list of users
 */
export const updateUsersStatus = (
  updateUsersRequest: UpdateUsersRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string[]>(
    {
      url: `/back-api/api/v3/users/status/list`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateUsersRequest,
    },
    options
  );
};

export const getUpdateUsersStatusMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUsersStatus>>,
    TError,
    { data: UpdateUsersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUsersStatus>>,
  TError,
  { data: UpdateUsersRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUsersStatus>>,
    { data: UpdateUsersRequest }
  > = props => {
    const { data } = props ?? {};

    return updateUsersStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUsersStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateUsersStatus>>>;
export type UpdateUsersStatusMutationBody = UpdateUsersRequest;
export type UpdateUsersStatusMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Update users status for a list of users
 */
export const useUpdateUsersStatus = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUsersStatus>>,
    TError,
    { data: UpdateUsersRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUsersStatus>>,
  TError,
  { data: UpdateUsersRequest },
  TContext
> => {
  const mutationOptions = getUpdateUsersStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Update review request status for a single user
 */
export const changeUserReviewStatusUsers = (
  complianceChangeUserStatusRequest: ComplianceChangeUserStatusRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/mute`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceChangeUserStatusRequest,
    },
    options
  );
};

export const getChangeUserReviewStatusUsersMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUserReviewStatusUsers>>,
    TError,
    { data: ComplianceChangeUserStatusRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeUserReviewStatusUsers>>,
  TError,
  { data: ComplianceChangeUserStatusRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeUserReviewStatusUsers>>,
    { data: ComplianceChangeUserStatusRequest }
  > = props => {
    const { data } = props ?? {};

    return changeUserReviewStatusUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeUserReviewStatusUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeUserReviewStatusUsers>>
>;
export type ChangeUserReviewStatusUsersMutationBody = ComplianceChangeUserStatusRequest;
export type ChangeUserReviewStatusUsersMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Update review request status for a single user
 */
export const useChangeUserReviewStatusUsers = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeUserReviewStatusUsers>>,
    TError,
    { data: ComplianceChangeUserStatusRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeUserReviewStatusUsers>>,
  TError,
  { data: ComplianceChangeUserStatusRequest },
  TContext
> => {
  const mutationOptions = getChangeUserReviewStatusUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Resolve review request for a single user
 */
export const resolveUsers = (
  userReviewResolveRequest: UserReviewResolveRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/resolve`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userReviewResolveRequest,
    },
    options
  );
};

export const getResolveUsersMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveUsers>>,
    TError,
    { data: UserReviewResolveRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resolveUsers>>,
  TError,
  { data: UserReviewResolveRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveUsers>>,
    { data: UserReviewResolveRequest }
  > = props => {
    const { data } = props ?? {};

    return resolveUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveUsersMutationResult = NonNullable<Awaited<ReturnType<typeof resolveUsers>>>;
export type ResolveUsersMutationBody = UserReviewResolveRequest;
export type ResolveUsersMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Resolve review request for a single user
 */
export const useResolveUsers = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveUsers>>,
    TError,
    { data: UserReviewResolveRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof resolveUsers>>,
  TError,
  { data: UserReviewResolveRequest },
  TContext
> => {
  const mutationOptions = getResolveUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get documents for a single user
 */
export const getDocumentsByUserUsers = (
  getDocumentsByUserRequest: GetDocumentsByUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/documents/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getDocumentsByUserRequest,
    },
    options
  );
};

export const getGetDocumentsByUserUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDocumentsByUserUsers>>,
    TError,
    { data: GetDocumentsByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getDocumentsByUserUsers>>,
  TError,
  { data: GetDocumentsByUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getDocumentsByUserUsers>>,
    { data: GetDocumentsByUserRequest }
  > = props => {
    const { data } = props ?? {};

    return getDocumentsByUserUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetDocumentsByUserUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getDocumentsByUserUsers>>>;
export type GetDocumentsByUserUsersMutationBody = GetDocumentsByUserRequest;
export type GetDocumentsByUserUsersMutationError = ErrorType<void>;

/**
 * @summary Get documents for a single user
 */
export const useGetDocumentsByUserUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDocumentsByUserUsers>>,
    TError,
    { data: GetDocumentsByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getDocumentsByUserUsers>>,
  TError,
  { data: GetDocumentsByUserRequest },
  TContext
> => {
  const mutationOptions = getGetDocumentsByUserUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get orders for a single user
 */
export const usersGetOrdersByUser = (
  getOrdersByUserRequest: GetOrdersByUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserOrdersResponse>(
    {
      url: `/back-api/api/v3/users/orders/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getOrdersByUserRequest,
    },
    options
  );
};

export const getUsersGetOrdersByUserMutationOptions = <
  TError = ErrorType<ApiErrorResponse | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersGetOrdersByUser>>,
    TError,
    { data: GetOrdersByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersGetOrdersByUser>>,
  TError,
  { data: GetOrdersByUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersGetOrdersByUser>>,
    { data: GetOrdersByUserRequest }
  > = props => {
    const { data } = props ?? {};

    return usersGetOrdersByUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersGetOrdersByUserMutationResult = NonNullable<Awaited<ReturnType<typeof usersGetOrdersByUser>>>;
export type UsersGetOrdersByUserMutationBody = GetOrdersByUserRequest;
export type UsersGetOrdersByUserMutationError = ErrorType<ApiErrorResponse | void>;

/**
 * @summary Get orders for a single user
 */
export const useUsersGetOrdersByUser = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersGetOrdersByUser>>,
    TError,
    { data: GetOrdersByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersGetOrdersByUser>>,
  TError,
  { data: GetOrdersByUserRequest },
  TContext
> => {
  const mutationOptions = getUsersGetOrdersByUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get portfolio for a single user
 */
export const getPortfolioByUserUsers = (
  userPortfolioRequest: UserPortfolioRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PortfolioResponse>(
    {
      url: `/back-api/api/v3/users/portfolio/get`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: userPortfolioRequest,
    },
    options
  );
};

export const getGetPortfolioByUserUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioByUserUsers>>,
    TError,
    { data: UserPortfolioRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPortfolioByUserUsers>>,
  TError,
  { data: UserPortfolioRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPortfolioByUserUsers>>,
    { data: UserPortfolioRequest }
  > = props => {
    const { data } = props ?? {};

    return getPortfolioByUserUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetPortfolioByUserUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getPortfolioByUserUsers>>>;
export type GetPortfolioByUserUsersMutationBody = UserPortfolioRequest;
export type GetPortfolioByUserUsersMutationError = ErrorType<void>;

/**
 * @summary Get portfolio for a single user
 */
export const useGetPortfolioByUserUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioByUserUsers>>,
    TError,
    { data: UserPortfolioRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getPortfolioByUserUsers>>,
  TError,
  { data: UserPortfolioRequest },
  TContext
> => {
  const mutationOptions = getGetPortfolioByUserUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Get transfers for a single user
 */
export const getTransfersUsers = (
  getTransfersByUserRequest: GetTransfersByUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GetTransfersByUserResponse>(
    {
      url: `/back-api/api/v3/users/transfers/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getTransfersByUserRequest,
    },
    options
  );
};

export const getGetTransfersUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getTransfersUsers>>,
    TError,
    { data: GetTransfersByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getTransfersUsers>>,
  TError,
  { data: GetTransfersByUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getTransfersUsers>>,
    { data: GetTransfersByUserRequest }
  > = props => {
    const { data } = props ?? {};

    return getTransfersUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetTransfersUsersMutationResult = NonNullable<Awaited<ReturnType<typeof getTransfersUsers>>>;
export type GetTransfersUsersMutationBody = GetTransfersByUserRequest;
export type GetTransfersUsersMutationError = ErrorType<void>;

/**
 * @summary Get transfers for a single user
 */
export const useGetTransfersUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getTransfersUsers>>,
    TError,
    { data: GetTransfersByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getTransfersUsers>>,
  TError,
  { data: GetTransfersByUserRequest },
  TContext
> => {
  const mutationOptions = getGetTransfersUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin, ComplianceReviewer
 * @summary Sets the compliance tier of the user to one lower level (If it's Tier1 we return an error that this is the minimum level). Only accessible from the admin and compliance officer
 */
export const downgradeUserTierUsers = (
  complianceTierDowngradeRequest: ComplianceTierDowngradeRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/downgrade`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: complianceTierDowngradeRequest,
    },
    options
  );
};

export const getDowngradeUserTierUsersMutationOptions = <
  TError = ErrorType<void | ApiErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downgradeUserTierUsers>>,
    TError,
    { data: ComplianceTierDowngradeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downgradeUserTierUsers>>,
  TError,
  { data: ComplianceTierDowngradeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downgradeUserTierUsers>>,
    { data: ComplianceTierDowngradeRequest }
  > = props => {
    const { data } = props ?? {};

    return downgradeUserTierUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DowngradeUserTierUsersMutationResult = NonNullable<Awaited<ReturnType<typeof downgradeUserTierUsers>>>;
export type DowngradeUserTierUsersMutationBody = ComplianceTierDowngradeRequest;
export type DowngradeUserTierUsersMutationError = ErrorType<void | ApiErrorResponse>;

/**
 * @summary Sets the compliance tier of the user to one lower level (If it's Tier1 we return an error that this is the minimum level). Only accessible from the admin and compliance officer
 */
export const useDowngradeUserTierUsers = <TError = ErrorType<void | ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downgradeUserTierUsers>>,
    TError,
    { data: ComplianceTierDowngradeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof downgradeUserTierUsers>>,
  TError,
  { data: ComplianceTierDowngradeRequest },
  TContext
> => {
  const mutationOptions = getDowngradeUserTierUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin
 * @summary Get a list of account tags for a user
 */
export const getAccountTagsByUserUsers = (
  getAccountTagsByUserRequest: GetAccountTagsByUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/account-tags/list`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: getAccountTagsByUserRequest,
    },
    options
  );
};

export const getGetAccountTagsByUserUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAccountTagsByUserUsers>>,
    TError,
    { data: GetAccountTagsByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAccountTagsByUserUsers>>,
  TError,
  { data: GetAccountTagsByUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAccountTagsByUserUsers>>,
    { data: GetAccountTagsByUserRequest }
  > = props => {
    const { data } = props ?? {};

    return getAccountTagsByUserUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAccountTagsByUserUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof getAccountTagsByUserUsers>>
>;
export type GetAccountTagsByUserUsersMutationBody = GetAccountTagsByUserRequest;
export type GetAccountTagsByUserUsersMutationError = ErrorType<void>;

/**
 * @summary Get a list of account tags for a user
 */
export const useGetAccountTagsByUserUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAccountTagsByUserUsers>>,
    TError,
    { data: GetAccountTagsByUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getAccountTagsByUserUsers>>,
  TError,
  { data: GetAccountTagsByUserRequest },
  TContext
> => {
  const mutationOptions = getGetAccountTagsByUserUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * For instance, assigning a MarketMaker tag to an account is beneficial as it allows for the placement of orders without incurring any fees.
Roles: Admin
 * @summary Add a tag to an account
 */
export const createAccountTagUsers = (
  createAccountTagRequest: CreateAccountTagRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/account-tags/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: createAccountTagRequest,
    },
    options
  );
};

export const getCreateAccountTagUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAccountTagUsers>>,
    TError,
    { data: CreateAccountTagRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAccountTagUsers>>,
  TError,
  { data: CreateAccountTagRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAccountTagUsers>>,
    { data: CreateAccountTagRequest }
  > = props => {
    const { data } = props ?? {};

    return createAccountTagUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAccountTagUsersMutationResult = NonNullable<Awaited<ReturnType<typeof createAccountTagUsers>>>;
export type CreateAccountTagUsersMutationBody = CreateAccountTagRequest;
export type CreateAccountTagUsersMutationError = ErrorType<void>;

/**
 * @summary Add a tag to an account
 */
export const useCreateAccountTagUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAccountTagUsers>>,
    TError,
    { data: CreateAccountTagRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAccountTagUsers>>,
  TError,
  { data: CreateAccountTagRequest },
  TContext
> => {
  const mutationOptions = getCreateAccountTagUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Roles: Admin
 * @summary Remove a tag from an account
 */
export const deleteAccountTagUsers = (
  deleteAccountTagRequest: DeleteAccountTagRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v3/users/account-tags/delete`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: deleteAccountTagRequest,
    },
    options
  );
};

export const getDeleteAccountTagUsersMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAccountTagUsers>>,
    TError,
    { data: DeleteAccountTagRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAccountTagUsers>>,
  TError,
  { data: DeleteAccountTagRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAccountTagUsers>>,
    { data: DeleteAccountTagRequest }
  > = props => {
    const { data } = props ?? {};

    return deleteAccountTagUsers(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAccountTagUsersMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountTagUsers>>>;
export type DeleteAccountTagUsersMutationBody = DeleteAccountTagRequest;
export type DeleteAccountTagUsersMutationError = ErrorType<void>;

/**
 * @summary Remove a tag from an account
 */
export const useDeleteAccountTagUsers = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAccountTagUsers>>,
    TError,
    { data: DeleteAccountTagRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAccountTagUsers>>,
  TError,
  { data: DeleteAccountTagRequest },
  TContext
> => {
  const mutationOptions = getDeleteAccountTagUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Instruments
 */
export const getAllInstrumentsCharting = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/marketdata/instruments`, method: 'GET', signal }, options);
};

export const getGetAllInstrumentsChartingQueryKey = () => {
  return [`/marketdata/instruments`] as const;
};

export const getGetAllInstrumentsChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllInstrumentsCharting>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllInstrumentsCharting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllInstrumentsChartingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllInstrumentsCharting>>> = ({ signal }) =>
    getAllInstrumentsCharting(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllInstrumentsCharting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllInstrumentsChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getAllInstrumentsCharting>>>;
export type GetAllInstrumentsChartingQueryError = ErrorType<unknown>;

/**
 * @summary Get All Instruments
 */
export const useGetAllInstrumentsCharting = <
  TData = Awaited<ReturnType<typeof getAllInstrumentsCharting>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllInstrumentsCharting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllInstrumentsChartingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get All Instruments
 */
export const getAllInstrumentsV1Charting = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/marketdata/api/v1/instruments`, method: 'GET', signal }, options);
};

export const getGetAllInstrumentsV1ChartingQueryKey = () => {
  return [`/marketdata/api/v1/instruments`] as const;
};

export const getGetAllInstrumentsV1ChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllInstrumentsV1ChartingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>> = ({ signal }) =>
    getAllInstrumentsV1Charting(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllInstrumentsV1ChartingQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>
>;
export type GetAllInstrumentsV1ChartingQueryError = ErrorType<unknown>;

/**
 * @summary Get All Instruments
 */
export const useGetAllInstrumentsV1Charting = <
  TData = Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllInstrumentsV1Charting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllInstrumentsV1ChartingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get recent history for a specified market(instrument).
 * @summary Recent History
 */
export const getRecentHistoryCharting = (
  instrument: string,
  params: GetRecentHistoryChartingParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ChartHistoryResponse>(
    { url: `/marketdata/instruments/${instrument}/limited_history`, method: 'GET', params, signal },
    options
  );
};

export const getGetRecentHistoryChartingQueryKey = (instrument: string, params: GetRecentHistoryChartingParams) => {
  return [`/marketdata/instruments/${instrument}/limited_history`, ...(params ? [params] : [])] as const;
};

export const getGetRecentHistoryChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecentHistoryCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  params: GetRecentHistoryChartingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentHistoryCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecentHistoryChartingQueryKey(instrument, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentHistoryCharting>>> = ({ signal }) =>
    getRecentHistoryCharting(instrument, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!instrument, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecentHistoryCharting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRecentHistoryChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentHistoryCharting>>>;
export type GetRecentHistoryChartingQueryError = ErrorType<unknown>;

/**
 * @summary Recent History
 */
export const useGetRecentHistoryCharting = <
  TData = Awaited<ReturnType<typeof getRecentHistoryCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  params: GetRecentHistoryChartingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentHistoryCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecentHistoryChartingQueryOptions(instrument, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get recent history for a specified market(instrument).
 * @summary Recent History
 */
export const getRecentHistoryV1Charting = (
  instrument: string,
  params: GetRecentHistoryV1ChartingParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ChartHistoryResponse>(
    { url: `/marketdata/api/v1/instruments/${instrument}/limited_history`, method: 'GET', params, signal },
    options
  );
};

export const getGetRecentHistoryV1ChartingQueryKey = (instrument: string, params: GetRecentHistoryV1ChartingParams) => {
  return [`/marketdata/api/v1/instruments/${instrument}/limited_history`, ...(params ? [params] : [])] as const;
};

export const getGetRecentHistoryV1ChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecentHistoryV1Charting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  params: GetRecentHistoryV1ChartingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentHistoryV1Charting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecentHistoryV1ChartingQueryKey(instrument, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentHistoryV1Charting>>> = ({ signal }) =>
    getRecentHistoryV1Charting(instrument, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!instrument, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecentHistoryV1Charting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRecentHistoryV1ChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentHistoryV1Charting>>>;
export type GetRecentHistoryV1ChartingQueryError = ErrorType<unknown>;

/**
 * @summary Recent History
 */
export const useGetRecentHistoryV1Charting = <
  TData = Awaited<ReturnType<typeof getRecentHistoryV1Charting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  params: GetRecentHistoryV1ChartingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentHistoryV1Charting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecentHistoryV1ChartingQueryOptions(instrument, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get order book for a specified market(instrument)
 * @summary Get Order Book
 */
export const getBookInfoCharting = (
  instrument: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderBookInfo>(
    { url: `/marketdata/instruments/${instrument}/depth`, method: 'GET', signal },
    options
  );
};

export const getGetBookInfoChartingQueryKey = (instrument: string) => {
  return [`/marketdata/instruments/${instrument}/depth`] as const;
};

export const getGetBookInfoChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getBookInfoCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookInfoCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookInfoChartingQueryKey(instrument);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookInfoCharting>>> = ({ signal }) =>
    getBookInfoCharting(instrument, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!instrument, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBookInfoCharting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBookInfoChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getBookInfoCharting>>>;
export type GetBookInfoChartingQueryError = ErrorType<unknown>;

/**
 * @summary Get Order Book
 */
export const useGetBookInfoCharting = <
  TData = Awaited<ReturnType<typeof getBookInfoCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookInfoCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBookInfoChartingQueryOptions(instrument, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get order book for a specified market(instrument)
 * @summary Get Order Book
 */
export const getBookInfoV1Charting = (
  instrument: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderBookInfo>(
    { url: `/marketdata/api/v1/instruments/${instrument}/depth`, method: 'GET', signal },
    options
  );
};

export const getGetBookInfoV1ChartingQueryKey = (instrument: string) => {
  return [`/marketdata/api/v1/instruments/${instrument}/depth`] as const;
};

export const getGetBookInfoV1ChartingQueryOptions = <
  TData = Awaited<ReturnType<typeof getBookInfoV1Charting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookInfoV1Charting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookInfoV1ChartingQueryKey(instrument);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookInfoV1Charting>>> = ({ signal }) =>
    getBookInfoV1Charting(instrument, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!instrument, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBookInfoV1Charting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBookInfoV1ChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getBookInfoV1Charting>>>;
export type GetBookInfoV1ChartingQueryError = ErrorType<unknown>;

/**
 * @summary Get Order Book
 */
export const useGetBookInfoV1Charting = <
  TData = Awaited<ReturnType<typeof getBookInfoV1Charting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookInfoV1Charting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBookInfoV1ChartingQueryOptions(instrument, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get market statistics for all markets for the past 24 hours.
 * @summary Tickers
 */
export const tickersV1Markets = (
  params?: TickersV1MarketsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Market24HourData[]>(
    { url: `/marketdata/api/v1/tickers`, method: 'GET', params, signal },
    options
  );
};

export const getTickersV1MarketsQueryKey = (params?: TickersV1MarketsParams) => {
  return [`/marketdata/api/v1/tickers`, ...(params ? [params] : [])] as const;
};

export const getTickersV1MarketsQueryOptions = <
  TData = Awaited<ReturnType<typeof tickersV1Markets>>,
  TError = ErrorType<unknown>
>(
  params?: TickersV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tickersV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTickersV1MarketsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tickersV1Markets>>> = ({ signal }) =>
    tickersV1Markets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tickersV1Markets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TickersV1MarketsQueryResult = NonNullable<Awaited<ReturnType<typeof tickersV1Markets>>>;
export type TickersV1MarketsQueryError = ErrorType<unknown>;

/**
 * @summary Tickers
 */
export const useTickersV1Markets = <TData = Awaited<ReturnType<typeof tickersV1Markets>>, TError = ErrorType<unknown>>(
  params?: TickersV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tickersV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTickersV1MarketsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves order book data for a specific market (ticker).
 * @summary Order Book
 */
export const orderbookV1Markets = (
  symbol: string,
  params?: OrderbookV1MarketsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderBookResponse>(
    { url: `/marketdata/api/v1/orderbook/${symbol}`, method: 'GET', params, signal },
    options
  );
};

export const getOrderbookV1MarketsQueryKey = (symbol: string, params?: OrderbookV1MarketsParams) => {
  return [`/marketdata/api/v1/orderbook/${symbol}`, ...(params ? [params] : [])] as const;
};

export const getOrderbookV1MarketsQueryOptions = <
  TData = Awaited<ReturnType<typeof orderbookV1Markets>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  params?: OrderbookV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof orderbookV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderbookV1MarketsQueryKey(symbol, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orderbookV1Markets>>> = ({ signal }) =>
    orderbookV1Markets(symbol, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!symbol, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof orderbookV1Markets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrderbookV1MarketsQueryResult = NonNullable<Awaited<ReturnType<typeof orderbookV1Markets>>>;
export type OrderbookV1MarketsQueryError = ErrorType<unknown>;

/**
 * @summary Order Book
 */
export const useOrderbookV1Markets = <
  TData = Awaited<ReturnType<typeof orderbookV1Markets>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  params?: OrderbookV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof orderbookV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrderbookV1MarketsQueryOptions(symbol, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves historical trade data for a specific market (ticker) based on the specified type (buy/sell).
 * @summary Trade History
 */
export const tradeHistoryV1Markets = (
  symbol: string,
  params?: TradeHistoryV1MarketsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TradeHistoryResponse>(
    { url: `/marketdata/api/v1/history/${symbol}`, method: 'GET', params, signal },
    options
  );
};

export const getTradeHistoryV1MarketsQueryKey = (symbol: string, params?: TradeHistoryV1MarketsParams) => {
  return [`/marketdata/api/v1/history/${symbol}`, ...(params ? [params] : [])] as const;
};

export const getTradeHistoryV1MarketsQueryOptions = <
  TData = Awaited<ReturnType<typeof tradeHistoryV1Markets>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  params?: TradeHistoryV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tradeHistoryV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradeHistoryV1MarketsQueryKey(symbol, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradeHistoryV1Markets>>> = ({ signal }) =>
    tradeHistoryV1Markets(symbol, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!symbol, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tradeHistoryV1Markets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TradeHistoryV1MarketsQueryResult = NonNullable<Awaited<ReturnType<typeof tradeHistoryV1Markets>>>;
export type TradeHistoryV1MarketsQueryError = ErrorType<unknown>;

/**
 * @summary Trade History
 */
export const useTradeHistoryV1Markets = <
  TData = Awaited<ReturnType<typeof tradeHistoryV1Markets>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  params?: TradeHistoryV1MarketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tradeHistoryV1Markets>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTradeHistoryV1MarketsQueryOptions(symbol, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Overview of market data for all tickers and all market pairs on the exchange.
 * @summary Market Summary
 */
export const summaryV1Markets = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<MarketSummaryData[]>({ url: `/marketdata/api/v1/summary`, method: 'GET', signal }, options);
};

export const getSummaryV1MarketsQueryKey = () => {
  return [`/marketdata/api/v1/summary`] as const;
};

export const getSummaryV1MarketsQueryOptions = <
  TData = Awaited<ReturnType<typeof summaryV1Markets>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof summaryV1Markets>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSummaryV1MarketsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof summaryV1Markets>>> = ({ signal }) =>
    summaryV1Markets(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof summaryV1Markets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SummaryV1MarketsQueryResult = NonNullable<Awaited<ReturnType<typeof summaryV1Markets>>>;
export type SummaryV1MarketsQueryError = ErrorType<unknown>;

/**
 * @summary Market Summary
 */
export const useSummaryV1Markets = <
  TData = Awaited<ReturnType<typeof summaryV1Markets>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof summaryV1Markets>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSummaryV1MarketsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns detailed summary for each currency available on the exchange.
 * @summary Assets Summary
 */
export const getAssetsV1Markets = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AssetsResponse[]>({ url: `/marketdata/api/v1/assets`, method: 'GET', signal }, options);
};

export const getGetAssetsV1MarketsQueryKey = () => {
  return [`/marketdata/api/v1/assets`] as const;
};

export const getGetAssetsV1MarketsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssetsV1Markets>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1Markets>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetsV1MarketsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsV1Markets>>> = ({ signal }) =>
    getAssetsV1Markets(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAssetsV1Markets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAssetsV1MarketsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsV1Markets>>>;
export type GetAssetsV1MarketsQueryError = ErrorType<unknown>;

/**
 * @summary Assets Summary
 */
export const useGetAssetsV1Markets = <
  TData = Awaited<ReturnType<typeof getAssetsV1Markets>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1Markets>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAssetsV1MarketsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get top markets data for the last 24-hrs
 * @summary Top Markets
 */
export const getTopMarketsData = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Asset24HourData[]>({ url: `/marketdata/top_markets`, method: 'GET', signal }, options);
};

export const getGetTopMarketsDataQueryKey = () => {
  return [`/marketdata/top_markets`] as const;
};

export const getGetTopMarketsDataQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopMarketsData>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopMarketsData>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopMarketsDataQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopMarketsData>>> = ({ signal }) =>
    getTopMarketsData(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopMarketsData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopMarketsDataQueryResult = NonNullable<Awaited<ReturnType<typeof getTopMarketsData>>>;
export type GetTopMarketsDataQueryError = ErrorType<unknown>;

/**
 * @summary Top Markets
 */
export const useGetTopMarketsData = <
  TData = Awaited<ReturnType<typeof getTopMarketsData>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopMarketsData>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTopMarketsDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get top markets data for the last 24-hrs
 * @summary Top Markets
 */
export const getTopMarketsDataV1 = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Asset24HourData[]>({ url: `/marketdata/api/v1/top_markets`, method: 'GET', signal }, options);
};

export const getGetTopMarketsDataV1QueryKey = () => {
  return [`/marketdata/api/v1/top_markets`] as const;
};

export const getGetTopMarketsDataV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getTopMarketsDataV1>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopMarketsDataV1>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopMarketsDataV1QueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopMarketsDataV1>>> = ({ signal }) =>
    getTopMarketsDataV1(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopMarketsDataV1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopMarketsDataV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTopMarketsDataV1>>>;
export type GetTopMarketsDataV1QueryError = ErrorType<unknown>;

/**
 * @summary Top Markets
 */
export const useGetTopMarketsDataV1 = <
  TData = Awaited<ReturnType<typeof getTopMarketsDataV1>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTopMarketsDataV1>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTopMarketsDataV1QueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all trends for a specified market(symbol) within the last 24-hrs
 * @summary Get All Trends
 */
export const getAllTrends = (
  symbol: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetAllTrends200One | GetAllTrends200Two | GetAllTrends200Three>(
    { url: `/marketdata/trends/all/${symbol}`, method: 'GET', signal },
    options
  );
};

export const getGetAllTrendsQueryKey = (symbol: string) => {
  return [`/marketdata/trends/all/${symbol}`] as const;
};

export const getGetAllTrendsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllTrends>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTrends>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTrendsQueryKey(symbol);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTrends>>> = ({ signal }) =>
    getAllTrends(symbol, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!symbol, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllTrends>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllTrendsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTrends>>>;
export type GetAllTrendsQueryError = ErrorType<unknown>;

/**
 * @summary Get All Trends
 */
export const useGetAllTrends = <TData = Awaited<ReturnType<typeof getAllTrends>>, TError = ErrorType<unknown>>(
  symbol: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTrends>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllTrendsQueryOptions(symbol, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all trends for a specified market(symbol) within the last 24-hrs
 * @summary Get All Trends
 */
export const getAllTrendsV1 = (
  symbol: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetAllTrendsV1200One | GetAllTrendsV1200Two | GetAllTrendsV1200Three>(
    { url: `/marketdata/api/v1/trends/all/${symbol}`, method: 'GET', signal },
    options
  );
};

export const getGetAllTrendsV1QueryKey = (symbol: string) => {
  return [`/marketdata/api/v1/trends/all/${symbol}`] as const;
};

export const getGetAllTrendsV1QueryOptions = <
  TData = Awaited<ReturnType<typeof getAllTrendsV1>>,
  TError = ErrorType<unknown>
>(
  symbol: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTrendsV1>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTrendsV1QueryKey(symbol);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTrendsV1>>> = ({ signal }) =>
    getAllTrendsV1(symbol, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!symbol, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllTrendsV1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllTrendsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getAllTrendsV1>>>;
export type GetAllTrendsV1QueryError = ErrorType<unknown>;

/**
 * @summary Get All Trends
 */
export const useGetAllTrendsV1 = <TData = Awaited<ReturnType<typeof getAllTrendsV1>>, TError = ErrorType<unknown>>(
  symbol: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTrendsV1>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllTrendsV1QueryOptions(symbol, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
