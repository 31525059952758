/**
 * @function getTime
 * @description This function converts milliseconds to days, hours, minutes, and seconds.
 * @param time accepts a number in millisecond
 * @returns Number of days, hours, totalHours, minutes or seconds
 * totalHours converts days into hours and adds to its hours
 */

export const getTime = (time: number) => {
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  const totalHours = days * 24 + hours;

  return { days, hours, minutes, seconds, totalHours };
};
