const TWO_DIGITS = 2 as const;

/**
 * It takes a number as input and returns a string representation of the
 * absolute value of the number, padded with leading zeros to ensure it has two digits.
 * @param {number} [value=0] - The `value` parameter is a number that will be converted to a string and
 * padded with leading zeros.
 */
export const getDuoNumber = (value = 0) => {
  return Math.abs(value).toString().padStart(TWO_DIGITS, '0');
};
