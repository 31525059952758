import { preferencesStore } from '@ping/stores/preferences.store';
import { translate } from '@ping/utils';

/**
 * It translates the text while replaces all the %s with the params passed in respectively.
 * @param {string} text - The string to be translated.
 * @param {(string | number)[]} params - (string | number)[] - this is an array of strings/numbers that will be used to replace the
 * %s in the string.
 * @external preferencesStore.getLanguageTranslations()
 *
 *
 * NOTE: remember to put "selectedLanguage" in dependency array when you're using it in the "React.useMemo" or
 *       "React.useCallback" in order to work properly as expected.
 *
 * @example
 *  const selectedLanguage = usePreferencesStore(selectedLanguageSelector)
 *  ...
 *  useMemo(
 *    () => { const alwaysUpToDateTranslation = t('text') },
 *    [ selectedLanguage ],
 *  )
 */
export const t = (text: string, ...params: (string | number)[]) => {
  const translations = preferencesStore.getSelectedLanguageTranslations();
  return translate(translations, text, ...params);
};
