export enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
  CHF = 'CHF',
  JPY = 'JPY',
}

export enum ECurrencyValue {
  USD = 'usd',
  EUR = 'eur',
  CHF = 'chf',
  JPY = 'jpy',
}
