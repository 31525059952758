import { useEffect } from 'react';

import { clsx } from 'clsx';
import { LastUserReviewResponse, UserReviewStatusType, useGetLastRequestCompliance } from '@ping/api';
import { t } from '@ping/helpers';
import {
  compliancyPendingRequestSeenStore,
  isCompliancyPendingRequestSeenSelector,
  useCompliancyPendingRequestSeenStore,
} from '@ping/stores/compliancy-pending-request-seen.store';
import {
  isThereAnyPendingRequestToNotifyUserSelector,
  lastRequestStatusSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { pendingApprovalModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import ClockIcon from '@ping/assets/Icon/clock.svg';
import { ButtonA11Y } from '@ping/uikit';

import style from './style.module.scss';

export const PendingApprovalTriggerButton = () => {
  const pendingApprovalModal = useModalStore(pendingApprovalModalRemoteStateSelector);
  const activeRequestStatus = useUserInformationStore(lastRequestStatusSelector);
  const isThereAnyPendingRequestToNotifyUser = useUserInformationStore(isThereAnyPendingRequestToNotifyUserSelector);
  const isCompliancyPendingRequestSeen = useCompliancyPendingRequestSeenStore(isCompliancyPendingRequestSeenSelector);
  const { setCompliancyPendingRequestSeen } = compliancyPendingRequestSeenStore;
  const { data: activeRequestData } = useGetLastRequestCompliance<LastUserReviewResponse>();

  useEffect(() => {
    setCompliancyPendingRequestSeen(
      activeRequestData ? Boolean(activeRequestData?.acknowledgedAt) : isCompliancyPendingRequestSeen
    );
  }, [activeRequestData?.statusType, activeRequestData?.acknowledgedAt]);

  if (
    (activeRequestStatus === UserReviewStatusType.Denied && !isCompliancyPendingRequestSeen) ||
    isThereAnyPendingRequestToNotifyUser
  ) {
    return (
      <ButtonA11Y
        className={clsx(style['pending-approval--btn'], {
          [style['is-rejected']]: activeRequestStatus === UserReviewStatusType.Denied,
          [style['is-more-data-needed']]: activeRequestStatus === UserReviewStatusType.RequestedMoreData,
          [style['is-pending-for-review']]: activeRequestStatus === UserReviewStatusType.PendingForReview,
        })}
        onPress={pendingApprovalModal.open}
      >
        <ClockIcon />
        <span className={style['pending-approval--btn-text']}>
          {activeRequestStatus === UserReviewStatusType.Denied && t('Request was rejected')}
          {activeRequestStatus === UserReviewStatusType.RequestedMoreData && t('Provide more data')}
          {activeRequestStatus !== UserReviewStatusType.Denied &&
            activeRequestStatus !== UserReviewStatusType.RequestedMoreData &&
            t('Pending for review')}
        </span>
      </ButtonA11Y>
    );
  }

  return null;
};
