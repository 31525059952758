import { AlertDescription } from './AlertDescription';
import { AlertIcon } from './AlertIcon';
import { AlertRoot } from './AlertRoot';
import { AlertTitle } from './AlertTitle';

export const Alert = Object.freeze({
  Root: AlertRoot,
  Description: AlertDescription,
  Icon: AlertIcon,
  Title: AlertTitle,
});
