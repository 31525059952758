import clsx from 'clsx';
import { useContext } from 'react';

import { ModalContext } from '../modal.context';
import style from './style.module.scss';

interface IModalTitleProps extends ICustomizable {
  children: React.ReactNode;
  headerTag?: IHeaderTag | 'div';
}

export const ModalTitle = (props: IModalTitleProps) => {
  const { headerTag: HeaderTag = 'h4', className, children, ...rest } = props;
  const ctx = useContext(ModalContext);

  return (
    <HeaderTag {...rest} {...ctx.titleProps} className={clsx(style['modal-title'], className)}>
      {children}
    </HeaderTag>
  );
};
