import clsx from 'clsx';

import style from './style.module.scss';

interface IModalActionsProps extends ICustomizable {
  children: React.ReactNode;
}

export const ModalActions = (props: IModalActionsProps) => (
  <footer className={clsx(style['modal-actions'], props.className)} data-uikit-modal-actions>
    {props.children}
  </footer>
);
