import { ActivityStatus, ComplianceStepType, getNextStepCompliance, getOrCreateProfile } from '@ping/api';
import { axiosInstance } from '@ping/api/axios-instance';
import * as configs from '@ping/configs';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { TOAST_OPTIONS } from '@ping/constants/toast-options.constant';
import { storage } from '@ping/helpers';
import { modalStore } from '@ping/stores/modal.store';
import { tokenStore } from '@ping/stores/token.store';
import { userInformationStore } from '@ping/stores/userInformation.store';
import { Toast } from '@ping/uikit';
import { simpleDevlog, isBrowser } from '@ping/utils';
import axios from 'axios';
import router from 'next/router';

import { LOGIN_CALLBACK_PATH, LOGIN_PATH, LOGOUT_REDIRECTION_CALLBACK_PATH } from './constants';

import type { ApiErrorResponse } from '@ping/api';

let authorizer = null;
isBrowser() && import('./authorizer').then(module => (authorizer = module.authorizer));

export const REFERRAL_CODE_STORAGE = 'ref-code';
export const TERMINATED_USER_ERROR_CODE = 30200;
export const FROZEN_USER_ERROR_CODE = 30201;
const NOT_KYCED_ERROR_CODE = 50002;

export const loginSideEffect = () => {
  const { pathname, search } = window.location;
  const redirectLoginTo = pathname + search;

  if (!redirectLoginTo.startsWith('/login')) {
    storage.loginRedirect.set(redirectLoginTo);

    if (search.includes(REFERRAL_CODE_STORAGE)) {
      storage.referralCode.set(new URLSearchParams(search).get(REFERRAL_CODE_STORAGE));
    }
  }

  authorizer.makeAuthorizationRequest();
};

export const logInCallbackSideEffect = async () => {
  if (authorizer) {
    await authorizer.signinCallback();
    let redirectLoginTo = storage.loginRedirect.pop() || '/';

    const accessToken = tokenStore.getAccessToken();
    if (accessToken) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      simpleDevlog('Authorization header set', accessToken);
      let redirectTimeOut = 1_000;

      try {
        /* this call is needed before doing anything else on Ping project for logged-in users */
        await handleProfile();
        // await loadUserSideEffect();

        if (
          !userInformationStore.isUserVerified() ||
          userInformationStore.getComplianceStep() === ComplianceStepType.FormA ||
          userInformationStore.getUserActivityStatus() === ActivityStatus.WaitingForReview ||
          userInformationStore.getUserActivityStatus() === ActivityStatus.WaitingForData ||
          userInformationStore.getUserActivityStatus() === ActivityStatus.Banned
        ) {
          redirectLoginTo = '/user/portfolio';
          redirectTimeOut = 0;
        }

        if (userInformationStore.isUserSupport()) {
          redirectLoginTo = '/markets';
          redirectTimeOut = 0;
        }

        if (!storage.isImportantNoticeModalSeen.coerceGet(true)) {
          modalStore.getRemoteState(modalKeys.IMPORTANT_NOTICE).open();
          storage.isImportantNoticeModalSeen.set(false);
        }
      } catch (error: any) {
        if (error?.response?.data) {
          Toast.error({ title: error?.response?.data as ApiErrorResponse });
          redirectTimeOut = TOAST_OPTIONS.autoClose || 5_000;
        }
      }

      setTimeout(() => router.push(redirectLoginTo), redirectTimeOut);
    }
  } else {
    setTimeout(logInCallbackSideEffect, 400);
  }
};

export const handleProfile = async () => {
  try {
    const profile = await getOrCreateProfile({
      referralCode: storage.referralCode.get(),
    });
    const nextStep = await getNextStepCompliance();

    if (profile.verificationLevel >= 1 && !profile.verificationIsInProgress) {
      storage.referralCode.remove();
    }

    userInformationStore.setUserInformation(profile);
    userInformationStore.setComplianceStep(nextStep);
  } catch ({ response }) {
    if (response?.data?.errorCode === NOT_KYCED_ERROR_CODE) {
      userInformationStore.setUserInformation({
        verificationError: response?.data?.errors[0].message,
      });
    } else if (response?.data?.errorCode === TERMINATED_USER_ERROR_CODE) {
      userInformationStore.setUserInformation({
        isTerminated: true,
      });
    } else if (response?.data?.errorCode === FROZEN_USER_ERROR_CODE) {
      userInformationStore.setUserInformation({
        isFrozen: true,
      });
    } else {
      throw { response };
    }
  }
};

export const logOutSideEffect = async () => {
  const formData = new FormData();
  formData.append('token', tokenStore.getAccessToken());
  formData.append('client_id', configs.HYDRA_CLIENT);

  try {
    axios.post(`${configs.HYDRA_DOMAIN}/oauth2/revoke`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error) {
    console.error('Error:', error.message);
  }
};

export const logOutCallbackSideEffect = async () => {
  await authorizer.signoutCallback();
};

export const useHandleLocationChangedSideEffect = async () => {
  const { pathname } = window.location;

  const handleAsyncAwait = async () => {
    if (pathname === LOGIN_CALLBACK_PATH) {
      await logInCallbackSideEffect();
    }

    if (pathname === LOGIN_PATH) {
      // await throttledLogin(dispatch)
    }

    if (pathname === LOGOUT_REDIRECTION_CALLBACK_PATH) {
      // await throttledLogoutCallback(dispatch)
    }
  };
  await handleAsyncAwait();
};
