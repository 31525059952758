import { useEffect, useState } from 'react';

import { getDiffTimeFromNow, getTime, DateTime } from '@ping/utils';

/**
 * @method useCountUp
 * @description Hook to count-up from the provided number.
 * @param targetDate accepts a number in milliseconds
 */
const useCountUp = (targetDate: number) => {
  const [countUp, setCountUp] = useState(getDiffTimeFromNow(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setCountUp(getDiffTimeFromNow(targetDate));
    }, DateTime.seconds(1));
    return () => clearInterval(interval);
  }, [targetDate]);

  // Get the time in days, hours, minutes, etc.
  return getTime(countUp);
};

export { useCountUp };
