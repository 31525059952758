import clsx from 'clsx';

import style from './style.module.scss';

interface IModalContentProps extends ICustomizable {
  children: React.ReactNode;
}

export const ModalContent = (props: IModalContentProps) => {
  const { children, className, ...rest } = props;

  return (
    <article {...rest} className={clsx(style['modal-content'], className)}>
      {children}
    </article>
  );
};
