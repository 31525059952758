import { createStorage } from '@ping/utils/create-storage.util';

import type ITradingView from '@public/static/charting_library/charting_library';

interface ITradingChartStorageContent {
  state: object;
  interval: ITradingView.ResolutionString;
  timeframe: ITradingView.TimeFramePeriodBack;
}

export const storage = {
  tradingChart: createStorage<ITradingChartStorageContent>(() => sessionStorage, 'TRADING_CHART_STORAGE'),
  loginRedirect: createStorage<string>(() => localStorage, 'LOGIN_REDIRECT_STORAGE'),
  referralCode: createStorage<string>(() => localStorage, 'REFERRAL_CODE_STORAGE'),
  isImportantNoticeModalSeen: createStorage<boolean>(() => localStorage, 'IS_IMPORTANT_NOTICE_MODAL_SEEN'),
} as const;
