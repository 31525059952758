import TerminatedUserBanner from './TerminatedUserBanner';
import FrozenUserBanner from './FrozenUserBanner';
import ClaimRewardsBanner from './ClaimRewardsBanner';
import MaintenanceBanner from './MaintenanceBanner';

export const BannersContainer = () => (
  <>
    <MaintenanceBanner />
    <TerminatedUserBanner />
    <FrozenUserBanner />
    <ClaimRewardsBanner />
  </>
);
