import { BASE_URL } from '@ping/configs';
import { tokenStore } from '@ping/stores/token.store';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: BASE_URL,
  headers: { contentType: 'application/json' },
}); // use your own URL here or environment variable

let errorHandler: (error: AxiosError) => void = null;

export const setErrorHandler = (handler: (error: AxiosError) => void) => {
  errorHandler = handler;
};

export const setBaseURL = (baseURL: string) => {
  AXIOS_INSTANCE.defaults.baseURL = baseURL;
};

export const setAuthToken = (token: string) => {
  AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  AXIOS_INSTANCE.defaults.headers.common.Authorization = undefined;
};

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();

  const accessToken = tokenStore.getAccessToken();
  if (accessToken) {
    AXIOS_INSTANCE.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token, ...options })
    .then(({ data }) => data)
    .catch(e => {
      if (errorHandler) {
        errorHandler(e);
      }
      throw e;
    });
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
