import { nop } from '@ping/utils';
import { createContext } from 'react';

import { useInitModal } from './modal.utils';

export interface IModalContext extends ReturnType<typeof useInitModal> {
  onOverlayPress?: () => void;
  onCloseButtonPress?: () => void;
}

export const ModalContext = createContext<IModalContext>({
  state: undefined,
  modalRef: undefined,
  titleProps: {},
  triggerProps: {},
  modalProps: {},
  onOverlayPress: nop,
  onCloseButtonPress: nop,
});
