import clsx from 'clsx';
import { Text } from '@ping/uikit';
import WarningIcon from '@ping/assets/Icon/warning.svg';
import { useRemoteModal } from '@ping/hooks';

export type IBannerType = 'success' | 'warning' | 'error';

import style from './style.module.scss';

interface IBannerMessage {
  bannerType?: IBannerType;
  message: string;
  extraMessage?: string;
  showIcon?: boolean;
  messageClassName?: string;
  remoteModal?: ReturnType<typeof useRemoteModal>;
  onClick?: () => void;
}

const BannerMessage = ({
  bannerType = 'error',
  message,
  remoteModal,
  showIcon = true,
  extraMessage = null,
  messageClassName = '',
  onClick,
}: IBannerMessage) => {
  const handleBannerClick = () => {
    onClick?.();
    remoteModal?.state.open();
  };

  return (
    <div
      onClick={handleBannerClick}
      className={clsx(
        style['banner-message'],
        {
          [style['banner-message__error']]: bannerType === 'error',
        },
        {
          [style['banner-message__succuss']]: bannerType === 'success',
        },
        {
          [style['banner-message__warning']]: bannerType === 'warning',
        },
        messageClassName
      )}
    >
      {showIcon && <WarningIcon />}
      <Text caption='regular'>{message}</Text>
      {extraMessage && (
        <>
          &nbsp;
          <Text caption='semi-bold'>{extraMessage}</Text>
        </>
      )}
    </div>
  );
};

export default BannerMessage;
