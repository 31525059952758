import clsx from 'clsx';

import { useAlertContext } from '../alert.context';
import style from './style.module.scss';

interface IAlertTitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const AlertTitle = (props: IAlertTitleProps) => {
  const ctx = useAlertContext();

  return (
    <h5
      className={clsx(style['alert-title'], props.className)}
      data-variant={ctx.variant}
      id={ctx.titleId}
      aria-details={ctx.descriptionId}
    >
      {props.children}
    </h5>
  );
};
