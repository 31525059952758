import Big, { Comparison } from 'big.js';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useLayoutEffect } from 'react';

import '@ping/assets/css/style.css';
import '@ping/assets/scss/style.scss';

import { setInterceptors } from '@ping/api/interceptors';
import colors from '@ping/assets/scss/theme/exportVariables.module.scss';
import { useHandleLocationChangedSideEffect } from '@ping/authorization/side-effects';
import { BannersContainer, Layout } from '@ping/components';
import { ENVIRONMENT } from '@ping/configs';
import { TOAST_OPTIONS } from '@ping/constants/toast-options.constant';
import { useForceUpdate } from '@ping/hooks';
import Providers from '@ping/providers';
import { checkLanguageChange } from '@ping/stores/preferences.effects';
import { preferencesStore } from '@ping/stores/preferences.store';
import {
  forceRedirectFormAUsers,
  forceRedirectNonKycUser,
  forceRedirectSupportUsers,
  forceRedirectUserActivityWaitingForReview,
} from '@ping/stores/userInformation.effects';
import { Toast } from '@ping/uikit';
import initialConfigs from './_base.utils';

import type { AppProps } from 'next/app';

const PingExchangeApp = ({ Component, pageProps }: AppProps) => {
  const forceUpdate = useForceUpdate();

  /* this run synchronously before useEffect and we want interceptor
  to run before everything else and once */
  useLayoutEffect(() => {
    setInterceptors();
  }, []);

  useEffect(() => {
    initialConfigs();
    useHandleLocationChangedSideEffect().then();
    // TODO: Change it back to get user setting and everything when we want to support light theme
    // applyUserSystemPreferenceTheme()
    preferencesStore.setDefaultTheme();
    const languageChangeSubscription = checkLanguageChange(forceUpdate);
    const forceRedirectNonKycUserSubscription = forceRedirectNonKycUser();
    const forceRedirectFormAUsersSubscription = forceRedirectFormAUsers();
    const forceRedirectUserActivityPendingSubscription = forceRedirectUserActivityWaitingForReview();
    const forceRedirectSupportUsersSubscription = forceRedirectSupportUsers();

    return () => {
      languageChangeSubscription.unsubscribe();
      forceRedirectNonKycUserSubscription.unsubscribe();
      forceRedirectFormAUsersSubscription.unsubscribe();
      forceRedirectUserActivityPendingSubscription.unsubscribe();
      forceRedirectSupportUsersSubscription.unsubscribe();
    };
  }, []);

  return (
    <Providers {...pageProps}>
      {ENVIRONMENT === 'production' && (
        <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KDV9V2G3');
`,
            }}
          />
        </Head>
      )}
      <NextNProgress color={colors.Primary} options={{ showSpinner: false }} />
      <Toast {...TOAST_OPTIONS} />
      <BannersContainer />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Providers>
  );
};

/* render dev also in CSR */
export default dynamic(() => Promise.resolve(PingExchangeApp), {
  ssr: false,
});

// TODO: DO NOT USE THIS FEATURE UNTIL IT'S FINALIZED
//! NOTE: DO NOT MUTATE PROTOTYPE
// --------------------------------------------------------------------------------
// POLYFILL NUMBER
// --------------------------------------------------------------------------------
Number.prototype.plus = function (x) {
  return Big(this).plus(x).toFixed();
};

Number.prototype.minus = function (x) {
  return Big(this).minus(x).toFixed();
};

Number.prototype.times = function (x) {
  return Big(this).times(x).toFixed();
};

Number.prototype.divide = function (x) {
  return Big(this).div(x).toFixed();
};

Number.prototype.module = function (x) {
  return Big(this).mod(x).toFixed();
};

Number.prototype.power = function (exponent) {
  return Big(this).pow(exponent).toFixed();
};

Number.prototype.absolute = function () {
  return Big(this).abs().toFixed();
};

Number.prototype.compare = function (x) {
  return Big(this).cmp(x);
};

Number.prototype.isEqual = function (x) {
  return Big(this).eq(x);
};

Number.prototype.isGreaterThan = function (x) {
  return Big(this).gt(x);
};

Number.prototype.isGreaterThanEqual = function (x) {
  return Big(this).gte(x);
};

Number.prototype.isLessThan = function (x) {
  return Big(this).lt(x);
};

Number.prototype.isLessThanEqual = function (x) {
  return Big(this).lte(x);
};

// TODO
Number.prototype.toPercentage = function () {
  return `${(Math.round(this * 100) * 0.01).toFixed(2)}%`;
};

// TODO
Number.prototype.toScale = function (_scale: number) {
  console.log('🚀 prototype ~ toScale:', this);
  return this;
};

Number.prototype.toNeat = function () {
  return Big(this).toFixed();
};

String.prototype.padStartRemove = function (fillString: string) {
  let result: string = this.padStart(this.length, fillString);

  while (result.startsWith(fillString)) {
    result = this.slice(fillString.length);
  }

  return result;
};

String.prototype.padEndRemove = function (fillString: string) {
  let result: string = this.padEnd(this.length, fillString);

  while (result.endsWith(fillString)) {
    result = this.slice(0, -fillString.length);
  }

  return result;
};

declare global {
  interface Number {
    plus(x: number | string): string;
    minus(x: number | string): string;
    times(x: number | string): string;
    divide(x: number | string): string;
    module(x: number | string): string;
    power(exponent: number): string;
    absolute(): string;
    compare(x: number | string): Comparison;
    isEqual(x: number | string): boolean;
    isGreaterThan(x: number | string): boolean;
    isGreaterThanEqual(x: number | string): boolean;
    isLessThan(x: number | string): boolean;
    isLessThanEqual(x: number | string): boolean;
    toScale(scale: number): string;
    toPercentage(): string;
    toNeat(): string;
  }

  // TODO: it's better to make it helper to be used for specific scenarios
  interface String {
    /**
     * Removes the specified fill string from the start of the string until it no longer starts with the fill string.
     *
     * @param {string} fillString - The string to remove from the start of the string.
     * @return {string} The modified string with the fill string removed from the start.
     */
    padStartRemove(fillString: string): string;
    /**
     * Removes the specified fill string from the end of the string until it no longer ends with the fill string.
     *
     * @param {string} fillString - The string to remove from the end of the string.
     * @return {string} The modified string with the fill string removed from the end.
     */
    padEndRemove(fillString: string): string;
  }
}
