import React from 'react';

interface IAlertContext {
  variant: AlertVariant;
  titleId: string;
  descriptionId: string;
}

export const AlertContext = React.createContext<IAlertContext>({
  variant: undefined,
  titleId: undefined,
  descriptionId: undefined,
});

export const useAlertContext = () => React.useContext(AlertContext);
