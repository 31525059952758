import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import { LOGIN_CALLBACK_PATH } from '@ping/authorization/constants';

import style from './style.module.scss';

type TNavbarLinkItem = {
  href?: string;
  title: string;
  className: string;
  disabled?: boolean;
};

interface INavbarLinkProps {
  options: TNavbarLinkItem[];
}

const NavbarLink = ({ options }: INavbarLinkProps) => {
  const { pathname } = useRouter();
  const isCallbackPath = pathname === LOGIN_CALLBACK_PATH;

  return (
    <>
      {options.map((item, index) =>
        isCallbackPath ? (
          <button key={index + item.title} className={style['header__link-btn']} disabled={isCallbackPath}>
            {item.title}
          </button>
        ) : !item.disabled ? (
          <Link href={item.href} key={index + item.title}>
            <a className={clsx({ [style['header__link-active']]: pathname === item.href }, item.className)}>
              {item.title}
            </a>
          </Link>
        ) : (
          <span key={index + item.title} className={clsx(style['header__link_disabled'], item.className)}>
            {item.title}
          </span>
        )
      )}
    </>
  );
};

export default NavbarLink;
