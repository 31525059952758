import clsx from 'clsx';
import { useId } from 'react';

import { AlertContext } from '../alert.context';
import style from './style.module.scss';

interface IAlertRootProps extends ICustomizable {
  variant: AlertVariant;
  children: React.ReactNode;
}

export const AlertRoot = (props: IAlertRootProps) => {
  const titleId = useId();
  const descriptionId = useId();

  return (
    <section className={clsx(style['alert-root'], props.className)} role='region' aria-labelledby={titleId}>
      <AlertContext.Provider value={{ variant: props.variant, titleId, descriptionId }}>
        {props.children}
      </AlertContext.Provider>
    </section>
  );
};
