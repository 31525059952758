import { isUserAccountTerminatedSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import Banner from './Banner';

const TerminatedUserBanner = () => {
  const isTerminatedUser = useUserInformationStore(isUserAccountTerminatedSelector);

  return <Banner show={isTerminatedUser} message='Unfortunately, your account has been terminated.' />;
};

export default TerminatedUserBanner;
