import { isUserAccountFrozenSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import Banner from './Banner';

const FrozenUserBanner = () => {
  const isFrozenUser = useUserInformationStore(isUserAccountFrozenSelector);

  return <Banner show={isFrozenUser} message='Unfortunately, your account has been frozen.' />;
};

export default FrozenUserBanner;
