import {
  getGetPortfolioQueryKey,
  getUserSettingsGetQueryKey,
  GetUserSettingResponseResponse,
  useUserSettingsUpdate,
} from '@ping/api';
import { queryClient } from '@ping/providers/ReactQueryProvider';

export const useUpdateCurrencySetting = (options: Parameters<typeof useUserSettingsUpdate>[0]['mutation'] = {}) => {
  const { mutate: updateSettings, ...rest } = useUserSettingsUpdate();

  const updateSettingAPI = (value, onSuccessCallBack?: () => void) => {
    updateSettings(
      {
        data: {
          type: 'current-currency',
          value,
        },
      },
      {
        onSuccess: () => {
          queryClient.setQueryData<GetUserSettingResponseResponse>(getUserSettingsGetQueryKey(), oldSetting => ({
            data: {
              ...oldSetting.data,
              currentCurrency: value,
            },
          }));
          queryClient.invalidateQueries(getGetPortfolioQueryKey());
          onSuccessCallBack();
        },
        ...options,
      }
    );
  };

  return { updateSettingAPI, ...rest };
};
