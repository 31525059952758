import NextQueryParamProvider from './NextQueryParamProvider';
import ReactAriaOverlayProvider from './ReactAriaOverlayProvider';
import ReactQueryProvider from './ReactQueryProvider';

const ModalProvider = dynamic(() => import('./ModalProvider').then(module => module.ModalProvider), { ssr: false });

import type { IReactQueryProviderProps } from './ReactQueryProvider';
import dynamic from 'next/dynamic';
import { ErrorBoundary } from './ErrorBoundary';

export interface IProvidersProps extends IReactQueryProviderProps {
  children: React.ReactNode;
}

const Providers = ({ children, dehydratedState }: IProvidersProps) => (
  <ErrorBoundary>
    <ReactQueryProvider dehydratedState={dehydratedState}>
      <NextQueryParamProvider>
        <ReactAriaOverlayProvider>
          <ModalProvider>{children}</ModalProvider>
        </ReactAriaOverlayProvider>
      </NextQueryParamProvider>
    </ReactQueryProvider>
  </ErrorBoundary>
);

export default Providers;
