import colors from '@ping/assets/scss/theme/exportVariables.module.scss';
import { Spinner } from '@ping/uikit';
import { SpinnerType } from '@ping/uikit/Spinner';
import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

import style from './style.module.scss';

export interface IButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size' | 'type'> {
  type?: 'primary' | 'secondary';
  size?: 'large' | 'small' | 'extra-large';
  design?: 'general' | IOrderSide | 'gray';
  disabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  uppercase?: boolean;
  isLoading?: boolean;
  spinnerType?: SpinnerType;
}

export const Button = ({
  type = 'primary',
  size = 'small',
  design = 'general',
  children,
  disabled = false,
  className = '',
  htmlType = 'button',
  uppercase = false,
  isLoading = false,
  spinnerType = 'beatLoader',
  ...rest
}: IButtonProps) => {
  const classname = clsx(style['component_btn'], {
    [style[`type_${type}`]]: type,
    [style[`size_${size}`]]: size,
    [style[`design_${design}`]]: design,
    [className]: className,
    [style['disabled']]: disabled,
    [style['uppercase']]: uppercase,
  });
  return (
    <button type={htmlType} className={classname} disabled={disabled} {...rest}>
      {!isLoading ? children : <Spinner color={colors.Gray300} size={8} spinnerType={spinnerType} />}
    </button>
  );
};
