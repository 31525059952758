import Banner from '@ping/components/BannersContainer/Banner';
import { AnnouncementResponse, AnnouncementType, useGetAllAnnouncements } from '@ping/api';

const checkBannerTimeValidity = (banner: AnnouncementResponse) => {
  const { releasesAt, expiresAt } = banner;
  const currentDate = new Date();
  const startDate = new Date(releasesAt);
  const endDate = new Date(expiresAt);

  return currentDate > startDate && currentDate < endDate;
};

const MaintenanceBanner = () => {
  const { data: announcement } = useGetAllAnnouncements();
  const bannerAnnouncement = announcement?.find(item => item.type === AnnouncementType.Banner);

  if (!bannerAnnouncement || !checkBannerTimeValidity(bannerAnnouncement)) return null;

  const handleBannerClick = () => {
    window.open(bannerAnnouncement.link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Banner
      show
      message={bannerAnnouncement.description}
      /* ToDo: keep it warning for now till beck-end return appropriate property */
      bannerType='warning'
      /* ToDo: we will no longer have modal for banners */
      onClick={handleBannerClick}
    />
  );
};

export default MaintenanceBanner;
