import { OverlayProvider } from 'react-aria';

interface IReactAriaOverlayProviderProps {
  children: React.ReactNode;
}

const ReactAriaOverlayProvider = ({ children }: IReactAriaOverlayProviderProps) => (
  <OverlayProvider>{children}</OverlayProvider>
);

export default ReactAriaOverlayProvider;
