import { Store } from '@ping/utils';

interface IClaimRewardsModalStore {
  isShown: boolean;
}

// --------------------------------------------------------------------------------
// CLAIM REWARDS MODAL STORE
// --------------------------------------------------------------------------------
export const useClaimRewardsModalStore = new Store<IClaimRewardsModalStore>({ isShown: true })
  .withPersist({ name: 'CLAIM_REWARDS_MODAL_STORE' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// CLAIM REWARDS MODAL SELECTORS
// --------------------------------------------------------------------------------
export const canShowClaimRewardsModalSelector = (state: IClaimRewardsModalStore) => state.isShown;

// --------------------------------------------------------------------------------
// CLAIM REWARDS MODAL API
// --------------------------------------------------------------------------------
export const claimRewardsModalStore = {
  setClaimRewardsModal: (isShownValue: boolean) => useClaimRewardsModalStore.setState({ isShown: isShownValue }),
};
