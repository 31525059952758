import {
  isUserAtFormAStepSelector,
  isUserActivityStatusBannedSelector,
  isUserWaitingOrPendingForReviewSelector,
  useUserInformationStore,
  verificationLevelSelector,
  isUserSupportSelector,
} from '@ping/stores/userInformation.store';
import { isNil } from '@ping/utils';
import router from 'next/router';

/**
 * It redirects non-KYC users to the `/user/portfolio` page
 */
export const forceRedirectNonKycUser = () => {
  return useUserInformationStore.sliceSubscribe(verificationLevelSelector, verificationLevel => {
    if (!isNil(verificationLevel) && !verificationLevel) {
      router.push('/user/portfolio');
    }
  });
};

/**
 * It redirects the users who need to sign FormA to the `/user/portfolio` page
 */
export const forceRedirectFormAUsers = () => {
  return useUserInformationStore.sliceSubscribe(isUserAtFormAStepSelector, isUserAtFormAStep => {
    if (isUserAtFormAStep) {
      router.push('/user/portfolio');
    }
  });
};

/**
 * It redirects the users who need to wait for the review to the `/user/portfolio` page
 */
export const forceRedirectUserActivityWaitingForReview = () => {
  return useUserInformationStore.sliceSubscribe(
    isUserWaitingOrPendingForReviewSelector,
    isUserWaitingOrPendingForReview => {
      if (isUserWaitingOrPendingForReview) {
        router.push('/user/portfolio');
      }
    }
  );
};

/**
 * It redirects the banned users to the `/user/portfolio` page
 */
export const forceRedirectBannedUsers = () => {
  return useUserInformationStore.sliceSubscribe(isUserActivityStatusBannedSelector, isUserBanned => {
    if (isUserBanned) {
      router.push('/user/portfolio');
    }
  });
};

/**
 * It redirects the support users to the `/markets` page
 */
export const forceRedirectSupportUsers = () => {
  return useUserInformationStore.sliceSubscribe(isUserSupportSelector, isUserSupport => {
    if (isUserSupport) {
      router.push('/markets');
    }
  });
};
