type LanguageList = { label: string; value: string; active: boolean };
export const LANGUAGE_LIST: LanguageList[] = [
  {
    label: 'English',
    value: 'en-us',
    active: true,
  },
  {
    label: 'German',
    value: 'de-de',
    active: false,
  },
  {
    label: 'فارسی',
    value: 'fa-ir',
    active: false,
  },
  {
    label: 'Français',
    value: 'fr-fr',
    active: false,
  },
  {
    label: '日本語',
    value: 'ja-jp',
    active: false,
  },
  {
    label: '한국어',
    value: 'ko-kr',
    active: false,
  },
  {
    label: 'Russian',
    value: 'ru-ru',
    active: false,
  },
  {
    label: 'Spanish',
    value: 'es-es',
    active: false,
  },
];
