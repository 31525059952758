import { isBrowser } from '@ping/utils';
import { useState } from 'react';

import { useClientEffect } from './use-client-effect.hook';
import { useDebounce } from './useDebounce';

export enum Breakpoint {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
}

const findBreakpoint = () =>
  [Breakpoint.XS, Breakpoint.SM, Breakpoint.MD, Breakpoint.LG, Breakpoint.XL, Breakpoint.XXL].reduce(
    (previous, current) => {
      if (isBrowser() && current > window.innerWidth) {
        return previous;
      }
      return Math.max(current, previous);
    },
    Breakpoint.XS
  );

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(findBreakpoint());
  const handleResize = useDebounce(() => setBreakpoint(findBreakpoint()), 200);

  useClientEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return breakpoint;
};

export const useBreakpointUp = (minSize: Breakpoint) => {
  const breakpoint = useBreakpoint();
  return minSize <= breakpoint;
};

export const useBreakpointDown = (maxSize: Breakpoint) => {
  const breakpoint = useBreakpoint();
  return maxSize > breakpoint;
};

export const useBreakpointBetween = (minSize: Breakpoint, maxSize: Breakpoint) => {
  const breakpoint = useBreakpoint();
  return minSize <= breakpoint && maxSize > breakpoint;
};

export const useMobile = () => useBreakpointDown(Breakpoint.SM);
export const useTabletDevice = () => useBreakpointBetween(Breakpoint.SM, Breakpoint.LG);
export const useDesktopDevice = () => useBreakpointUp(Breakpoint.LG);
