import { useEffect, useState } from 'react';

import { getGetPortfolioQueryKey, useClaimReward, useGetRewards } from '@ping/api';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useIsUserLoggedIn, useRemoteModal } from '@ping/hooks';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import {
  canShowClaimRewardsModalSelector,
  claimRewardsModalStore,
  useClaimRewardsModalStore,
} from '@ping/stores/claim-rewards-modal.store';
import { claimRewardsModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import {
  isUserActivityStatusActiveSelector,
  isUserComplianceReviewerSelector,
  isUserSupportSelector,
  isUserTradeDataViewerSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { ButtonA11Y, Spinner, Toast } from '@ping/uikit';
import ClaimRewardModalContent from './ClaimRewardModalContent';

import Banner from '../Banner';

import style from './style.module.scss';

const ClaimRewardsBanner = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const isUserComplianceOfficer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);
  const isUserSupport = useUserInformationStore(isUserSupportSelector);
  const [showClaimRewardsBanner, setShowClaimRewardsBanner] = useState(false);
  const {
    data: rewardsData,
    isLoading: isRewardsLoading,
    refetch: handleRefetchRewardsApi,
  } = useGetRewards({
    query: {
      enabled:
        isUserLoggedIn &&
        isUserActivityStatusActive &&
        !isUserComplianceOfficer &&
        !isUserTradeDataViewer &&
        !isUserSupport,
    },
  });
  const isShowClaimRewardsBanner = isUserActivityStatusActive && rewardsData && rewardsData?.length !== 0;
  const modal = useRemoteModal(modalKeys.CLAIM_REWARDS);
  // Right now, we can only use the first reward. The endpoint will be updated with more rewards in the future
  const getFirstReward = rewardsData?.[0];

  const claimRewardsModalRemoteState = useModalStore(claimRewardsModalRemoteStateSelector);
  const canShowClaimRewardsModal = useClaimRewardsModalStore(canShowClaimRewardsModalSelector);
  const { setClaimRewardsModal } = claimRewardsModalStore;

  const { mutate: claimRewardMutate, isLoading: isClaimRewardLoading } = useClaimReward();

  const handleClaimReward = () => {
    claimRewardMutate(
      { data: { rewardId: getFirstReward.id } },
      {
        onSuccess: () => {
          modal.state.close();
          queryClient.invalidateQueries(getGetPortfolioQueryKey());
          handleRefetchRewardsApi();
          Toast.success({
            title: t('Congratulations!'),
            text: t(
              `${getFirstReward?.amount} ${getFirstReward?.assetId.toUpperCase()} were deposited into your Portfolio`
            ),
            options: {
              closeButton: false,
            },
          });
        },
      }
    );
  };

  useEffect(() => {
    setShowClaimRewardsBanner(isShowClaimRewardsBanner);
    if (isShowClaimRewardsBanner && canShowClaimRewardsModal) {
      setClaimRewardsModal(false);
      claimRewardsModalRemoteState.open();
    }
  }, [isUserActivityStatusActive, rewardsData]);

  return (
    <Banner
      show={showClaimRewardsBanner}
      headerTag='h4'
      message={t('Claim your Core Tokens!')}
      extraMessage={t('Learn more')}
      modalTitle={t('Claim your Core Tokens')}
      ModalContentChildren={<ClaimRewardModalContent />}
      remoteModal={modal}
      ModalActionsChildren={
        <ButtonA11Y
          className={style['banner__modal-trigger']}
          onPress={() => handleClaimReward()}
          isDisabled={isClaimRewardLoading || isRewardsLoading}
        >
          {isRewardsLoading || isClaimRewardLoading ? (
            <Spinner size={8} spinnerType='beatLoader' />
          ) : (
            t('Claim Rewards')
          )}
        </ButtonA11Y>
      }
      messageClassName={style['banner__message']}
      modalPortalClassName={style['banner__modal-portal']}
      showIcon={false}
      bannerType='success'
    />
  );
};

export default ClaimRewardsBanner;
