import { useUserSettingsGet } from '@ping/api';
import { DEFAULT_CURRENCY } from '@ping/configs';
import { ECurrency } from '@ping/enums';
import { useIsUserLoggedIn, useUpdateCurrencySetting } from '@ping/hooks';
import { selectDefaultFiatCurrenciesSelector, usePreferencesStore } from '@ping/stores/preferences.store';
import { Toast } from '@ping/uikit';

export const useSelectedCurrency = (): ECurrency => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const selectedFiatCurrency = usePreferencesStore(selectDefaultFiatCurrenciesSelector)?.toUpperCase();
  const { isLoading: isMutationInProgress, updateSettingAPI } = useUpdateCurrencySetting();

  const { data: userSettingsData } = useUserSettingsGet({
    query: {
      enabled: isUserLoggedIn,
      staleTime: Infinity,
    },
  });
  const userCurrency = userSettingsData?.data?.currentCurrency?.toUpperCase();

  if (
    !isMutationInProgress &&
    isUserLoggedIn &&
    selectedFiatCurrency &&
    userCurrency &&
    userCurrency !== selectedFiatCurrency
  ) {
    updateSettingAPI(selectedFiatCurrency.toLowerCase(), () => Toast.success({ title: 'The default currency synced' }));
  }

  return ECurrency[userCurrency] || selectedFiatCurrency || DEFAULT_CURRENCY;
};
