/**
 * It logs messages to the console in development mode, with an optional hex color parameter.
 * @param {string} ns - The `ns` parameter is a string that represents the namespace or module name for
 * which the log message is being generated. It is used to identify the source of the log message.
 * @param [options] - The `options` parameter is an optional object that can contain the following
 * property:
 * - hexColor: The color of the log message in hex format.
 */
import { IS_DEVELOPMENT_MODE } from '@ping/configs';

export const devlog =
  (ns: string, options?: { hexColor?: string }) =>
  (message: string, ...arg: any[]) => {
    if (IS_DEVELOPMENT_MODE) {
      console.log(`\n%c [${ns.toUpperCase()}] ${message}`, `color: ${options.hexColor}`, ...arg);
    }
  };

export const simpleDevlog = (message: string, ...arg: any[]) => {
  if (IS_DEVELOPMENT_MODE) {
    console.log(message, ...arg);
  }
};
