import { useRef } from 'react';
import { mergeProps, useDialog, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { useTransition } from 'transition-hook';

import { DateTime } from '@ping/utils';

import type IReactStately from 'react-stately';

const LONGEST_MODAL_ANIMATION_DURATION = DateTime.milliseconds(480); // NOTE: check the modal scss file

type IInitModalParams = IReactStately.OverlayTriggerProps;

/**
 * It initializes a modal state and props for a Modal component.
 * @param {IInitModalParams} params - params is an optional object parameter that can be passed to the
 * useInitModal hook. It can contain any additional props or options that need to be passed to the
 * underlying hooks used in the implementation of the modal. If no params are passed, an empty object
 * is used as the default value.
 */
export const useInitModal = (params: IInitModalParams = {}) => {
  //
  // Initialize modal state
  const modalState = useOverlayTriggerState(params);
  const transition = useTransition(modalState.isOpen, LONGEST_MODAL_ANIMATION_DURATION);
  const state = { ...modalState, isOpen: transition.shouldMount, stage: transition.stage };
  //
  // Initialize modal trigger props
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);
  //
  // Initialize modal title props
  const modalRef = useRef<HTMLDivElement>();
  const { dialogProps, titleProps } = useDialog({ role: 'dialog' }, modalRef);

  return {
    state,
    modalRef,
    triggerProps,
    modalProps: mergeProps(overlayProps, dialogProps),
    titleProps,
  };
};
