import { devlog } from './devlog.util';
import { isNil } from './is-nil.util';

const log = {
  storage: {
    in: devlog('storage<-', { hexColor: '#F38744' }),
    out: devlog('storage->', { hexColor: '#EF6820' }),
  },
} as const;

/**
 * It creates a storage object that can be used to store and retrieve values from a given storage
 * @param getStorage - () => Storage - a function that returns a Storage object.
 * @param {string} key - The key to store the value under.
 * @returns A function that takes a value and returns a function that takes a value and returns a
 * function that takes a value and returns a value.
 */
export const createStorage = <TValue>(getStorage: () => Storage, key: string) => {
  let storage: Storage;

  try {
    storage = getStorage();
  } catch (_) {
    return;
  }

  const get = (): TValue | undefined => {
    const value = JSON.parse(storage.getItem(key));
    log.storage.out(key, value);
    return value;
  };

  const coerceGet = (fallbackValue?: TValue): TValue => {
    const current = get();
    const value = current ?? fallbackValue;

    if (isNil(current)) {
      log.storage.out(key, `fallback hit ${value}`);
    }

    return value;
  };

  const set = (value: TValue) => {
    log.storage.in(key, value);
    return storage.setItem(key, JSON.stringify(value));
  };

  const remove = () => {
    storage.removeItem(key);
    log.storage.out(key, 'removed');
  };

  const update = (value: Partial<TValue extends object ? TValue : never>) => {
    set({ ...get(), ...value });
  };

  const pop = (): TValue => {
    const value = get();
    remove();
    return value;
  };

  return {
    get,
    coerceGet,
    set,
    remove,
    update,
    pop,
  };
};
