import { selectedLanguageSelector, usePreferencesStore } from './preferences.store';

/**
 * It returns a function that, when called, unsubscribes the callback from the language slice of the
 * preferences store
 * @param callback - () => void - this is the function that will be called when the selected language changes.
 */
export const checkLanguageChange = (callback: () => void) => {
  return usePreferencesStore.sliceSubscribe(selectedLanguageSelector, callback);
};
