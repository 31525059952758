import { ECurrencyValue } from '@ping/enums';

import type { ICoinValue } from '@ping/uikit';

export const DEFAULT_CURRENCIES: ICoinValue<ECurrencyValue>[] = [
  {
    value: ECurrencyValue.USD,
    view: <span>USD</span>,
    symbol: '$',
  },
  {
    value: ECurrencyValue.EUR,
    view: <span>EUR</span>,
    symbol: '€',
  },
  {
    value: ECurrencyValue.CHF,
    view: <span>CHF</span>,
    symbol: '₣',
  },
  {
    value: ECurrencyValue.JPY,
    view: <span>JPY</span>,
    symbol: '¥',
  },
];
