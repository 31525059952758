import clsx from 'clsx';

import { useAlertContext } from '../alert.context';
import style from './style.module.scss';

interface IAlertIconProps extends ICustomizable {
  children: React.ReactNode;
}

export const AlertIcon = (props: IAlertIconProps) => {
  const ctx = useAlertContext();

  return (
    <div className={clsx(style['alert-icon'], props.className)} data-variant={ctx.variant} aria-hidden>
      {props.children}
    </div>
  );
};
