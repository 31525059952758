import { Text } from '@ping/uikit';
import { t } from '@ping/helpers';

import style from './style.module.scss';

const ClaimRewardModalContent = () => (
  <>
    <h4 className={style['banner__modal-title']}>{t('Claim your Core Tokens')}</h4>
    <Text body='regular' className={style['banner__modal-text']}>
      {t(
        'Your Core Tokens from the initial order phase are now available to be deposited into your Ping portfolio. Transaction fees will be applied during the withdrawal process. If you encounter any issues or discrepancies, please reach out our support team.'
      )}
    </Text>
  </>
);

export default ClaimRewardModalContent;
