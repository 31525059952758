import { Big, type BigSource } from 'big.js';

const EMPTY = '' as const;
const SLICE_START_INDEX = 0 as const;
const SLICE_EXCLUSIVE_INDEX = 1 as const;

/**
 * It normalize a given value by excluding extra decimals without any rounding.
 * @param {BigSource} value - The value to be normalized.
 * @param {number} scale - The `scale` parameter is a number that represents the number of decimal
 * places to be used for normalization.
 */
export const normalize = (value: BigSource, scale: number) => {
  const result = Big(value);
  const scaleFactor = new Big(10).pow(scale);
  result.c = result
    .times(scaleFactor)
    .round()
    .div(scaleFactor)
    .c.slice(SLICE_START_INDEX, result.e + scale + SLICE_EXCLUSIVE_INDEX);
  return result;
};

export const toFixedRoundDown = (value: BigSource, decimalPlaces: number) => {
  return new Big(value).round(decimalPlaces, Big.roundDown).toFixed(decimalPlaces);
};

export const normalizeRoundDown = (value: BigSource, scale: number) => {
  return normalize(toFixedRoundDown(value, Math.abs(scale)), Math.abs(scale));
};

export const isFullNumber = (value: number) => {
  return Math.floor(value) === value;
};

export const countNumberDecimals = (input: BigSource) => {
  const [, fractional = EMPTY] = Big(input).toFixed().split('.');
  return fractional.length;
};

export const isFloat = (input: number) => {
  const [, fractional = EMPTY] = Big(input).toFixed().split('.');
  return Boolean(fractional.length);
};

/**
 * Rounds a percentage value to two decimal places.
 *
 * @param {number | string} value - The value to be rounded.
 * @param {number} visibleDecimal - The number of decimal places to be visible.
 * @return {number} The rounded value.
 */
export const roundPercentage = (value: number | string, visibleDecimal = 2) => {
  const rounded = Math.round(Number(value) * 100) * 0.01;

  switch (rounded) {
    case 0:
    case 100: {
      return rounded.toString();
    }
    default: {
      return rounded.toFixed(visibleDecimal);
    }
  }
};
