import { LANGUAGE_LIST } from '@ping/constants/languages.constant';
import clsx from 'clsx';
import { t } from '@ping/helpers';
import { preferencesStore, selectedLanguageSelector, usePreferencesStore } from '@ping/stores/preferences.store';
import { Popup } from '@ping/uikit';
import { Tab, Tabs } from 'react-bootstrap';
import { DEFAULT_CURRENCIES } from '@ping/constants/default-currencies.constant';
import { useSelectedCurrency } from '@ping/hooks';
import { ECurrencyValue } from '@ping/enums';
import useHandleSelectedCurrency from '@ping/hooks/useHandleSelectedCurrency';
import { useLayoutEffect, useRef } from 'react';
import type { IPopupProps } from '@ping/uikit';
import * as gtm from '@ping/helpers/gtm.helper';

import style from './style.module.scss';

interface ILanguageDialogProps extends Omit<IPopupProps, 'children'> {
  className?: string;
  onClose(): void;
  activeTab?: 'language' | 'currency';
}

export const LanguageCurrencyDialog = (props: ILanguageDialogProps) => {
  const { className = '', activeTab = 'language', onClose, ...rest } = props;
  const selectedLanguage = usePreferencesStore(selectedLanguageSelector);
  const selectedCurrency = useSelectedCurrency();
  const changeSelectCurrency = useHandleSelectedCurrency();
  const tabBarRef = useRef<HTMLDivElement>(null);
  const tabBarPrevPlacement = useRef<number>(null);

  const handleAnimation = activeTab => {
    const tabActiveElement = document.querySelector(`[data-rb-event-key='${activeTab}']`) as HTMLElement;
    const activeTabWidth = tabActiveElement.getClientRects()[0].width;
    tabBarRef.current.style.width = `${activeTabWidth}px`;
    const transformValue =
      tabBarPrevPlacement.current === null || tabActiveElement.offsetLeft === tabBarPrevPlacement.current
        ? tabActiveElement.offsetLeft
        : tabActiveElement.offsetLeft - tabBarPrevPlacement.current;
    tabBarRef.current.style.transform = `translate(${transformValue > 0 ? transformValue : 0}px, 4px)`;
    tabBarPrevPlacement.current = tabActiveElement.offsetLeft;
  };

  useLayoutEffect(() => {
    if (props.isOpen) {
      setTimeout(() => handleAnimation(activeTab), 300);
    }
  }, [props.isOpen]);

  const handleLanguageChange = (lang: Omit<ILanguageState, 'texts'>) => {
    gtm.changeLanguageEvent(selectedLanguage, lang);
    preferencesStore.setSelectedLanguage(lang);
    if (onClose) {
      onClose();
    }
  };

  /**
   * @name handleCurrencyChange
   * @description handle currency change value and close dialog
   * @param currency {ECurrencyValue}
   */
  const handleCurrencyChange = (currency: ECurrencyValue) => {
    gtm.changeCurrencyEvent(selectedCurrency, currency);
    changeSelectCurrency(currency);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Popup onClose={onClose} contentClassName={style['language-currency-dialog__content']} {...rest}>
      <div ref={tabBarRef} className={style['language-currency-dialog__tab-bar']} />
      <Tabs defaultActiveKey={activeTab} onSelect={handleAnimation} className={clsx(style['language-tabs'], className)}>
        <Tab title={t('Language & Region')} eventKey='language'>
          <div className={style['language-dialog__wrapper']}>
            <h5>{t('Choose a language')}</h5>
            <div className={style['language-dialog__content']}>
              {LANGUAGE_LIST.filter(language => language.active).map(language => (
                <span
                  role='button'
                  onClick={() => handleLanguageChange({ label: language.label, value: language.value })}
                  className={clsx(
                    style['language-dialog__item'],
                    selectedLanguage === language.label && style['language-dialog__item_active']
                  )}
                  key={language.value}
                >
                  {language.label}
                </span>
              ))}
            </div>
          </div>
        </Tab>
        <Tab title={t('Currency')} eventKey='currency'>
          <div className={style['currency-dialog__wrapper']}>
            <h5>{t('Choose a currency')}</h5>
            <div className={style['currency-dialog__content']}>
              {DEFAULT_CURRENCIES.map(currency => (
                <span
                  role='button'
                  onClick={() => handleCurrencyChange(currency.value.toLowerCase() as ECurrencyValue)}
                  className={clsx(
                    style['currency-dialog__item'],
                    selectedCurrency?.toLowerCase() === currency.value && style['currency-dialog__item_active']
                  )}
                  key={currency.value}
                >
                  {`${currency.value.toUpperCase()} - ${currency.symbol}`}
                </span>
              ))}
            </div>
          </div>
        </Tab>
      </Tabs>
    </Popup>
  );
};
