import { ECurrencyValue, ETheme } from '@ping/enums';
import { Store } from '@ping/utils';

interface IPreferencesStore {
  theme: ETheme;
  defaultFiatCurrency: ECurrencyValue;
  defaultLanguage: ILanguageState;
}

// --------------------------------------------------------------------------------
// PREFERENCES STORE
// --------------------------------------------------------------------------------
export const usePreferencesStore = new Store<IPreferencesStore>({
  theme: ETheme.DARK,
  defaultFiatCurrency: null,
  defaultLanguage: { label: 'English', value: 'en-us', translations: {} },
})
  .withPersist({ name: 'PREFERENCES_STORE' })
  .build();

// --------------------------------------------------------------------------------
// PREFERENCES STORE SELECTORS
// --------------------------------------------------------------------------------
export const selectedThemeSelector = (state: IPreferencesStore) => state.theme;
export const isSelectedThemeDarkSelector = (state: IPreferencesStore) => state.theme === ETheme.DARK;
/**
 * Select fiat currency to make re-rendering of the components that use this selector (NavBar)
 * @param state
 */
export const selectDefaultFiatCurrenciesSelector = (state: IPreferencesStore) => state.defaultFiatCurrency;

export const selectedLanguageSelector = (state: IPreferencesStore) => state.defaultLanguage.label;
export const selectedLanguageTranslationsSelector = (state: IPreferencesStore) => state.defaultLanguage.translations;

// --------------------------------------------------------------------------------
// PREFERENCES STORE API
// --------------------------------------------------------------------------------
export const preferencesStore = {
  //
  // THEME
  setDefaultTheme: () => {
    setDefaultThemeWithSideEffect(ETheme.DARK);
  },
  toggleTheme: () => {
    const selectedTheme = toggleThemeWithSideEffect();
    usePreferencesStore.setState({ theme: selectedTheme });
  },
  applyUserSystemPreferenceTheme: () => {
    const selectedTheme = applyUserSystemPreferenceThemeWithSideEffect();
    usePreferencesStore.setState({ theme: selectedTheme });
  },
  //
  // FIAT CURRENCY
  getSelectedFiat: () => selectDefaultFiatCurrenciesSelector(usePreferencesStore.getState()),
  setSelectedFiat: (defaultFiatCurrency: ECurrencyValue) => usePreferencesStore.setState({ defaultFiatCurrency }),
  //
  // LANGUAGE
  getSelectedLanguageTranslations: () => selectedLanguageTranslationsSelector(usePreferencesStore.getState()),
  setSelectedLanguage: (defaultLanguage: Omit<ILanguageState, 'texts'>) => {
    const [language] = defaultLanguage.value.split('-');
    //
    // NOTE: caching will be handled by browser itself.
    import(`/public/dictionary/translated/${language}/en-us.json`).then(({ default: translations }) => {
      usePreferencesStore.setState({ defaultLanguage: { ...defaultLanguage, translations } });
    });
  },
};

// --------------------------------------------------------------------------------
// PREFERENCES STORE SIDE EFFECTS
// --------------------------------------------------------------------------------
const setDefaultThemeWithSideEffect = (defaultTheme: ETheme) => {
  document.body.classList.toggle('dark', defaultTheme === ETheme.DARK);
};

const toggleThemeWithSideEffect = () => {
  const isDarkMode = document.body.classList.toggle('dark');
  return isDarkMode ? ETheme.DARK : ETheme.LIGHT;
};

const applyUserSystemPreferenceThemeWithSideEffect = () => {
  let theme: ETheme;

  // TODO: refactor when light mode is supported
  // try {
  //   const darkMode = window?.matchMedia('(prefers-color-scheme: dark)');
  //   const themefromLocalStorage = JSON.parse(localStorage.getItem('theme'));

  //   if (themefromLocalStorage === 'dark') {
  //     theme = ETheme.DARK;
  //     document.body.classList.toggle('dark', darkMode.matches);
  //   } else if (themefromLocalStorage === 'light') {
  //     theme = ETheme.LIGHT;
  //   } else {
  //     document.body.classList.toggle('dark', darkMode.matches);
  //     theme = darkMode.matches ? ETheme.DARK : ETheme.LIGHT;
  //   }
  // } catch (error) {
  //   console.error(error);
  //   theme = ETheme.LIGHT;
  // }

  return theme;
};
