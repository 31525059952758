import { clsx } from 'clsx';
import { useRef } from 'react';
import { useButton } from 'react-aria';

import { Loading } from '@ping/uikit';

import style from './style.module.scss';

import type IReactAria from 'react-aria';

export interface IButtonA11YProps extends ICustomizable, IReactAria.AriaButtonProps<'button'> {
  children?: React.ReactNode;
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
  primary?: boolean;
  secondary?: boolean;
  isLoading?: boolean;
  isPrimitive?: boolean;
  form?: string;
  onClick?: () => void;
}

export const ButtonA11Y = (props: IButtonA11YProps) => {
  const { primary, secondary, children, className, form, buttonRef } = props;
  const ref = buttonRef || useRef();
  const { buttonProps } = useButton({ ...props, isDisabled: props.isDisabled || props.isLoading }, ref);

  return (
    <button
      {...buttonProps}
      className={clsx(style['buttonA11Y'], className)}
      data-disabled={props.isDisabled}
      data-loading={props.isLoading}
      form={form}
      ref={ref}
      data-uikit-button
      data-primary={!props.isPrimitive && primary}
      data-secondary={!props.isPrimitive && secondary}
      data-primitive={Boolean(props.isPrimitive)}
    >
      {props.isLoading && <Loading className={style['buttonA11Y__loading']} />} {children}
    </button>
  );
};
