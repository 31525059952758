import {
  LastUserReviewResponse,
  useGetLastRequestCompliance,
  useGetNextStepCompliance,
  useSubmitReviewRequestCompliance,
} from '@ping/api';
import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import { BASE_URL } from '@ping/configs';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useClientEffect, useMobile, useRemoteModal } from '@ping/hooks';
import { tokenStore } from '@ping/stores/token.store';
import { Alert, Button, Modal, Toast } from '@ping/uikit';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useEffect, useMemo, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import style from './style.module.scss';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

const FILEPOND_ITEM_HEIGHT = 75 as const;
const FILEPOND_MAX_SIZE = '5MB' as const;

interface IProvideRequestedDocumentsModalPortalProps {
  comment: string;
}

export const ProvideRequestedDocumentsModalPortal = (props: IProvideRequestedDocumentsModalPortalProps) => {
  const isMobile = useMobile();
  const modal = useRemoteModal(modalKeys.PROVIDE_REQUESTED_DOCUMENTS);
  const [files, setFiles] = useState([]);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const { data: activeRequestData } = useGetLastRequestCompliance<LastUserReviewResponse>();
  const nextStep = useGetNextStepCompliance(null);
  useEffect(() => {
    nextStep.refetch();
  }, [activeRequestData]);

  const { isLoading: isSubmitComplianceLoading } = useSubmitReviewRequestCompliance({
    query: {
      enabled: isSubmitClicked,
      onSuccess: () => {
        modal.state.close();
        Toast.success({ title: t('Document upload successful.') });
      },
    },
  });

  const UploadAreaLabel = useMemo(() => {
    if (isMobile) {
      return `
        <img class="upload-label--icon" src="/img/download.svg" alt="" />
        <span class="upload-label--content">
          <span class="upload-label--title">${t('Upload a document')}</span>
          <span class="upload-label--text">${t('click to browse')} ${t('(%s max)', FILEPOND_MAX_SIZE)}</span>
        </span>
     `;
    }

    return `
      <img class="upload-label--image" src="/img/upload-illustration.svg" alt="" />
      <span class="upload-label--title">${t('Drag document here')}</span>
      <span class="upload-label--text">${t('or')} <span class="has-underline">${t('click to browse')}
      </span> ${t('(%s max)', FILEPOND_MAX_SIZE)}</span>
     `;
  }, [isMobile]);

  const handleSubmitUploadForm = () => {
    setIsSubmitClicked(true);
  };

  useClientEffect(() => {
    if (!isMobile) {
      return;
    }

    const filePondWrapper = document.querySelector<HTMLElement>('.filepond--wrapper');
    const filePondList = document.querySelector<HTMLElement>('.filepond--list-scroller');
    const filePondHeight = `${(files.length + 1) * FILEPOND_ITEM_HEIGHT}px`;

    if (filePondWrapper) {
      filePondWrapper.style.height = filePondHeight;
    }
    if (filePondList) {
      filePondList.style.height = filePondHeight;
    }
  }, [files, isMobile]);

  useEffect(() => {
    if (!modal.state.isOpen) {
      setFiles([]);
    }
  }, [modal.state.isOpen]);

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal className={style['provide-requested-documents-modal-portal']}>
        <Modal.Header className={style['provide-requested-documents-modal-portal__header']}>
          <Modal.Title>{t('Provide requested documents')}</Modal.Title>
          <Modal.CloseButton className={style['provide-requested-documents-modal-portal__close']} />
        </Modal.Header>

        <Modal.Content className={style['provide-requested-documents-modal-portal__content']}>
          <div className={style['provide-requested-documents-modal-portal__details']}>
            <Alert.Root className={style['provide-requested-documents-modal-portal-alert']} variant='warning'>
              <Alert.Icon>
                <CircleWarningIcon className={style['provide-requested-documents-modal-portal-alert__icon']} />
              </Alert.Icon>
              <Alert.Title>{t('More documents required')}</Alert.Title>
              <Alert.Description>{props.comment}</Alert.Description>
            </Alert.Root>
          </div>
          <div
            className={style['provide-requested-documents-modal-portal__upload-area']}
            data-populated={!!files.length}
          >
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple
              server={{
                process: {
                  url: `${BASE_URL}/frontoffice/api/v1/file-transfer/upload?documentType=ProofOfFunds`,
                  headers: {
                    authorization: `Bearer ${tokenStore.getAccessToken()}`,
                  },
                  ondata: formData => {
                    const newFormData = new FormData();
                    newFormData.append('', formData.getAll('documents')[1]);
                    return newFormData;
                  },
                  onerror: errorData => {
                    if (errorData) {
                      Toast.error({ title: JSON.parse(errorData)?.message });
                    }
                  },
                },
              }}
              name='documents'
              acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/pdf']}
              allowSyncAcceptAttribute
              labelIdle={UploadAreaLabel}
              credits={false}
              iconDone={`<img src='/img/upload-file.svg' alt='' />`}
              maxFileSize={FILEPOND_MAX_SIZE}
              allowFileSizeValidation
            />
          </div>
        </Modal.Content>

        <Modal.Actions className={style['provide-requested-documents-modal-portal__actions']}>
          <Button
            className={style['provide-requested-documents-modal-portal__submit']}
            size='large'
            uppercase
            onClick={handleSubmitUploadForm}
            isLoading={isSubmitComplianceLoading}
            disabled={!files.length}
          >
            {t('Submit')}
          </Button>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
