import React from 'react';
import clsx from 'clsx';
import { BeatLoader, ClipLoader } from 'react-spinners';

import style from './style.module.scss';

type TLengthType = number | string;

export type SpinnerType = 'beatLoader' | 'clipLoader';
interface ISpinnerProps {
  spinnerType?: SpinnerType;
  size?: TLengthType;
  margin?: TLengthType;
  color?: string;
  className?: string;
  isLoading?: boolean;
  css?: string;
  speedMultiplier?: number;
  width?: TLengthType;
  height?: TLengthType;
  radius?: TLengthType;
}

export const Spinner = ({
  spinnerType = 'beatLoader',
  color = '#b0b6d0',
  className = '',
  isLoading,
  ...restProps
}: ISpinnerProps) => {
  const SpecificSpinner = spinnerType === 'beatLoader' ? BeatLoader : ClipLoader;
  return (
    <span className={clsx(style['spinner'], className)}>
      <SpecificSpinner color={color} loading={isLoading} {...restProps} />
    </span>
  );
};
