import { ButtonA11Y, IButtonA11YProps } from '@ping/uikit';
import clsx from 'clsx';
import { useContext } from 'react';
import { mergeProps } from 'react-aria';
import { ModalContext } from '../modal.context';

import style from './style.module.scss';

export type IModalTriggerProps = IButtonA11YProps;

export const ModalTrigger = (props: IModalTriggerProps) => {
  const ctx = useContext(ModalContext);

  return (
    <ButtonA11Y {...mergeProps(ctx.triggerProps, props)} className={clsx(style['modal-trigger'], props.className)}>
      {props.children}
    </ButtonA11Y>
  );
};
