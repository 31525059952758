import { isBrowser } from '@ping/utils';
import { useLayoutEffect } from 'react';

/**
 * It runs an effect only on the client side.
 * @param effect - The `effect` parameter is a function that will be executed after the component has
 * rendered. It can contain any code that needs to be executed, such as fetching data, subscribing to
 * events, or updating the DOM.
 * @param [deps] - The `deps` parameter is an optional array of dependencies. It is similar to the
 * dependencies array in the `useEffect` hook.
 */
export const useClientEffect = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  useLayoutEffect(() => isBrowser() && effect(), deps);
};
