import { Store } from '@ping/utils';

export type LanguageModalStore = {
  isOpen: boolean;
  activeTab: 'language' | 'currency';
};
// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE
// --------------------------------------------------------------------------------
export const useLanguageModalStore = new Store<LanguageModalStore>({ isOpen: false, activeTab: 'language' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE SELECTORS
// --------------------------------------------------------------------------------

export const selectIsLanguageModalOpen = (state: LanguageModalStore) => state.isOpen;
export const selectLanguageModalActiveTab = (state: LanguageModalStore) => state.activeTab;

// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE API
// --------------------------------------------------------------------------------
export const languageModalStore = {
  setLanguageModal: (isOpenValue: boolean, tab = 'language' as LanguageModalStore['activeTab']) =>
    useLanguageModalStore.setState({ isOpen: isOpenValue, activeTab: tab }, true),
};
