import ArrowLeftIcon from '@ping/assets/Icon/arrowLeft.svg';
import CloseIcon from '@ping/assets/Icon/close.svg';
import { disablePageScroll } from '@ping/utils';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import style from './style.module.scss';

export interface IPopupProps extends ReactModal.Props {
  isOpen: boolean;
  title?: React.ReactNode;
  isHeaderVisible?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  fullscreen?: boolean;
  children: React.ReactNode;
  contentClassName?: string;
  uniqueId?: string;
}

export const Popup = ({
  title,
  isOpen,
  isHeaderVisible = true,
  onClose,
  onBack,
  children,
  fullscreen,
  className,
  contentClassName,
  uniqueId,
  ...rest
}: IPopupProps) => {
  /* handling animation state */
  const [closing, setClosing] = useState<boolean>(false);
  const modalIdentifier = useRef(Math.random().toString(16));

  const closeTimeout = 100;

  const handleOnClose = () => {
    setClosing(true);
    setTimeout(onClose, closeTimeout);
  };
  const handleOnBack = () => {
    setClosing(true);
    setTimeout(onBack, closeTimeout);
  };

  useEffect(() => {
    disablePageScroll(isOpen, modalIdentifier.current);

    /* reset closing animation when is open trigger  again */
    isOpen && setClosing(false);
  }, [isOpen]);

  const contentClassNames = clsx(style['popup__content'], contentClassName, {
    [style['popup__closing']]: closing,
  });
  const headerClassName = clsx(style['popup__header'], {
    [style['center']]: Boolean(onBack),
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      className={clsx(style['popup'], {
        [style['fullScreen']]: fullscreen,
        [`${className}`]: className,
      })}
      overlayClassName={style['popup__overlay']}
      closeTimeoutMS={closeTimeout}
      ariaHideApp={false}
      {...rest}
    >
      <div className={contentClassNames} data-popup={uniqueId}>
        {isHeaderVisible && (
          <div className={headerClassName}>
            {Boolean(onBack) && <ArrowLeftIcon className={style['popup__back']} onClick={handleOnBack} />}
            <div className={style['popup__left-part']}>
              {title && <h4 className={style['popup__left-part-title']}>{title}</h4>}
            </div>
            {Boolean(onClose) && <CloseIcon className='popup_close-btn' onClick={handleOnClose} />}
          </div>
        )}

        {children}
      </div>
    </ReactModal>
  );
};
