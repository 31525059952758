import clsx from 'clsx';

import style from './style.module.scss';

interface IModalHeaderProps extends ICustomizable {
  children: React.ReactNode;
}

export const ModalHeader = (props: IModalHeaderProps) => (
  <header className={clsx(style['modal-header'], props.className)}>{props.children}</header>
);
