import clsx from 'clsx';

import style from './style.module.scss';
import { useAlertContext } from '../alert.context';

interface IAlertDescriptionProps extends ICustomizable {
  children: React.ReactNode;
}

export const AlertDescription = (props: IAlertDescriptionProps) => {
  const ctx = useAlertContext();

  return (
    <p className={clsx(style['alert-description'], props.className)} id={ctx.descriptionId}>
      {props.children}
    </p>
  );
};
