import { ReactNode } from 'react';
import { Modal } from '@ping/uikit';
import clsx from 'clsx';
import BannerMessage from './BannerMessage';
import { useRemoteModal } from '@ping/hooks';

import type { IBannerType } from './BannerMessage';

import style from './style.module.scss';

interface IBannerModal {
  ModalContentChildren: ReactNode;
  ModalActionsChildren?: ReactNode;
  bannerType?: IBannerType;
  modalTitle?: string;
  message: string;
  extraMessage?: string;
  showIcon?: boolean;
  headerTag?: IHeaderTag;
  messageClassName?: string;
  modalTriggerClassName?: string;
  modalPortalClassName?: string;
  remoteModal?: ReturnType<typeof useRemoteModal>;
}

const BannerModal = ({
  ModalContentChildren,
  modalTitle = '',
  ModalActionsChildren,
  bannerType,
  message,
  extraMessage,
  showIcon,
  headerTag,
  remoteModal,
  messageClassName,
  modalTriggerClassName = '',
  modalPortalClassName = '',
}: IBannerModal) => (
  <Modal.Root {...remoteModal.props}>
    <Modal.Trigger className={clsx(style['banner-modal__trigger'], modalTriggerClassName)}>
      <BannerMessage
        bannerType={bannerType}
        remoteModal={remoteModal}
        message={message}
        extraMessage={extraMessage}
        showIcon={showIcon}
        messageClassName={messageClassName}
      />
    </Modal.Trigger>

    <Modal.Portal className={modalPortalClassName}>
      <Modal.Header>
        <Modal.Title headerTag={headerTag}>{modalTitle}</Modal.Title>
        <Modal.CloseButton />
      </Modal.Header>

      <Modal.Content>{ModalContentChildren}</Modal.Content>

      <Modal.Actions>{ModalActionsChildren}</Modal.Actions>
    </Modal.Portal>
  </Modal.Root>
);

export default BannerModal;
