import { ModalActions } from './ModalActions';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalContent } from './ModalContent';
import { ModalHeader } from './ModalHeader';
import { ModalPortal } from './ModalPortal';
import { ModalRoot } from './ModalRoot';
import { ModalSubtitle } from './ModalSubtitle';
import { ModalTitle } from './ModalTitle';
import { ModalTrigger } from './ModalTrigger';
import { ModalContext } from './modal.context';

export type { IModalParams } from './ModalRoot';
export type { IModalTriggerProps } from './ModalTrigger';
export type { IModalContext } from './modal.context';

export const Modal = {
  Context: ModalContext,
  Root: ModalRoot,
  Actions: ModalActions,
  CloseButton: ModalCloseButton,
  Content: ModalContent,
  Header: ModalHeader,
  Portal: ModalPortal,
  Subtitle: ModalSubtitle,
  Title: ModalTitle,
  Trigger: ModalTrigger,
};
