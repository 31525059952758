import { useIsUserLoggedIn, useUpdateCurrencySetting } from '@ping/hooks/index';
import { ECurrencyValue } from '@ping/enums';
import { Toast } from '@ping/uikit';
import { preferencesStore } from '@ping/stores/preferences.store';

const useHandleSelectedCurrency = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const { updateSettingAPI } = useUpdateCurrencySetting();

  return (currencyValue: ECurrencyValue) => {
    if (isUserLoggedIn) {
      updateSettingAPI(currencyValue, () => {
        Toast.success({ title: 'The current currency has been changed successfully' });
      });
      preferencesStore.setSelectedFiat(null);
    } else {
      preferencesStore.setSelectedFiat(currencyValue);
    }
  };
};

export default useHandleSelectedCurrency;
