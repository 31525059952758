import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import { generateBase64CoreIcon } from '@corepass/core-blockies';
import { UserComplianceTierType } from '@ping/api';
import { t } from '@ping/helpers';
import { truncateCoreId } from '@ping/utils';

import style from './style.module.scss';

interface IAvatarProps {
  className?: string;
  userCoreID: string;
  tier: UserComplianceTierType | 'Officer' | 'TradeViewer';
}

export const Avatar = (props: IAvatarProps) => {
  const { className = '', userCoreID, tier } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const avatarWrapperRef = useRef<HTMLDivElement>(null);

  const isElementFocused = (element: HTMLElement | null) => {
    return element === document.activeElement;
  };

  useEffect(() => {
    const handleFocus = () => {
      setShowTooltip(false);
    };

    avatarWrapperRef.current?.addEventListener('focus', handleFocus);

    return () => {
      // Clean up the event listener when the component unmounts.
      avatarWrapperRef.current?.removeEventListener('focus', handleFocus);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isElementFocused(avatarWrapperRef.current)) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <OverlayTrigger
      show={showTooltip}
      placement='bottom'
      overlay={
        <Tooltip id='avatar-tooltip'>
          <span>{userCoreID.toUpperCase()}</span>
        </Tooltip>
      }
    >
      <div
        className={clsx(style['avatar-wrapper'], className)}
        ref={avatarWrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={0}
      >
        <div className={style['avatar-info']}>
          <img className={style['avatar-image']} src={generateBase64CoreIcon(userCoreID, 3)} alt='avatar' />
          <span>{truncateCoreId(userCoreID.toUpperCase())}</span>
        </div>
        <div className={style['avatar-tier']}>{t(tier?.replace('Tier', 'Tier '))}</div>
      </div>
    </OverlayTrigger>
  );
};
