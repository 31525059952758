import { clsx } from 'clsx';

import style from './style.module.scss';

export const Loading = (props: ICustomizable) => (
  <svg
    {...props}
    className={clsx(style['loading'], props.className)}
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0511 33.3334C12.6587 33.3334 6.66602 27.3639 6.66602 20.0001C6.66602 12.6363 12.6587 6.66675 20.0511 6.66675C26.2881 6.66675 31.5287 10.916 33.0145 16.6667'
      stroke='currentColor'
      strokeWidth='3'
      strokeLinecap='round'
    />
  </svg>
);
