import * as signalR from '@microsoft/signalr';
import * as gtm from '@ping/helpers/gtm.helper';
import { socketConnectivityChecker } from '@ping/helpers/socket-connectivity-checker.helper';

class SocketMiddleware implements signalR.ILogger {
  log(logLevel: signalR.LogLevel, message: string) {
    if (message.includes('HubConnection.connectionClosed') || message.includes('HttpConnection.stopConnection')) {
      gtm.webSocketDisconnectedEvent('Notifications');
      gtm.webSocketDisconnectedEvent('Market');
      socketConnectivityChecker('Notifications', false);
      socketConnectivityChecker('Market', false);
    }
  }
}

const createConnection = (url: string): signalR.HubConnection => {
  if (process.env.IS_TEST === 'true' && window.signalrMock) {
    return window.signalrMock;
  }
  return new signalR.HubConnectionBuilder()
    .withUrl(url, { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
    .configureLogging(new SocketMiddleware())
    .withAutomaticReconnect()
    .build();
};
/** @method getConnectionMemo
 * @description Make sure to not create multiple connections to the same server. (SignalR may support this out of the box, but this way there is no need to create one at least and if we change our library this would also work)
 */
const getConnectionMemo = () => {
  const cache: { [key: string]: signalR.HubConnection } = {};
  return (url: string) => {
    if (cache[url]) {
      return cache[url];
    } else {
      const connection = createConnection(url);
      cache[url] = connection;
      return connection;
    }
  };
};

export const WSConnect = getConnectionMemo();
