export const dataLayerPush = (data: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const changeLanguageEvent = (previous_language, selected_language) => {
  dataLayerPush({
    event: 'change_language',
    previous_language,
    selected_language,
  });
};

export const changeCurrencyEvent = (previous_currency, selected_currency) => {
  dataLayerPush({
    event: 'change_currency',
    previous_currency,
    selected_currency,
  });
};

export const supportSubjectEvent = subject => {
  dataLayerPush({
    event: 'support_subject',
    subject,
  });
};

export const orderTypeEvent = (type, side) => {
  dataLayerPush({
    event: 'order_type',
    type,
    side,
  });
};

export const kycQrcodeEvent = (count, duration) => {
  dataLayerPush({
    event: 'kyc_qrcode',
    count,
    duration,
  });
};

export const webSocketDisconnectedEvent = socket_name => {
  dataLayerPush({
    event: 'websocket_disconnected',
    socket_name,
  });
};

export const clickTradeFromMarketsPageEvent = market => {
  dataLayerPush({
    event: 'click_trade_from_markets_page',
    market,
  });
};

export const withdrawalEvent = asset_name => {
  dataLayerPush({
    event: 'withdrawal',
    asset_name,
  });
};

export const quickSwapEvent = market => {
  dataLayerPush({
    event: 'quick_swap',
    market,
  });
};
