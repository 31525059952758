import RightArrowIcon from '@ping/assets/Icon/right-arrow.svg';
import Link from 'next/link';
import { ReactElement } from 'react';

import style from './style.module.scss';
import { useMobile } from '@ping/hooks';

interface IProps {
  href: string;
  title: string;
  subtitle: string;
  icon: ReactElement;
  onClick?: () => void;
}

export const LinkDropDownItem = (props: IProps) => {
  const isMobile = useMobile();

  const handleOnClick = () => {
    if (isMobile) {
      props?.onClick?.();
    }
  };

  return (
    <Link href={props.href}>
      <div className={style['trade__dropdown-item__content']} onClick={handleOnClick}>
        {props.icon}
        <div className={style['trade__dropdown-item__info']}>
          <div className='d-flex flex-column'>
            <span className={style['trade__dropdown-item__title']}>{props.title}</span>
            <span className={style['trade__dropdown-item__subtitle']}>{props.subtitle}</span>
          </div>
          <RightArrowIcon />
        </div>
      </div>
    </Link>
  );
};
