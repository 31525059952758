import { logOutSideEffect } from '@ping/authorization/side-effects';
import { WEBSOCKET_URL_NOTIFICATION } from '@ping/configs';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import { tokenStore } from '@ping/stores/token.store';
import { userInformationStore } from '@ping/stores/userInformation.store';
import { WSConnect } from '@ping/websockets';

/**
 * It clears the token and user information from the respective stores
 */
export const signoutReset = ({ withRevoke }: { withRevoke?: boolean } = { withRevoke: true }) => {
  const tokenQuery = `${tokenStore.getAccessToken()}`;
  const connection = WSConnect(`${WEBSOCKET_URL_NOTIFICATION}?access_token=${tokenQuery}`);
  connection.stop();
  withRevoke && logOutSideEffect();
  tokenStore.revokeToken();
  userInformationStore.revokeUserInformation();
  queryClient.clear();
  sessionStorage.removeItem('UI_HELPER');
};
