import clsx from 'clsx';
import style from './style.module.scss';

interface IProps {
  heading?: string;
  body?: 'medium' | 'regular' | 'semi-bold';
  caption?: 'regular' | 'semi-bold';
  text?: 'electric-blue' | 'cadet-blue';
  className?: string;
  uppercase?: boolean;
  children: React.ReactNode;
  isError?: boolean;
  cadetBlueColor?: boolean;
  grayColor?: boolean;
  badge?: boolean;
}

export const Text = ({
  heading,
  caption,
  badge,
  body,
  text,
  className = '',
  children,
  uppercase = false,
  isError = false,
  cadetBlueColor = false,
  grayColor = false,
  ...rest
}: IProps) => {
  const classname = clsx(
    style['text'],
    {
      [style[`body_${body}`]]: body,
      [style[`caption_${caption}`]]: caption,
      [style[`heading_${heading}`]]: heading,
      [style[`text_${text}`]]: text,
      [style['isError']]: isError,
      [style['uppercase']]: uppercase,
      [style['cadetBlueColor']]: cadetBlueColor,
      [style['grayColor']]: grayColor,
      [style['badge']]: badge,
    },
    className
  );
  return (
    <span className={classname} {...rest}>
      {children}
    </span>
  );
};
