import { modalStore } from '@ping/stores/modal.store';
import { useEffect, useState } from 'react';

import type { IModalKey } from '@ping/constants/modal-keys.constant';
import type { IModalParams } from '@ping/uikit';

/**
 * It manages the state and props of a remote modal component.
 * @param {IModalKey} key - The key is used to identify the modal. It can be any value that uniquely
 * identifies the modal.
 * @param {IModalParams} [params] - The `params` parameter is an optional object that contains
 * additional configuration options for the modal.
 */
export const useRemoteModal = (key: IModalKey, params?: IModalParams) => {
  const [isOpen, setIsOpen] = useState(Boolean(params?.defaultOpen ?? params?.isOpen));
  const state = {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen($isOpen => !$isOpen),
    setOpen: setIsOpen,
  };

  useEffect(() => modalStore.register(key, state), []);

  return {
    state,
    props: {
      ...params,
      isOpen,
      onOverlayPress: () => setIsOpen(false),
      onCloseButtonPress: () => setIsOpen(false),
    },
  };
};
