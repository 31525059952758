export const HYDRA_DOMAIN = process.env.NEXT_PUBLIC_HYDRA_DOMAIN;
export const HYDRA_CLIENT = process.env.NEXT_PUBLIC_HYDRA_CLIENT;
export const HYDRA_AUDIENCE = process.env.NEXT_PUBLIC_HYDRA_AUDIENCE;
export const API = process.env.NEXT_PUBLIC_API;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const TRUDESK_API_URL = process.env.NEXT_PUBLIC_TRUDESK_API_BASE_URL;
export const DEFAULT_MARKET_QUOTE = 'USDC' as const;
export const APP_VERSION = 'ALPHA' as const;
export const SWISS_FRANK = 'CHF' as const;
export const DEFAULT_CURRENCY = SWISS_FRANK;
export const WEBSOCKET_URL = process.env.NEXT_PUBLIC_WEBSOCKET_URL;
export const WEBSOCKET_URL_NOTIFICATION = `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
  'https',
  'wss'
)}/notification-service/websocket`;
export const WEBSITE_BASE_URL = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL;

export const HASH_VERSION = process.env.NEXT_PUBLIC_COMMIT_SHA || 'No version on ENV';
export const SHORT_HASH_VERSION = process.env.NEXT_PUBLIC_COMMIT_SHORT_SHA || 'No short version on ENV';
export const IS_DEVELOPMENT_MODE = process.env.NEXT_PUBLIC_ENVIRONMENT === 'develop' || IS_DEVELOPMENT;
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as 'develop' | 'staging' | 'testnet' | 'production';
export const DEFAULT_SELECTED_MARKET = 'XCB/CTN';
