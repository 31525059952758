import { useUserInformationStore } from '@ping/stores/userInformation.store';
import * as Sentry from '@sentry/nextjs';

/**
 * @method initialConfigs
 * @description This method is used to set the initial configurations for the app
 * @returns void
 */
const initialConfigs = (): void => {
  const user = useUserInformationStore.getState();

  Sentry.setUser({
    id: user.id,
    coreId: user.coreId,
    verificationLevel: user.verificationLevel,
  });
};

export default initialConfigs;
