import { ButtonA11Y } from '@ping/uikit';
import { useContext } from 'react';

import { ModalContext } from '../modal.context';
import style from './style.module.scss';

import type { IButtonA11YProps } from '@ping/uikit/ButtonA11Y';
import clsx from 'clsx';

type IModalCloseButtonProps = Omit<IButtonA11YProps, 'isLoading'>;

export const ModalCloseButton = (props: IModalCloseButtonProps) => {
  const ctx = useContext(ModalContext);

  return (
    <ButtonA11Y
      {...props}
      className={clsx(style['modal-close-button'], props.className)}
      onPress={e => {
        ctx.state.close();
        ctx.onCloseButtonPress?.();
        props?.onPress?.(e);
      }}
    >
      {/* //TODO: replace this when icons are organized */}
      <svg
        className={style['modal-close-button__icon']}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M6 6L18 18' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' />
        <path d='M18 6L6 18' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' />
      </svg>
    </ButtonA11Y>
  );
};
