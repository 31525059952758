import * as Sentry from '@sentry/nextjs';
import { t } from '@ping/helpers';
import { Button } from '@ping/uikit';

import style from './style.module.scss';

const ErrorFallback = () => {
  const handleTryAgain = () => {
    /* wait for the event to be sent to Sentry before reloading the page */
    Sentry.flush(500).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className={style['error-container']}>
      <img src='/img/error.svg' alt='page error' className={style['error-container__icon']} />
      <h2 className={style['error-container__title']}>{t('Oops something went wrong!')}</h2>
      <p className={style['error-container__description']}>
        {t('Application error: a client-side exception has occurred (see the browser console for more information')}
      </p>
      <Button className={style['error-container__btn']} type='primary' onClick={handleTryAgain} uppercase>
        {t('Reload')}
      </Button>
    </div>
  );
};

export const ErrorBoundary = ({ children }) =>
  <ErrorFallback /> && <Sentry.ErrorBoundary fallback={ErrorFallback}>{children}</Sentry.ErrorBoundary>;
