import { Store } from '@ping/utils';

export type SocketsStore = {
  isMarketConnected: boolean;
  isNotificationsConnected: boolean;
};
// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE
// --------------------------------------------------------------------------------
export const useSocketsStore = new Store<SocketsStore>({ isMarketConnected: false, isNotificationsConnected: false })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE SELECTORS
// --------------------------------------------------------------------------------

export const selectIsMarketConnected = (state: SocketsStore) => state.isMarketConnected;
export const selectIsNotificationsConnected = (state: SocketsStore) => state.isNotificationsConnected;

// --------------------------------------------------------------------------------
// LANGUAGE MODAL STORE API
// --------------------------------------------------------------------------------
export const socketsStore = {
  setMarketSocketStatus: (isConnected: boolean) => useSocketsStore.setState({ isMarketConnected: isConnected }),
  setNotificationsSocketStatus: (isConnected: boolean) =>
    useSocketsStore.setState({ isNotificationsConnected: isConnected }),
};
