import axios from 'axios';

import { TERMINATED_USER_ERROR_CODE } from '@ping/authorization/side-effects';
import { signoutReset } from '@ping/helpers';
import { isTokenNeededToBeRefreshedSelector, tokenStore } from '@ping/stores/token.store';
import { useUserInformationStore } from '@ping/stores/userInformation.store';
import { isBrowser } from '@ping/utils';

import { ActivityStatus } from '.';
import { axiosInstance } from './axios-instance';

let authorizer = null;
authorizer = isBrowser() && import('@ping/authorization/authorizer').then(module => (authorizer = module.authorizer));

const UNAUTHORIZED = 401 as const;
const INACTIVE_USER_CANCEL_MESSAGE = 'Operation canceled since user is not Active.';

let previousRequestInterceptor: number = null;
let previousResponseInterceptor: number = null;
let concurrentRequests = 0;
let isTokenRefreshing = false;
let tokenRefreshSubscribers: ((accessToken: string) => void)[] = [];

/**
 * @method subscribeRequestsTokenRefresh
 * @description subscribe to requests so they can be used later when token get refreshed
 * @param cb {Function} callback function to be called when token is refreshed
 */
const subscribeRequestsTokenRefresh = (cb: (accessToken: string) => void) => {
  tokenRefreshSubscribers.push(cb);
};

/**
 * @description notify all subscribers that token has been refreshed and reset subscribers array
 * @param accessToken {string} new access token
 */
const onTokenRefreshed = (accessToken: string) => {
  tokenRefreshSubscribers.forEach(callback => callback(accessToken));
  tokenRefreshSubscribers = [];
};

export const activeUserAPI = [
  '/rewards',
  '/portfolio',
  '/settings',
  '/referral',
  '/order_history',
  '/orders/my',
  '/withdrawal/limits',
  '/contactcrypto',
];
export const setInterceptors = () => {
  // Set a request interceptor once single-tone instance with global variable
  if (previousRequestInterceptor) {
    axiosInstance.interceptors.request.eject(previousRequestInterceptor);
  }
  previousRequestInterceptor = axiosInstance.interceptors.request.use(async config => {
    const requestUrl = config.url;
    if (
      useUserInformationStore.getState().activityStatus !== ActivityStatus.Active &&
      useUserInformationStore.getState().activityStatus !== ActivityStatus.Limited &&
      activeUserAPI.some(api => requestUrl.includes(api))
    ) {
      throw new axios.Cancel(INACTIVE_USER_CANCEL_MESSAGE);
    }

    const isTokenNeededToBeRefreshed = isTokenNeededToBeRefreshedSelector(tokenStore.getTokenResponse());
    if (isTokenNeededToBeRefreshed) {
      if (!isTokenRefreshing) {
        isTokenRefreshing = true;
        try {
          await authorizer.silentSignin();
          /* run all apis in the queue with new token */
          onTokenRefreshed(tokenStore.getAccessToken());
          /* In first call queue is empty we manually update the token in place */
          config.headers['Authorization'] = `Bearer ${tokenStore.getAccessToken()}`;
          return config;
        } catch (error) {
          tokenStore.revokeToken();
        } finally {
          isTokenRefreshing = false;
        }
      }

      return new Promise(resolve => {
        subscribeRequestsTokenRefresh((newToken: string) => {
          // Update the authorization header with new token
          config.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(config);
        });
      });
    }

    return config;
  });

  // Set a response interceptor in case of 401 errors that might be related to token expiration
  // Try to refresh the token and retry the request. if it keeps failing on retry attempt, give up and
  // throw the error back to the requester
  if (previousResponseInterceptor) {
    axiosInstance.interceptors.response.eject(previousResponseInterceptor);
  }
  previousResponseInterceptor = axiosInstance.interceptors.response.use(
    response =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    handleOnRejected
  );
};

const handleOnRejected = async (error: any) => {
  let reason = error;
  const originalRequest = error.config;
  const errorCode = error?.response?.data?.errorCode;

  if (
    error?.response?.status === UNAUTHORIZED &&
    errorCode !== TERMINATED_USER_ERROR_CODE &&
    !originalRequest._retry &&
    concurrentRequests === 0
  ) {
    originalRequest._retry = true;
    const { refreshToken } = tokenStore.getTokenResponse();
    concurrentRequests++;

    if (Boolean(refreshToken) && 'silentSignin' in authorizer) {
      try {
        await authorizer.silentSignin();
        return Promise.resolve(axiosInstance(originalRequest));
      } catch (error) {
        reason = error;
        /* no need to revoke since refresh token either expired or being used for getting new access token */
        signoutReset({ withRevoke: false });
      } finally {
        concurrentRequests--;
      }
    }
  }

  return Promise.reject(reason);
};
