import clsx from 'clsx';

import style from './style.module.scss';

interface IModalSubtitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const ModalSubtitle = (props: IModalSubtitleProps) => {
  const { className, children, ...rest } = props;

  return (
    <p {...rest} className={clsx(style['modal-subtile'], className)}>
      {children}
    </p>
  );
};
