import { ModalContext } from '../modal.context';
import { useInitModal } from '../modal.utils';

export type IModalRemote = ReturnType<typeof useInitModal>;
export type IModalState = IModalRemote['state'];
export type IModalParams = Parameters<typeof useInitModal>[0];

interface IModalRootProps extends IModalParams {
  remote?: IModalRemote;
  children: React.ReactNode;
  onOverlayPress?: () => void;
  onCloseButtonPress?: () => void;
}

export const ModalRoot = (props: IModalRootProps) => {
  const modalConfig = useInitModal(props);

  return (
    <ModalContext.Provider
      value={{
        ...modalConfig,
        onOverlayPress: props.onOverlayPress,
        onCloseButtonPress: props.onCloseButtonPress,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
