import clsx from 'clsx';

import { t } from '@ping/helpers';
import { provideRequestedDocumentsRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import { Modal } from '@ping/uikit';

import style from './style.module.scss';

interface IProvideRequestedDocumentsModalTriggerProps extends ICustomizable {
  onPress?: () => void;
}

export const ProvideRequestedDocumentsModalTrigger = (props: IProvideRequestedDocumentsModalTriggerProps) => {
  const modal = useModalStore(provideRequestedDocumentsRemoteStateSelector);

  return (
    <Modal.Trigger
      className={clsx(style['provide-requested-documents-modal-trigger'], props.className)}
      onPress={() => {
        modal.open();
        props.onPress?.();
      }}
    >
      {t('Upload more docs')}
    </Modal.Trigger>
  );
};
